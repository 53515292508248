import React, { useEffect, useState } from 'react';
import Topbar from './Topbar';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useLocation } from 'react-router-dom';
import DB from '../../db.js';

const Services = () => {

    const [facility, setFacility] = useState([]);

    const location = useLocation();
    const pathname = location.pathname;

    const indexie = pathname.lastIndexOf("/");
    var slug = pathname.substring(indexie + 1);
    var service = pathname.substring(indexie + 1).replace("-"," ");
    const index = service.indexOf("-");
    
    if(index >= 1){
        service = service.replace("-"," ");
    }

    useEffect(()=>{
        const db = new DB('family_hero');
        db.getFacility(slug).then((data)=>{
            setFacility(data);
        });
    },[]);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{service}</title>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://www.africanfamilyhero.co.za"/>
            </Helmet>
            <Topbar/>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        {/*<h6 className="section-title bg-white text-center text-primary px-3">{""}</h6>*/}
                        <h2 className="mb-5" style={{marginTop: '100px'}}>{service}</h2>
                        {
                            facility.map((item)=>{
                                return(
                                    <p>{item.text}</p>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Services;