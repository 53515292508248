import React, { useEffect } from 'react';
import Footer from './Footer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Topbar from './../main/Topbar';

const LatestMedicalBlogPost = () => {


    const [courses, setCourses] = React.useState([]);

    React.useEffect(()=>{
            fetch('http://localhost:5000/view/latest-courses')
                .then((res) => res.json())
                .then((data) => {
                    setCourses(data)
                })
                .catch((err)=>{
                    console.log(err.message);
                })
    },[])

    return(
        <>
            <Topbar/>
            <br/>
            <Box sx={{ flexGrow: 1}}>
                <Grid container spacing={{ xs: 2, md: 3}} columns={{ xs: 4, sm: 8, md: 12}} style={{marginTop: "125px"}}>
                    <Grid item xs={2} sm={4} md={4}>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} className="text-center mx-auto mb-5" variant="div">
                        <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">
                                    {"Courses"}
                        </h5>
                        <h1 className="display-4">{"Our latest blog courses"}</h1>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                            
                    </Grid>
                </Grid>
            </Box>
            <Footer/>      
        </>
    )
}

export default LatestMedicalBlogPost;