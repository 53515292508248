import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Paper from '@mui/material/Paper';
import DB from '../../db.js';
import { Observable } from 'rxjs';

const UserList = () => {

    const db = new DB('family_hero');
    const [bots, setBots] = useState([]);
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState('Please wait we are loading society...');

    const user = sessionStorage.getItem('email');

    useEffect(()=>{
        if((user !== null)){
            db.getBots().then((bot)=>{
                fetch("http://localhost:8080/users")
                .then((res) => res.json())
                .then((data) => {
                    if((data.length > 0)){
                        data.map((user)=>{
                            bot.push(user);
                            setBots(bot);
                        });
                    }else{
                        console.log('no registered users.');
                        setBots(bot);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            });
        }else{
            setInterval(function(){
                setStatus('Sign in or sign up to see society.')
            }, 30000);
        }
    },[]);

    function isOpen(ws){
        return ws.readyState === ws.OPEN
    }

    function capitalizeFirstLetter(str) {
        return str[0].toUpperCase() + str.slice(1);
    }

    function formatDate(date){
        const h = "0" + date.getHours();
        const m = "0" + date.getMinutes();

        return `${h.slice(-2)}:${m.slice(-2)}`;
    }

    async function handleChange(event){
        var user = sessionStorage.getItem("email");
        if((user !== null)){
            var username = event.target.value;
            var handle = "@"+username.toLowerCase();

            await db.viewBotMessage(handle).then((bot)=>{
                if(bot[0] == 'not_found'){
                    var objMessage = {
                        date:formatDate(new Date()),
                        handle:handle,
                        msg:"Hi, welcome to HeroChat! Go ahead and send me a message. 😄"
                    }
                    db.saveBotMessage(objMessage).then((res)=>{
                        if(res[0] == true){

                            sessionStorage.setItem('selected_chat_user',handle.substring(1).toLowerCase());
                            var email = sessionStorage.getItem('email');

                            const objUserSelects = {
                                "_id": handle.substring(1).toLowerCase(),
                                "name": handle.substring(1).toLowerCase(),
                                "handle": handle.substring(1).toLowerCase(),
                                "bot": handle.substring(1).toLowerCase(),
                                "from": email.toLowerCase()
                            };

                            (async () => {
                                  const rawResponse = await fetch('http://localhost:8080/selected-user',{
                                      method: 'POST',
                                      headers: {
                                          'Content-Type':'application/json'
                                      },
                                      body: JSON.stringify(objUserSelects)
                                  });
                                  const content = await rawResponse.json();
                                  console.log(content);
                            })();
                        }
                    });
                }else{
                    bot.map((i)=>{
                        if((i._id === handle)){

                            sessionStorage.setItem('selected_chat_user',handle.substring(1).toLowerCase());
                            var email = sessionStorage.getItem('email'); 

                            const objUserSelects = {
                                "_id": handle.toLowerCase(),
                                "name": handle.substring(1).toLowerCase(),
                                "handle": handle.substring(1).toLowerCase(),
                                "bot": handle.substring(1).toLowerCase(),
                                "from": email.toLowerCase()
                            };


                            (async () => {
                                  const rawResponse = await fetch('http://localhost:8080/selected-user', {
                                      method: 'POST',
                                      headers: {
                                          'Content-Type': 'application/json'
                                      },
                                      body: JSON.stringify(objUserSelects)
                                  });
                                  const content = await rawResponse.json();
                                  console.log(content);
                            })();
                        }
                    })
                }
            });
        }else{
            console.log('sign in or sign up to send and receive messages.');
        }
    }

    return (
                (() => {
                   if ((bots.length > 0)) {
                        return (
                            <>
                               <div>
                                   <Paper style={{ maxHeight: 323, overflow: 'auto' }} elevation={0}>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                onChange={handleChange}
                                            >
                                                {bots.map((bot,key)=>{
                                                    return(
                                                        <ListItem alignItems="flex-start" key={key}>
                                                            <ListItemAvatar>
                                                                <FormControlLabel value={bot.handle.substring(1)} control={<Radio
                                                                    checked={sessionStorage.getItem('selected_chat_user') === bot.handle.substring(1).toLowerCase()}
                                                                />}/>
                                                            </ListItemAvatar>
                                                            <ListItemAvatar>
                                                                <ListItemText
                                                                    primary={bot.username}
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            <Typography
                                                                                sx={{ display: 'inline'}}
                                                                                component="span"
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {bot.handle}
                                                                            </Typography>
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </ListItemAvatar>
                                                            <Divider/>
                                                        </ListItem>
                                                    )                                        
                                                })}
                                            </RadioGroup>
                                      </List>
                                   </Paper>
  
                                </div>
                            </>
                        )
                    } else if ((bots.length === 0)) {
                        return (
                            <div>{status}</div>
                        )
                    }
                   
                })()               
    )
}
export default UserList;