import React from 'react';
import Topbar from './Topbar';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { Helmet } from 'react-helmet';
import Footer from './Footer';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const SEOSitemap = () => {
    
    return(
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{"African family hero - sitemap"}</title>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="viewport" content={"width=device-width, initial-scale=1.0"}/>
                <link rel="canonical" href="https://www.africanfamilyhero.co.za/sitemap"/>
            </Helmet>
            <Topbar/>
            <br/>
            <Grid content spacing={6}>
                
                <div className="container-fluid my-5 py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px',marginTop:'65px'}}>
                            <h2 className="d-inline-block text-black text-uppercase border-bottom border-5">{"Sitemap"}</h2>
                            <br/>
                            <h3 className="display-4 mb-4">{"Whole website sitemap"}</h3>
                            <h2 className="text-black fw-normal">{"Browse through the website using our sitemap to find african health specialists, articles and more."}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4 justify-content-center">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <div className="panel-group" id="accordion">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                <h3 className="panel-title">
                                    <a data-toggle="collapse" data-parent="#accordion" target="_blank" href="https://africanfamilyhero.co.za/sitemap_0.xml" style={{textDecoration:'none'}}>
                                        {"Website xml structure"}
                                    </a>
                                </h3>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a data-toggle="collapse" data-parent="#accordion" target="_blank" href="https://africanfamilyhero.co.za/sitemap_3.xml" style={{textDecoration:'none'}}>
                                        {"Posts xml sitemap"}
                                    </a>
                                </h4>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a data-toggle="collapse" data-parent="#accordion" target="_blank" href="https://africanfamilyhero.co.za/sitemap_2.xml" style={{textDecoration:'none'}}>
                                        {"Doctor xml sitemap"}
                                    </a>
                                </h4>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" target="_blank" href="https://africanfamilyhero.co.za/sitemap_1.xml" style={{textDecoration:'none'}}>
                                            {"Appointment xml sitemap"}
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" target="_blank" href="https://africanfamilyhero.co.za/sitemap_4.xml" style={{textDecoration:'none'}}>
                                            {"Course categories xml sitemap"}
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" target="_blank" href="#" style={{textDecoration:'none'}}>
                                            {"HeroChat xml sitemap"}
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
            <Footer/>
        </div>
        

    );
}

export default SEOSitemap; 