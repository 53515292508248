import React from 'react';
import Topbar from './Topbar';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const apply = () => {
    console.log('applying for a job button clicked..');
}   

const Careers = () => {
    
    return(
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{"family heroes - careers "}</title>
                <meta name="description" content={"Careers, medical students and professionals that are ready make a move, here are the vaccancies "}/>
                <meta name="keywords" content={""}/>
            </Helmet>
            <Topbar/>
            <div>
                <br/>
                <Grid container spacing={3}>
                    <Grid item xs></Grid>
                    <Grid item xs={6}>
                        <Item variant="outlined">
                            <h3>Careers, ready to make a move, apply here are the latest vaccancies.</h3>
                        </Item>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
                <br/>
                <Grid container spacing={3} style={{ paddingLeft: '250px'}}>
                    <Grid item xs></Grid>
                    <Grid item xs={6}>
                             
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar>
                                <ImageIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="React Developer" secondary="Remote - Jan 18, 2022" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar>
                                <BeachAccessIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Java Developer" secondary="Remote - July 15, 2022" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar>
                                <BeachAccessIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Full Stack Developer" secondary="Remote - July 16, 2022" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar>
                                <BeachAccessIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Web Developer" secondary="Remote - July 16, 2022" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar>
                                <BeachAccessIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Microservices Developer" secondary="Remote - July 17, 2022" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar>
                                <BeachAccessIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Database Developer" secondary="Remote - July 17, 2022" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar>
                                <BeachAccessIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Scrum master" secondary="Remote - July 17, 2022" />
                        </ListItem>
                        <ListItem>
                            <Button>apply</Button>
                        </ListItem>
                        </List>      
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>

             

            </div>
            {/*<AppBar position="fixed" 
                    variant="outlined" 
                    color="primary" 
                    sx={{ top: 'auto', bottom: 0, fontSize: '3rem' }} 
                    >
                <Toolbar
                    style={{ minHeight: '154px', fontSize: '1rem'}}
                >
                    <div>
                            <IconButton color="inherit">
                                <Link to={"/contact"} style={{textDecoration: 'none'}}>
                                    <Typography
                                        style={{color:"#ffffff"}}
                                    >
                                        Contact |
                                    </Typography>
                                </Link>
                            </IconButton>
                            <IconButton color="inherit">
                                <Link to={"/careers"} style={{textDecoration: 'none'}}>
                                    <Typography
                                        style={{color:"#ffffff"}}
                                        >
                                     Careers |
                                    </Typography>
                                </Link>
                                 
                            </IconButton>
                            <IconButton color="inherit">
                                <Link to={"#"} style={{textDecoration: 'none'}}>
                                    <Typography
                                        style={{color:"#ffffff"}}
                                    >
                                        Sitemap |
                                    </Typography>
                                </Link>
                            </IconButton>
                            <IconButton color="inherit">
                                <Link to={"/faq"} style={{textDecoration: 'none'}}>
                                    <Typography
                                    style={{color:"#ffffff"}}
                                        >
                                     Frequently asked questions |
                                    </Typography>
                                </Link>
                                 
                            </IconButton>
                    </div>
                    <div>
                        <Typography paragraph>
                            - © 2022 Family heroes ZA. <br/>
                            - Granada Centre, 3 Corlett Dr, Illovo, Johannesburg, 2196, Andiccio24 🍕<br/>
                            - Lesiba@tsatsile.co.za <br/>
                            - Developer
                        </Typography>
                    </div>

                </Toolbar>
    </AppBar>*/}
        </div>
    )
}

export default Careers;