import React from 'react';
import Topbar from '../main/Topbar';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const Ambulance = () => {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{"Free ambulance"}</title>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="viewport" content={"width=device-width, initial-scale=1.0"}/>
                <link rel="canonical" href=""/>
            </Helmet>
            <Topbar/>
            <br/><br/><br/><br/>
            <Box sx={{ flewGrow: 1}}>
                <Grid container spacing={3}>
                    <Grid item xs></Grid>
                    <Grid item xs={15}>
                        <div className="container-fluid py-5">
                            <div className="container">
                                <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px'}}>
                                    <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">{"Free ambulance"}</h5>
                                    <h1 className="display-4">{""}</h1>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
            </Box>
            <Footer/>
        </>
    )
}

export default Ambulance;