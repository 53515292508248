import React from 'react';
import AppBar from '@mui/material/AppBar';
import {  Link, Route, Routes, useLocation } from "react-router-dom";


const pages = ['Patients', 'Doctor']
//const settings = ['Profile','Account','Dashboard','Logout']

const Topbar = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseNavMenu = (event) => {
        setAnchorElNav(null);
    }
    const handleCloseUserMenu = (event) => {
        setAnchorElUser(null)
    }
    
    const aboutUsPage = (event) => {
        event.preventDefault();
        window.open('http://localhost:3000/about', '_blank');
    }

    const nearbyDoctor = (event) => {
        event.preventDefault();
        window.open('http://localhost:3000/find-a-doctor', '_blank');
    }

    const pricing = (event) => {
        event.preventDefault();
        window.open('http://localhost:3000/pricing', '_blank');
    }

    const contact = (event) => {
        event.preventDefault();
        window.open('http://localhost:3000/contact', '_blank');
    }

    const location = useLocation();
    const pathname = location.pathname;

    return(

        <AppBar>
        <div className="container-fluid py-2 border-bottom d-none d-lg-block bg-white">
          <div className="container">
              <div className="row">
                <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                  <div className="d-inline-flex align-items-center">
                    <a className="text-decoration-none text-body pe-2" href="#"><i className="bi bi-telephone me-2"></i>+012 345 7689</a>
                    <span className="text-body">|</span>
                    <a className="text-decoration-none text-body px-3" href="#"><i className="bi bi-envelope me-2"></i>lesiba.less@gmail.com</a>
                  </div>
                </div>
                <div className="col-md-6 text-center text-lg-end">
                  <div className="d-inline-flex align-items-center">
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div className="container-fluid sticky-top bg-white shadow-sm">
            <div className="container">
                <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0">
                  <a href="/" className="navbar-brand">
                    <h1 className="m-0 text-uppercase text-primary"><i className="fa fa-clinic-medical me-2"></i>Family Hero</h1>
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                      <a href="/forum" className="nav-item nav-link">{""}</a>
                      {/*<a href="about" className="nav-item nav-link active">About</a>
                      <a href="find-a-doctor" className="nav-item nav-link">Find A Doctor</a>
                      <a href="patient-memberships" className="nav-item nav-link">{"Patients"}</a>
                      <a href="specialist-memberships" className="nav-item nav-link">{"Specialists"}</a>*/}
                    </div>
                  </div>
                </nav>
            </div>
          </div>
      </AppBar>
    );

   
}

export default Topbar;