import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase-config'; 
import AuthTopbar from './AuthTopbar';

const Auth = ()=>{

    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [isValid, setIsValid] = useState(true);
    const navigate = useNavigate();

    const signIn = async (event) =>{
        event.preventDefault(); 
        if(loginEmail == ""){
            setIsValid(false);
        }
        if(loginPassword == ""){
            setIsValid(false);
        }

        if((loginEmail != "") && (loginPassword != "")){
            try{

               const user = await signInWithEmailAndPassword(
                    auth,
                    loginEmail,
                    loginPassword
                );

                if(user.user.email.length > 0){
                    console.log("/..");
                    navigate("/home");
                }
                /*if(user.user.email.length > 0){
                    history.push('/dashboard')
                }*/
            }catch(error){
                console.log(error);
            }
        }

    }


    const signInButtonStyle = {
        backgroundColor: "darkcyan",
        borderColor: "darkcyan",
    }

    return (
        <>
           <AuthTopbar/>
           <div className="auth-form-container row justify-content-center position-relative" style={{ marginTop:'0px',zIndex:1 }}>
            <form className="auth-form">
                <div className="auth-form-content">
                        <h3 className="auth-form-title">Sign In</h3>
                        <div className="form-group mt-3">
                                <label>Email address</label>
                                <input 
                                    type="text"
                                    onChange={(event) => {setLoginEmail(event.target.value);}}
                                    className="form-control mt-1"
                                    placeholder="Enter email"
                                    style={{borderColor: !isValid ? 'red':''}}
                                    required
                                />
                        </div>
                        <div className="form-group mt-3">
                                <label>Password</label>
                                <input 
                                    type="password"
                                    onChange={(event) => {setLoginPassword(event.target.value)}}
                                    className="form-control mt-1"
                                    placeholder="Enter password"
                                    style={{borderColor: !isValid ? 'red':''}}
                                    required
                                />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button 
                                type="submit" 
                                className="btn btn-primary"
                                onClick={signIn}
                                style={signInButtonStyle}
                                >
                                Submit
                            </button>
                        </div>
                        <p className="forgot-password text-right mt-2"></p>
                </div>
            </form>
        </div>
        {/*<Footer/>*/}
        </>   
    )
}
export default Auth;