import axios from "axios";
import { useEffect, useState } from "react";

export default function useGeolocationIPData(){
	const [locationData, setLocationData] = useState(null);
	useEffect(()=>{
		getLocation();
	},[]);

	//async function getLocation(){
	//	const res = await axios.get("https://api.ipdata.co?api-key=0f1dd01fa46498aec0de3d11522477747ddcce285163960ea387363b");
		//console.log(res);
	//	if (res.status === 200)
	//		setLocationData(res.data);
	//}

	async function getLocation(){
		try{
			const res = await axios.get("https://api.ipdata.co?api-key=0f1dd01fa46498aec0de3d11522477747ddcce285163960ea387363b");
			if (res.status === 200){
				setLocationData(res.data);
			} else {
				console.error(`Unexpected response status ${res.status}`);
			}

		} catch (error) {
			if (error) {
				if (error.response) {
					// The request was made and the server response with a status code outside the 2xx range
					console.error("Response error:", error.response.data);
					console.error("Response status:", error.response.status);
					console.error("Response headers:", error.response.headers);
				} else if (error.request) {
					// The request was made out no response was received
					console.error("No response received:", error.request);
				} else {
					// Something happened in setting up the request
					console.error("Error setting up request:", error.message);
				}
			}
		}
	}

	return {
		ip_ipdata: locationData?.ip,
		is_eu_ipdata: locationData?.is_eu,
		city_ipdata: locationData?.city,
		region_ipdata: locationData?.region,
		region_code_ipdata: locationData?.region_code,
		region_type_ipdata: locationData?.region_type,
		country_name_ipdata: locationData?.country_name,
		country_code_ipdata: locationData?.country_code,
		continent_name_ipdata: locationData?.continent_name,
		continent_code_ipdata: locationData?.continent_code,
		latitude_ipdata: locationData?.latitude,
		longitude_ipdata: locationData?.longitude,
		postal_ipdata: locationData?.postal,
		calling_code_ipdata: locationData?.calling_code,
		flag_ipdata: locationData?.flag,
		emoji_flag_ipdata: locationData?.emoji_flag,
		languages_ipdata: locationData?.languages,
		currency_ipdata: locationData?.currency,
		time_zone_ipdata: locationData?.time_zone
	}
}