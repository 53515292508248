import React,{ useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import MedicationIcon from '@mui/icons-material/Medication';
import EventIcon from '@mui/icons-material/Event';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import TagIcon from '@mui/icons-material/Tag';
import SmsIcon from '@mui/icons-material/Sms';
import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import { styled, useTheme, alpha } from '@mui/material/styles';
import {  Link, Route, Routes, useLocation } from "react-router-dom";
import Home from '../../pages/home/Home';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Line } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import { CardActionArea } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PeopleIcon from '@mui/icons-material/People';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CampaignIcon from '@mui/icons-material/Campaign';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Rating from '@mui/material/Rating';

import EditIcon from '@mui/icons-material/Edit';

import axios from 'axios';

import Chip from '@mui/material/Chip';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import * as THREE from 'three';
import InsightsIcon from '@mui/icons-material/Insights';
import StarIcon from '@mui/icons-material/Star';
import DB from '../../db'
import user from '../main/images/user.jpg';
import { NotificationsSharp } from '@mui/icons-material';
//const useStyles = makeStyles((theme) => ({}))
const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
);

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Transition = React.forwardRef(function Transition(props, ref){
    return <Slide direction="up" ref={ref}{...props}/>
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

  
const StyledMenu = styled((props) => {

})(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
          theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
          'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
          padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
          '& .MuiSvgIcon-root': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
          },
          '&:active': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
}));


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const contentWidth = {
    width: '157vh'
}

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root':{
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root':{
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props){
    const { children, onClose, ...other} = props;

    return(
        <DialogTitle sx={{ m:0, p: 2 }} {...other}>
           {children}
           {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


const Sidebar = () => {

    const [chipData, setChipData] = React.useState([
        { key: 0, label: 'Angular' },
        { key: 1, label: 'jQuery' },
        { key: 2, label: 'Polymer' },
        { key: 3, label: 'React' },
        { key: 4, label: 'Vue.js' },
    ]);

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };


    const [items, setItems] =  useState([]);
    const [slug, setSlug] = useState('');
    const [sidebarHeightX, setSidebarHeightX] = useState('');
    const [keyword, setKeyword] = useState('');
    const [website, setWebsite] = useState('');
    const [keywords, setKeywords] = useState('');
    const [open, setOpen] = useState(false);
    const [articlesOpen, setArticlesOpen] = useState(false);
    const [pages, setPages] = useState('');
    const [articles, setArticles] = useState(null);
    const [doctors, setDoctors] = useState(null);
    const [dialogOpen, setDialogOpen] = useState('');
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [timer, setTimer] = useState('');
    const [campaign, setCampaign] = useState('choose_campaign');
    const [body, setBody] = useState('');
    const [specialist, setSpecialist] = useState([]);
    const [posts, setPosts] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [filteredSpecs, setFilteredSpecs] = useState([]);
    const [department,setDepartment] =  useState([]);

    const [tagPost, setTagPost] =  useState([]);
    const [sites, setSites] = useState([]);
    const [keywordX, setKeywordX] = useState([]);

    const [openAppointment, setOpenAppointment] = useState(false);
    const [openComment, setOpenComment] = useState(false);

    const [logId, setLogId] = useState('');
    const[logTitle, setLogTitle] = useState('');
    const [logCourse, setLogCourse] = useState('');
    const [logInstructor, setLogInstructor] = useState('');
    const [logDuration, setLogDuration] = useState('');
    const [logStudent, setLogStudent] = useState('');

    const [openKeyword, setOpenKeyword] = useState(false);
    const [openNormalized, setOpenNormalized] = useState(false);

    const [selectDpt, setSelectDpt] = useState([]);
    const [selectSite, setSelectSite] = useState([]);

    const [idY, setIdY] = useState('');
    const [nameY, setNameY] = useState('');
    const [emailY, setEmailY] = useState('');
    const [departmentY, setDepartmentY] = useState('');
    const [latitudeY, setLatitudeY] = useState('');
    const [longitudeY, setLongitudeY] = useState('');
    const [learningScoreY, setLearningScoreY] = useState(0);
    const [imageY, setImageY] = useState('');
    const [descriptionY, setDescriptionY] = useState('');
    const [bedY, setBedY] = useState('');
    const [telY, setTelY] = useState('');

    const [Idx, setIdx] = useState('');
    const [userImage, setUserImage] = useState('');
    const [blogImage, setBlogImage] = useState('');
    const [blogTitle, setBlogTitle] = useState('');
    const [blogCategory, setBlogCategory] = useState('');
    const [blogBody1, setBlogBody1] = useState('');
    const [blogBody2, setBlogBody2] = useState('');
    const [blogBody3, setBlogBody3] = useState('');
    const [author, setAuthor] = useState('');
    const [seoScore, setSeoScore] = useState('');
    const [slugX, setSlugX] = useState('');

    const [postTotal, setPostTotal] = useState(0);
    const [doctorTotal, setDoctorTotal] = useState(0);
    const [normalized, setNormalized] = useState([]);

    const [department1,setDepartment1] = useState('');
    const [hospital1, setHospital1] = useState('');

    const [googleKeywords, setGoogleKeywords] = useState([]);

    const [xId, setXid] = useState('');
    const [dKeyword, setDKeyword] = useState('');
    const [dLowRange, setDLowRange] = useState('');
    const [dHighRange, setDHighRange] = useState('');
    const [dSlug, setDSlug] = useState('');

    const [dId, setdID] = useState('');
    const [dBio, setdBio] = useState('');
    const [dCert, setdCert] = useState('');
    const [dDepartment, setdDepartment] = useState('');
    const [dLearnScore, setDLearnScore] = useState(0);
    const [dName, setDname] = useState('');
    const [dSpec, setDspec] = useState('');
    const [dPic, setdPic] = useState('');
    const [dImage,setdImage] = useState('');

    const [appointments, setAppointments] = useState([]);
    const [appointmentTotal, setAppointmentTotal] = useState(0);

    const [docDesc, setDocDesc] = useState('');
    const [idYX, setIdYX] = useState('');
    const [dateXY, setDateXY] = useState('');
    const [specialistXY, setSpecialistXY] = useState('');
    const [nameXY, setNameXY] = useState('');
    const [timeXY, setTimeXY] = useState('');
    const [departmentXY, setDepartmentXY] = useState('');
    const [messageXY, setMessageXY] = useState('');
    const [emailXY, setEmailXY] = useState('');
    const [keywordTotal, setKeywordTotal] = useState(0);
    
    const [action, setAction] = useState('');

    const [articleSnackOpen, setArticleSnackOpen] = useState(false);
    const [scraperSnackOpen, setScraperSnackOpen] = useState(false);
    const [scraperStatusOpen, setScraperStatusOpen] = useState(false);
    const [errorStatusOpen, setErrorStatusOpen] = useState(false);
    const [homeSnackbarOpen,setHomeSnackbarOpen] = useState(false);
    //const [articleSnackClose, setArticleSnackClose] = React.useState(false);
    const [keywordResearch, setKeywordResearch] = useState([]);

    const [courses, setCourses] = useState([]);
    const [courseTotal, setCoursesTotal] = useState(0);

    const [openX, setOpenX] = useState(false);
    const [openXY, setOpenXY] = useState(false);
    const [openY,setOpenY] = useState(false);
    const [openXYX, setOpenXYX] = useState(false);

    const [openDocEdit, setDocEdit] = useState(false);
    const [openOriginalEdit, setOpenOriginalEdit] = useState(false);
    const [openBio, setOpenBio] = useState(false);

    const [openContactsEdit, setOpenContactsEdit] = useState(false);

    const [specialists, setSpecialists] = useState([]);
    const [campaigns, setCampaigns] = useState([])

    const [createCampaingOpen, setCreateCampaingOpen] = useState(false);
    const [openCampignSnackbar, setOpenCampaignSnackbar] = useState(false);

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [comments, setComments] = useState([]);

    const [postActionType, setPostActionType] = useState('');
    const [appointmentActionType, setAppointmentActionType] = useState('');
    const [homeActionSnackbar, setHomeActionSnackbar] = useState(false);

    const [commentAppointment, setCommentAppointment] = useState('');
    const [appointmentRelationshipAction, setAppointmentRelationshipAction] = useState('');
    const [commentAppointmentAction, setCommentAppointmentAction] = useState('');
    const [notificationSection, setNotificationSection] = useState('');

    const [idC, setIdC] = useState('');
    const [userC, setUserC] = useState('');
    const [dateC, setDateC] = useState('');
    const [commentC, setCommentC] = useState('');
    const [imageC, setImageC] = useState('');
    const [appointmentC, setAppointmentC] = useState('');
    const [typeC, setTypeC] = useState('');

    const [homeAction, setHomeAction] = useState('');
    const [openEditSnackbar, setOpenEditSnackbar] = useState(false);

    const [commentErrorSnackbarOpen, setCommentErrorSnackbarOpen] = useState(false);
    const [commentErrorMessage, setCommentErrorMessage] = useState('');
    const [contacts, setContacts] = useState([]);
    const [newsletter, setNewsletter] = useState([]);

    const [contactId, setContactId] = useState('');
    const [contactSubject, setContactSubject] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactMessage, setContactMessage] = useState('');

    const [contactInfoSnackbar, setContactInfoSnackbar] = useState(false);
    const [contactInfoMessage, setContactInfoMessage] = useState('');

    const [membershipId, setMembershipId] = useState('');
    const [membershipEmail, setMembershipEmail] = useState('');
    const [membershipDialog, setMembershipDialog] = useState(false);

    const [membershipSnackbar, setMembershipSnackbar] = useState(false);
    const [membershipMessage, setMembershipMessage] = useState('');

    const [latestNews, setLatestNews] = useState([]);
    const [latestNewsTotal, setLatestNewsTotal] = useState(0);

    const [openLatestNewsDialog, setOpenLatestNewsDialog] = useState(false);

    const [latestNewsId, setLatestNewsId] = useState('');
    const [latestNewsHeight, setLatestNewsHeight] = useState('');
    const [latestNewsImage, setLatestNewsImage] = useState('');
    const [latestNewsTitle, setLatestNewsTitle] = useState('');
    const [latestNewsBody1, setLatestNewsBody1] = useState('');
    const [latestNewsBody2, setLatestNewsBody2] = useState('');
    const [latestNewsBody3, setLatestNewsBody3] = useState('')
    const [latestNewsSeoscore, setLatestNewsSeoscore] = useState('');
    const [latestNewsAuthor, setLatestNewsAuthor] = useState('');
    const [latestNewsCategory, setLatestNewsCategory] = useState('');
    const [latestNewsSlug, setLatestNewsSlug] = useState('');
    
    const [notificationId, setNotificationId] = useState(0);
    const [notificationTitle, setNotificationTitle] = useState([]);
    const [notificationImage, setNotificationImage] = useState([]);
    const [notificationAbout, setNotificationAbout] = useState([]);
    const [notificationUser, setNotificationUser] = useState([]);
    const [notificationDate, setNotificationDate] = useState([]);
    const [notificationSlug, setNotificationSlug] = useState([]);
    
    const [notificationMessage, setNotificationMessage] = useState('');
    const [openNotificationSnackbar, setOpenNotificationSnackbar] = useState(false);

    const [openCommentSnackbar, setCommentSnackbar] = useState(false);
    const [errorCommentMessage, setErrorCommentMessage] = useState('');
    
    const [openAdvertiseDialog, setOpenAdvertiseDialog] = useState(false);
    const [advertiseId, setAdvertising] = useState("");
    const [advertiseAd, setAdvertisingAd] = useState("");
    const [advertiseDepartment, setAdvertisingDepartment] = useState("");
    const [storage, setStorage] = useState("");
    const [notifications, setNotifications] = useState([]);

    const [users, setUsers] = useState([]);
    const [usersTotal, setUsersTotal] = useState(0);
    const [adminsTotal, setAdminsTotal] = useState(0);
    const [lmsCategories, setLmsCategories] = useState([]);

    const scene = new THREE.Scene();

    const theme = useTheme();

    const location = useLocation();
    const pathname = location.pathname;
    const db = new DB('family_hero');

    const createCampaign = (event) => {
        event.preventDefault();
        setCreateCampaingOpen(true);
    }


    const handleMembershipSnackbarClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'click'){
            return;
        }
        setMembershipSnackbar(false);
    }

    const handleCommentSnackbarClose = (event,reason)=>{
        event.preventDefault();
        if(reason == 'click'){
            return;
        }
        setCommentSnackbar(true);
    }

    const handleContactInfoClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'click'){
            return;
        }
        setContactInfoSnackbar(false);
    }

    const handleLatestNewsDialogClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'click'){
            return;
        }
        setOpenLatestNewsDialog(false);
    }

    const handleLatestNewsUpdates = (event)=>{
        event.preventDefault();

        const baseURL = "http://localhost:5000/view/update-latest-news";

        setLatestNewsId(latestNewsId);
        setLatestNewsImage(latestNewsImage);
        setLatestNewsHeight(latestNewsHeight);
        setLatestNewsTitle(latestNewsTitle);
        setLatestNewsCategory(latestNewsCategory);
        setLatestNewsBody1(latestNewsBody1);
        setLatestNewsBody2(latestNewsBody2);
        setLatestNewsBody3(latestNewsBody3);
        setLatestNewsAuthor(latestNewsAuthor);
        setLatestNewsSeoscore(latestNewsSeoscore);
        setLatestNewsSlug(latestNewsSlug);

        const objPost = {
            "id":latestNewsId,
            "sidebarHeight":latestNewsHeight,
            "userImage":userImage,
            "blogImage":latestNewsImage,
            "blogTitle":latestNewsTitle,
            "category":latestNewsCategory,
            "blogBody1":latestNewsBody1,
            "blogBody2":latestNewsBody2,
            "blogBody3":latestNewsBody3,
            "seoScore":latestNewsSeoscore,
            "slug":latestNewsSlug,
            "author":latestNewsAuthor
        }

        try{
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify(objPost)
            }).then((response)=>{
                if(response.status === 201){
                    //alert(response.status);
                    setOpenLatestNewsDialog(true);
                    console.warn("success part");
                }else{
                    console.warn("error part");
                }
            }).catch((err)=>{
                console.log(err);
            });
        }catch(err){
            console.log(err);
        }

    }

    const handleMembershipClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'click'){
            return;
        }
        setMembershipDialog(false);
    }

    const handleOriginalDocClose = (event,reason)=>{
        event.preventDefault();
        if(reason == 'click'){
            setOpenOriginalEdit(false);
            return;
        }
        setOpenOriginalEdit(false);
    }

    const handleCommentErrorClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'click'){
            setCommentErrorSnackbarOpen(false);
            return;
        }
        setCommentErrorSnackbarOpen(false);
    }

    const handleBioClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'click'){
            setOpenBio(false);
            return;
        }
        setOpenBio(false);
    }

    const handleNormClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'click'){
            setOpenNormalized(false);
            return;
        }
        setOpenNormalized(false);
    }

    const handleCommentEditClose = (event, reason) =>{
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setOpenEditSnackbar(false);
    }

    const handleCourseClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setOpenY(false);
    }

    const createCampaignSnackbarClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setCreateCampaingOpen(false);
    }

    const snackBarNotificationClose = (event, reason) => {
        if(reason == 'clickaway'){
            return;
        }
        setOpenNotificationSnackbar(false);
    }
    const snackBarClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setSnackBarOpen(false);
    }

    const homeActionSnackbarClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setHomeActionSnackbar(false);
    }

    const homeSnackbarClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setHomeSnackbarOpen(false);
    }

    const snackBarCloseXY = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setOpenXY(false);
    }


    const errorStatusClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setErrorStatusOpen(false)
    }

    const scraperStatusClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setScraperStatusOpen(false);
    }

    const articleBarClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setArticleSnackOpen(false);
    }

    const scraperBarClose = (event, reason) => {
        event.preventDefault();
        if(reason == 'clickaway'){
            return;
        }
        setScraperSnackOpen(false)
    }

    const openingArticleSnackBar = () => {
        setArticleSnackOpen(true);
    }

    const openingSnackBar = () => {
        setSnackBarOpen(true);
    }

    const closingDialog = () => {
        setDialogOpen(false);
        console.log('closing dialog');
    }

    const handleCourseTitle = (event) => {
        event.preventDefault();
        setLogTitle(event.target.value);
    }

    const handleCourse = (event) => {
        setLogCourse(event.target.value);
    }

    const handleActionHome = (event) => {
        event.preventDefault();
        setHomeAction(event.target.value);
    }
    

    const handleLogInstructor = (event) => {
        event.preventDefault();
        setLogInstructor(event.target.value);
    }

    const handleLogDuration = (event) => {
        event.preventDefault();
        setLogDuration(event.target.value);
    }

    const handleLogStudent = (event) => {
        event.preventDefault();
        setLogStudent(event.target.value);
    }

    const handleUserImage = (event) => {
        event.preventDefault();
        setUserImage(event.target.value);
    }

    const handleBlogImage = (event) => {
        event.preventDefault();
        setBlogImage(event.target.value);
    }

    const handleBlogTitle = (event) => {
        event.preventDefault();
        setBlogTitle(event.target.value);
    }


    const handleBlogCategory = (event) => {
        event.preventDefault();
        setBlogCategory(event.target.value);
    }

    const handleSeoScore = (event) => {
        event.preventDefault();
        setSeoScore(event.target.value);
    }

    const closeX = (event) => {
        event.preventDefault();
        event.preventDefault();
    }


    const handleDepartmentDocChange = (event) => {
        event.preventDefault();
        setdDepartment(event.target.value)
    }

    const handleLearnScoreDocChange = (event) => {
        event.preventDefault();
        setDLearnScore(event.target.value);
    }

    const handleNameDoc = (event) => {
        event.preventDefault();
        setDname(event.target.value);
    }

    const handleCertificatesDoc = (event) => {
        event.preventDefault();
        setdCert(event.target.value);
    }

    const handleSpecialistDoc = (event) => {
        event.preventDefault();
        setDspec(event.target.value);
    }

    const handlePictureDoc = (event) => {
        event.preventDefault();
        setdPic(event.target.value);
    }

    const handleOriginalDoctorName = (event) => {
        event.preventDefault();
        setNameY(event.target.value);
    }

    const handleOrignalDoctorEmail = (event) => {
        event.preventDefault();
        setEmailY(event.target.value);
    }

    const getAppointmentAction = (event) => {
        event.preventDefault();
        setAppointmentActionType(event.target.value);
    }

    const handleOriginalDoctorDepartment = (event) => {
        event.preventDefault();
        setDepartmentY(event.target.value);
    }

    const handleBedChangeX = (event) => {
        event.preventDefault();
        setBedY(event.target.value);
    }

    const handleLatitudeDoctor = (event) => {
        event.preventDefault();
        setLatitudeY(event.target.value);
    }

    const handleLongitudeDoctor = (event) => {
        event.preventDefault();
        setLongitudeY(event.target.value);
    }

    const handleLearningScoreDoctor = (event) => {
        event.preventDefault();
        setLearningScoreY(event.target.value);
    }

    const handleAuthor = (event) => {
        event.preventDefault();
        setAuthor(event.target.value);
    }

    const handleSidebarHeight = (event) => {
        event.preventDefault();
        setSidebarHeightX(event.target.value);
    }

    const handleImageUpdate = (event) =>{
        event.preventDefault();
        setImageY(event.target.value);
    }

    const handleCloseX = (event)=>{
        event.preventDefault();
        setOpenX(false);
    }
    

    const handleCloseXYX = (event) => {
        event.preventDefault();
        setOpenXYX(false);
    }

    const handleHomeAction = (event) => {
        event.preventDefault();
        setHomeAction(event.target.value);
    }

    const handleChange = (event) =>{
        event.preventDefault();
        setAppointmentC(event.target.value);
    }


    const storageSave = (event) => {
        event.preventDefault();
        var db = new DB('family_hero');
       // db.createAllData();
        
        if(storage == ""){
            console.log("storage is empty");
        }else if(storage == "store_data"){
            db.createAllData();
        }
        /*else if(storage == "departments"){
            db.createDepartment();
        }else if(storage == "notifications"){
            db.createNotifications();
        }else if(storage == "posts"){
            db.createPost();
        }else if(storage == "latest_news"){
            db.latestPosts();
        }else if(storage == "specialists"){
            db.createSpecialists();
        }else if(storage == "post_keywords"){
            db.createPostsKeywords();
        }else if(storage == "normalized_doctor"){
            console.log(storage);
        }else if(storage == "doctor"){
            console.log(storage);
        }else if(storage == "appointment"){
            console.log(storage);
        }else if(storage == "recent_posts"){
            db.createRecentPosts();
        }else if(storage == "biographies"){
            db.createBio();
        }*/
    }
    
    const homeSave = (event) => {

        event.preventDefault();
        
        if(homeAction == ""){
            setHomeActionSnackbar(true);
        }else if(homeAction == 'choose_a_sitemap'){
            setHomeActionSnackbar(true);
        }
        else if(homeAction == 'website_sitemap'){
            fetch('http://localhost:5000/sitemap/save-website-sitemap')
             .then((data)=>{
                if(data.status === 201){
                    setHomeSnackbarOpen(true);
                }
             })
             .catch((err)=>{
                console.log(err.message);
             });
        }else if(homeAction == 'post_sitemap'){
            fetch('http://localhost:5000/sitemap/save-post-sitemap')
            .then((data)=>{
               if(data.status === 201){
                   setHomeSnackbarOpen(true);
               }
            })
            .catch((err)=>{
               console.log(err.message);
            });
        }else if(homeAction == 'doctor_sitemap'){
            fetch('http://localhost:5000/sitemap/save-doctor-sitemap')
            .then((data)=>{
               if(data.status === 201){
                   setHomeSnackbarOpen(true);
               }
            })
            .catch((err)=>{
               console.log(err.message);
            });
        }else if(homeAction == 'appointment_sitemap'){
            fetch('http://localhost:5000/sitemap/save-appointment-sitemap')
            .then((data)=>{
               if(data.status === 201){
                   setHomeSnackbarOpen(true);
               }
            })
            .catch((err)=>{
               console.log(err.message);
            });
        }
    }
    
    const updateCourse = (event) => {

        event.preventDefault();

        const baseURL = "http://localhost:5000/view/update-course";

        const objData = {
            id: logId,
            title: logTitle,
            course: logCourse,
            instructor: logInstructor,
            duration: logDuration,
            students: logStudent
        }


        try{
            
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify(objData)
            }).then((response) => {
                if(response.status == 201){
                      setOpenY(true);
                      setOpenXYX(false);
                }
            }).catch((error)=>{
                console.log(error);
            });

        }catch(error){
            console.log(error)
        }

    }

    // Original data 
    const saveX = (event) => {

        event.preventDefault();

        const baseURL = "http://localhost:5000/view/update-doctor";

        setIdY(idY);
        setNameY(nameY);
        setEmailY(emailY);
        setDepartmentY(departmentY);
        setLatitudeY(latitudeY);
        setLongitudeY(longitudeY);
        setLearningScoreY(learningScoreY);
        setImageY(imageY);
        setDocDesc(docDesc);
        setBedY(bedY);

        const objDoc = {
            "id": idY,
            "name": nameY,
            "email":emailY,
            "department":departmentY,
            "latitude":latitudeY,
            "longitude":longitudeY,
            "learningScore":learningScoreY,
            "image":imageY,
            "description":docDesc,
            "bed":bedY
        }

        console.log(objDoc);

        try{
           
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify(objDoc)
            }).then((data)=>{
                
                if(data.status === 201){
                    setOpenOriginalEdit(true);
                    handleClose();
                }

            }).catch((err)=>{
                console.log(err);
            });

        }catch(error){
            console.log(error)
        }

    }
    const getNotificationSection = (event)=>{
        setNotificationSection(event.target.value);
    }

    const pushNotifications = () => {
        
        const db = new DB('family_hero');

        setTimeout(()=>{
            if(notificationSection === 'posts'){
                 console.log(notificationSection);
            }else if(notificationSection === 'doctor'){
                 console.log(notificationSection);
            }else if(notificationSection === 'appointments'){
                 console.log(notificationSection);
            }else if(notificationSection === 'courses'){
                 console.log(notificationSection);
            }
        },400);

    }

    const save = (event) => {

        event.preventDefault();

        const baseURL = "http://localhost:5000/view/update-article";

        setIdx(Idx);
        setSidebarHeightX(sidebarHeightX);
        setBlogImage(blogImage);
        setBlogTitle(blogTitle);
        setBlogCategory(blogCategory);
        setBlogBody1(blogBody1);
        setBlogBody2(blogBody2);
        setBlogBody3(blogBody3);
        setAuthor(author);
        setSeoScore(seoScore);
    
        const objPost = {
            'id':Idx,
            'sidebarHeight':sidebarHeightX,
            'userImage':userImage,
            'blogImage':blogImage,
            'blogTitle':blogTitle,
            'category':blogCategory,
            'blogBody1':blogBody1,
            'blogBody2':blogBody2,
            'blogBody3':blogBody3,
            'seoScore':seoScore,
            'slug':slug,
            'author':author
        }

        //console.log(objPost);


        try{
            
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify(objPost)
            }).then((response)=>{
                
                console.log(response.status);

                if(response.status === 201){
                    openingArticleSnackBar();
                    handleClose();
                    console.log("success part");
                }else{
                    scraperStatusOpen();
                    handleClose();
                    console.log("error part");
                }

            }).catch((err)=>{
                console.log(err);
            });

        }catch(err){
            console.log(err);
        }
    }

    const doctorCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Total"}
                </Typography>
                <Typography variant="h5">
                    {doctorTotal}
                </Typography>
            </CardContent>
        </React.Fragment>
    )

    const campaignCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Admin"}
                </Typography>
                <Typography variant="h5">
                    {adminsTotal}
                </Typography>
            </CardContent>
        </React.Fragment>
    )

    const keywordCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Total"}
                </Typography>
                <Typography variant="h5">
                    {keywordTotal}
                </Typography>
            </CardContent>
        </React.Fragment>
    )

    const deliveredCampaignCard = (
         <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Users"}
                </Typography>
                <Typography variant="h5">
                    {usersTotal}
                </Typography>
            </CardContent>
         </React.Fragment>
    );

    const appointmentTotalCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Appointments"}
                </Typography>
                <Typography variant="h5">
                    {appointmentTotal}
                </Typography>
            </CardContent>
        </React.Fragment>
    )

    const referralsAppointment = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Comments"}
                </Typography>
                <Typography variant="h5">
                    {comments.length}
                </Typography>
            </CardContent>
        </React.Fragment>
    );

    const logsCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Notifications"}
                </Typography>
                <Typography variant="h5">
                    {0}
                </Typography>
            </CardContent>
        </React.Fragment>
    );

    const notificationsCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Notifications"}
                </Typography>
                <Typography variant="h5">
                    {notifications.length}
                </Typography>
            </CardContent>
        </React.Fragment>
    );
      
    const card = (
        <React.Fragment>
          <CardContent>
            <Typography variant="h4" component="div">
              {"Published"}
            </Typography>
            <Typography variant="h5">
                {postTotal}
            </Typography>
          </CardContent>
        </React.Fragment>
    );

    const latestPostCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Latest news"}
                </Typography>
                <Typography variant="h5">
                    {latestNewsTotal}
                </Typography>
            </CardContent>
        </React.Fragment>
    )

    const coursesCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                {"Courses"}
                </Typography>
                <Typography variant="h5">
                    {courseTotal}
                </Typography>
            </CardContent>
        </React.Fragment>
    )

    const contactsCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Contacts"}
                </Typography>
                <Typography variant="h5">
                    {contacts.length}
                </Typography>
            </CardContent>
        </React.Fragment>
    );

    const newsletterCard = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h4" component="div">
                    {"Newsletter"}
                </Typography>
                <Typography variant="h5">
                    {newsletter.length}
                </Typography>
            </CardContent>
        </React.Fragment>
    )
    
    const getDepartment = (event) => {
        event.preventDefault();

        var department1 = event.target.value;
        
        db.getDepartments().then((department)=>{
            if(department1 === ""){
                alert("Please choose a department!");
            }else{
                db.getDepartments().then((department)=>{
                    setDepartment1(department.department);
                });
            }
        });

        /*if(department1 === ""){
            alert("Please choose a department!");
        }else{
            setDepartment1(department1);
        }*/
    }

    const getSite = (event) => {
        event.preventDefault();

        var site = event.target.value;

        if(site === ""){
            alert("Please choose a link");
        }else{
            setSelectSite(event.target.value);
        }
    }

    const getPostAction = (event) => {
        setPostActionType(event.target.value);
    }

    const getPost = (event) => {
        event.preventDefault();
        const baseURL = "http://localhost:5000/view/create-post";


        const objPost = {
            "department": department1,
            "link": selectSite
        }

        if(postActionType == 'scrape_posts'){
            try{
                fetch(baseURL,{
                    headers:{
                        "Content-Type":"application/json"
                    },
                    method: "POST",
                    body: JSON.stringify(objPost)
                }).then((response)=>{
                    //console.log(response);
                    
                    if(response.status === 400){
                        setErrorStatusOpen(true);
                        console.log("http error 400");
                    }
                    if(response.status === 417){
                        setScraperStatusOpen(true);
                        console.log("http error 417");
                    }
                    if(response.status === 200){
                        setScraperSnackOpen(true);
                    }

                }).catch((err)=>{
                    console.log(err);
                    setScraperStatusOpen(true);
                });
            }catch(err){
                console.log(err);
            }
        }else if(postActionType == 'update_sitemap'){
            
             fetch('http://localhost:5000/sitemap/save-post-sitemap')
             .then((data)=>{
                if(data.status === 201){
                    alert(data.status);
                }
             })
             .catch((err)=>{
                console.log(err.message);
             });
        }
          
    }



    useEffect(()=>{

        const db = new DB('family_hero');   

        db.getCommentsAndReplies().then((comments)=>{
            setComments(comments);
        });
        db.getSpecialists().then((specialist)=>{
            setSpecialist(specialist);
            setDoctorTotal(specialist.length);
        });
        db.getSpecialists().then((specialist)=>{
            setSpecialists(specialist);
        });
        db.getAllPosts().then((posts)=>{
            setPosts(posts);
            setPostTotal(posts.length)
        });
        db.getPostsKeywords().then((keywords)=>{
            setTagPost(keywords);
        });
        db.getDepartments().then((department)=>{
            setDepartment(department);
        });
        db.getSites().then((site)=>{
            setSites(site); 
        });
        db.getAppointments().then((appointment)=>{
            setAppointments(appointment);
            setAppointmentTotal(appointment.length);
        });
        db.getPostsKeywords().then((keywords)=>{
            setKeywordTotal(keywords.length);
            setKeywordX(keywords);
        });
        db.getContacts().then((contact)=>{
            setContacts(contact);
        });
        db.getNewsletter().then((newsletter)=>{
            setNewsletter(newsletter);
        });
        db.getLatestPosts().then((latestPosts)=>{
            setLatestNews(latestPosts);
            setLatestNewsTotal(latestPosts.length);
        });
        db.getNotifications().then((notifications)=>{
            setNotifications(notifications);
        });
        db.getUsers().then((users)=>{
            setUsers(users);
        });
        db.getUsersPerRole().then((totalUsers)=>{
            setUsersTotal(totalUsers.length);
        });
        db.getAdminPerRole().then((totalAdmins)=>{
            setAdminsTotal(totalAdmins.length)
        });
        db.getCourseCategory().then((category)=>{
            setLmsCategories(category);
        })


    },[]);

    const notificationColumns = [
        { field: 'id', headerName:'Id', width: 70},
        { field: 'title', headerName:'Notification', width: 130},
        { field: 'image', headerName:'Image',width: 130},
        { field: 'about', headerName:'About', width: 130},
        { field: 'user', headerName:'User', width: 130},
        { field: 'date', headerName:'Date',width: 130},
        { field: 'slug', headerName:'Slug',width: 130},
        { field: 'action',headerName:'Action',width:130,disableClickEventBubbling: true,
            renderCell: (params)=>{
                <IconButton
                    onClick={(event)=>{
                        event.ignore = true;
                        setNotificationId(params.row.id);
                        setNotificationTitle(params.row.title);
                        setNotificationImage(params.row.image);
                        setNotificationAbout(params.row.about);
                        setNotificationUser(params.row.user);
                        setNotificationDate(params.row.date);
                        setNotificationSlug(params.row.slug);
                    }}
                    >
                    <EditIcon/>
                </IconButton>
            }
        }
    ]

    const newsletterColumns = [
        { field: 'id', headerName: 'ID', width: 70},
        { field: 'email', headerName: 'Email', width: 130},
        { field: 'action', headerName: 'Action', width: 130, disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton
                    onClick={(event)=>{
                        event.ignore = true;
                        setMembershipId(params.row.id);
                        setMembershipEmail(params.row.email);
                        setMembershipDialog(true);
                    }}
                >
                    <EditIcon/>
                </IconButton>
            )
        }
    ]

    const normalizedColumns = [
        { field: 'id', headerName: 'Id', width: 70},
        { field: 'bio', headerName: 'Biography', width: 130},
        { field: 'certificates',headerName: 'Certificates',width:130},
        { field: 'department',headerName: 'Department', width:130},
        { field: 'learning_score',headerName: 'Learning score',width:100},
        { field: 'name', headerName: 'Name',width:100},
        { field: 'specialists', headerName: 'Specialists',width:130},
        { field: 'bed',headerName:'Bed',width:70},
        { field: 'image',headerName:'Image',width:70},
        { field: 'action', headerName: 'Action', width:70, disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton
                    onClick={(event)=>{
                        event.ignore = true;
                        var id = params.row.id;
                        var bio = params.row.bio;
                        var cert = params.row.certificates;
                        var department = params.row.department;
                        var learn_score = params.row.learning_score;
                        var name = params.row.name;
                        var spec = params.row.specialists;
                        var bed = params.row.bed;
                        var image = params.row.image;
                        
                        //console.log(bed);

                        setdID(id);
                        setdBio(bio);
                        setdCert(cert);
                        setdDepartment(department);
                        setDLearnScore(learn_score);
                        setDname(name);
                        setDspec(spec);
                        setdPic(bed);
                        setdImage(image);

                        setOpenNormalized(true);
                    }}
                >
                    <EditIcon/>
                </IconButton>
            )
        }
    ]

    
    const doctorColumns = [
        { field: 'id', headerName: 'ID', width: 70},
        { field: 'name',headerName: 'Name', width: 100},
        { field: 'email',headerName: 'Email',width:100},
        { field: 'department',headerName: 'Department',width:130},
        { field: 'latitude', headerName: 'Latitude', width:130},
        { field: 'longitude', headerName: 'Longitude', width:130},
        { field: 'learning_score', headerName: 'Learning score', width:100},
        { field: 'bed', headerName: 'Bed',width: 80},
        { field: 'image', headerName: 'Image', width:83},
        { field: 'action', headerName: 'Action', width: 90, disableClickEventBubbling: true,
            renderCell: (params) => (
                    <IconButton
                        onClick={(event) => {
                            event.ignore = true;
                            var searchInput = params.row.email;

                            var id = params.row.id;
                            var name = params.row.name;
                            var email = params.row.email;
                            var department = params.row.department;
                            var latitude = params.row.latitude;
                            var longitude = params.row.longitude;
                            var learningScore = params.row.learning_score;
                            var image = params.row.image;
                            var bed = params.row.bed;
                            var number = params.row.number;
                            var desc = params.row.description;
                            //console.log(typeof learningScore.toString());

                            setIdY(id);
                            setNameY(name);
                            setEmailY(email);
                            setDepartmentY(department);
                            setLatitudeY(latitude);
                            setLongitudeY(longitude);
                            setLearningScoreY(learningScore);
                            setImageY(image);
                            setBedY(bed);
                            setTelY(number);
                            setDescriptionY(desc);

                            console.log(typeof learningScoreY);

                            const filteredData = specialist.filter((item)=>{
                                return Object.values(item).join('').toLowerCase().includes(searchInput.toString());
                            });
                            setFilteredSpecs(filteredData)
                            setOpen(true);           
                    }}
                    >
                <EditIcon />
                </IconButton>
            )}
    ]

    const coursesColumns = [
        { field: 'id', headerName: 'ID', width: 70},
        { field: 'title', headerName: 'Title', width: 130},
        { field: 'course', headerName: 'Course', width: 130},
        { field: 'instructor', headerName: 'Instructor', width: 130},
        { field: 'duration', headerName: 'Duration',width: 130},
        { field: 'students', headerName: 'Student', width: 100},
        { field: 'action', headerName: 'Action', width: 130, disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton
                    onClick={(event)=>{
                        event.ignore = true;

                        var id = params.row.id;
                        var title = params.row.title;
                        var course = params.row.course;
                        var instructor = params.row.instructor;
                        var duration = params.row.duration;
                        var student = params.row.students;

                        setLogId(id);
                        setLogTitle(title);
                        setLogCourse(course);
                        setLogInstructor(instructor);
                        setLogDuration(duration);
                        setLogStudent(student);

                        setOpenXYX(true);
                        console.log("delete id: " + params.row.id);

                    }}
                >
                    <EditIcon/>
                </IconButton>
        
        )}
    ]


    const postColumns = [
        { field: '_id', headerName: 'ID', width: 70},
        { field: 'sidebarHeight', headerName: 'Sidebar height', width: 130},
        { field: 'blogImage', headerName: 'Blog image', width: 130},
        { field: 'blogTitle', headerName: 'Blog title', width: 130},
        { field: 'blogBody1', headerName:'Blog body', width: 130},
        { field: 'seo_score', headerName: 'SEO score', width:90},
        { field: 'author', headerName: 'Author', width: 90},
        { field: 'category', headerName:'Category', width: 100},
        { field: 'action', headerName: 'Action', width: 130, disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton
                    onClick={(event) => {
                        event.ignore = true;

                        var id = params.row.id;     
                        var searchInput = params.row.slug;  
                        var author = params.row.author;
                        var sidebarHeight = params.row.sidebarHeight;
                        //var usrImg = params.row.userImage;
                        var blogImg = params.row.blogImage;
                        var blogTitle = params.row.blogTitle;
                        var blogCategory = params.row.category;
                        var blogBody1 = params.row.blogBody1;
                        var blogBody2 = params.row.blogBody2;
                        var blogBody3 = params.row.blogBody3;
                        var seo_score = params.row.seo_score;

                        console.log(params.row);

                        setIdx(id);
                        //setUserImage(blogImg);
                        setSidebarHeightX(sidebarHeight);
                        setSlug(searchInput);
                        setAuthor(author);
                        //setUserImage(usrImg);
                        setBlogImage(blogImg);
                        setBlogTitle(blogTitle);
                        setBlogCategory(blogCategory);
                        setBlogBody1(blogBody1);
                        setBlogBody2(blogBody2);
                        setBlogBody3(blogBody3);
                        setSeoScore(seo_score);

                        const filteredData = posts.filter((item)=>{
                            return Object.values(item).join('').toLowerCase().includes(searchInput.toString().toLowerCase());
                        });
                        setFilteredResults(filteredData);
                        setOpen(true);
                }}
                >
                <EditIcon />
            </IconButton>
        )}
    ]

    const handleCloseAppointment = (event) => {
        event.preventDefault();
        setOpenAppointment(false);
    }

    const handleCloseComment = (event) => {
        event.preventDefault();
        setOpenComment(false);
    }

    const handleSaveComment = (event) => {
        event.preventDefault();

        console.log(userC);

        const baseURL = "http://localhost:5000/view/update-comment";

        const objComment = {
            "id":idC,
            "user":userC,
            "date":dateC,
            "comment":commentC,
            "image":imageC,
            "appointmentId":appointmentC,
            "type":typeC
        }


        try{
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify(objComment)
            }).then((response) => {
                if(response.status === 201){
                    setOpenEditSnackbar(true);
                    setOpenComment(false);
                }
            }).catch((err)=>{
                console.log(err.message)
            })
        }catch(error){
            console.log(error);
        }
    }

    const generateBioX = (event) => {
        event.preventDefault();

        const baseURL = "http://localhost:5000/details/view/generate-o-bio";

        const objData = {
            id: idY,
            name: nameY,
            email: emailY,
            department: departmentY,
            description: docDesc,
            image:imageY,
            latitude: latitudeY,
            longitude: longitudeY,
            learningScore: learningScoreY,
            bed:bedY,
            telephone:telY
        }

        try{
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify(objData)
            }).then((response) => {
                if(response.status === 201){
                    setOpenBio(true);
                    handleClose();
                }
                //console.log(response)
            }).catch((error)=>{
                console.log(error);
            });
        }catch(error){
            console.log(error);
        }
       // console.log(objData);
    }

    const subjectFun = (event) => {
        event.preventDefault();
        setSubject(event.target.value);
    }

    const nameFun = (event) => {
        event.preventDefault();
        setContactName(event.target.value);
    }

    const emailFun = (event) => {
        event.preventDefault();
        setContactEmail(event.target.value);
    }

    const updateContactInfo = (event) => {
        event.preventDefault();
        
        var id = document.getElementById("contactusId").value;
        const baseURL = "http://localhost:5000/contact/update-contact";


        const objContactInfo = {
            "id":id,
            "subject":contactSubject,
            "name":contactName,
            "email":contactEmail,
            "message":contactMessage
        }

       try{
            
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify(objContactInfo)
            }).then((response)=>{
                if(response.status === 201){
                    handleContactEditClose();
                    setContactInfoMessage("Contact information updated!");
                    setContactInfoSnackbar(true)
                }
            }).catch((error)=>{
                console.log(error);
            });

       }catch(error){
            console.log(error.message);
       } 

       console.log(objContactInfo);
    }

    const onInputChange = (event) => {
        event.preventDefault();

        const baseURL = "http://localhost:5000/upload/upload-csv-file";


    }

    const generateBio = (event) => {
        event.preventDefault();
        
        const baseURL = "http://localhost:5000/details/view/generate-bio";

        const objData = {
            department:dDepartment,
            picture:dPic,
            specialists:dSpec,
            id:dId,
            name:dName,
            certificates:dCert,
            learning_score:dLearnScore,
            image:dImage
        }

        console.log(objData);

        try{
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method:"POST",
                body: JSON.stringify(objData)
            }).then((response)=>{
                if(response.status === 201){
                    setOpenBio(true);
                    setOpenNormalized(false);
                }
                //console.log(response);
            }).catch((error)=>{
                console.log(error);
            });

        }catch(error){
            console.log(error)
        }

    }

    const usersColumns = [
        { field: 'id', headerName: 'Id', width: 70},
        { field: 'name', headerName: 'Name', width: 150},
        { field: 'email', headerName: 'Email', width: 150},
        { field: 'profession', headerName: 'Profession',width:150},
        { field: 'password', headerName: 'Password',width:150},
        { field: 'role', headerName: 'Role',width:150},
        { field: 'action', headerName: 'Action',width: 130, disableClickEventBubbling: true,
        renderCell: (params) => (
                <IconButton
                    onClick={(event)=>{

                        event.ignore = true;
                    
                    }}
                >
                    <EditIcon/>
                </IconButton>
            )
        }
    ]

    const keywordsColumns = [
        { field: 'id', headerName: 'Id', width: 70},
        { field: 'keyword', headerName: 'Keyword', width: 130},
        { field: 'low_range', headerName: 'Low range', width: 130},
        { field: 'high_range', headerName: 'High range', width: 130},
        { field: 'slug', headerName: 'Slug',width:100},
        { field: 'action', headerName: 'Action', width: 130, disableClickEventBubbling: true,
        renderCell: (params) => (
                <IconButton
                    onClick={(event) => {

                        event.ignore = true;      

                        var id = params.row.id;
                        var keyword = params.row.keyword;
                        var low_range = params.row.low_range;
                        var high_range = params.row.high_range;
                        var slug = params.row.slug;

                        setXid(id);
                        setDKeyword(keyword);
                        setDLowRange(low_range);
                        setDHighRange(high_range);
                        setDSlug(slug);

                        setOpenKeyword(true);
                }}
                >
              <EditIcon />
            </IconButton>
          )}
    ]
    
    // contacts table
    const contactsColumns = [
        { field: 'id', headerName: 'ID', width: 70},
        { field: 'subject', headerName: 'Subject', width: 130},
        { field: 'name', headerName: 'Name', width: 130},
        { field: 'message', headerName: 'Message', width: 130},
        { field: 'email', headerName: 'Email', width: 130},
        { field: 'action', headerName: 'Action', width: 130, disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton
                    onClick={(event)=>{
                        event.ignore = true;
                        console.log("delete id: " + params.row.id);
                        setContactId(params.row.id);
                        setContactSubject(params.row.subject);
                        setContactName(params.row.name);
                        setContactMessage(params.row.message);
                        setContactEmail(params.row.email);

                        setOpenContactsEdit(true);

                    }}
                >
                    <EditIcon/>
                </IconButton>
            )
        }
    ];

    // for doctors table
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'streetAddress', headerName: 'Street Address', width: 130 },
        { field: 'practiseNo', headerName: 'Practise No', width: 130 },
        { field: 'town', headerName: 'Town', width: 130 },
        { field: 'email', headerName: 'Email', width: 130 },
        { field: 'action', headerName: 'Action', width: 130, disableClickEventBubbling: true,
        renderCell: (params) => (
                <IconButton
                    onClick={(event) => {
                        event.ignore = true;               
                        console.log("delete id: " + params.row.id);
                        //setId(params.row.id);
                        //openingDialog()
                }}
                >
            <DeleteIcon />
            </IconButton>
        )}
    ];

    const columnsAppointment = [
        { field: 'id', headerName: 'Id', width: 70},
        { field: 'date',headerName: 'Date', width: 130},
        { field: 'specialist', headerName: 'Specialists', width: 130},
        { field: 'name', headerName: 'Name', width: 130},
        { field: 'time', headerName: 'Time', width: 130},
        { field: 'department', headerName: 'Department', width: 130},
        { field: 'message', headerName: 'Message', width: 130},
        { field: 'email', headerName: 'Email', width: 130},
        { field: 'action', headerName: 'Action', width: 70, disableClickEventBubbling: true,
        renderCell: (params)=>(
            <IconButton
                onClick={(event)=>{
                    event.ignore = true;
                    console.log("row id: " + params.row.id);

                    var id = params.row.id;
                    var specialist = params.row.specialist;
                    var date = params.row.date;
                    var name = params.row.name;
                    var time = params.row.time;
                    var department = params.row.department;
                    var message = params.row.message;
                    var email = params.row.email;

                    setIdYX(id);
                    setDateXY(date);
                    setSpecialistXY(specialist);
                    setNameXY(name);
                    setTimeXY(time);
                    setDepartmentXY(department);
                    setMessageXY(message);
                    setEmailXY(email);

                    setOpenAppointment(true);
                    
                }}
            >    
                <EditIcon/>
            </IconButton>
        )}
    ]

    const latestPostColumns = [
        { field: 'id', headerName: 'ID', width: 70},
        { field: 'sidebarHeight', headerName: 'Sidebar Height', width: 130},
        { field: 'blogImage', headerName: 'Blog image', width: 130},
        { field: 'blogTitle', headerName: 'Blog title', width: 130},
        { field: 'blogBody1', headerName:'Blog body', width: 130},
        { field: 'seo_score', headerName: 'SEO score', width:90},
        { field: 'author', headerName: 'Author', width: 90},
        { field: 'category', headerName:'Category', width: 100},
        { field: 'action', headerName: 'Action', width: 130, disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton
                    onClick={(event) => {
                        event.ignore = true;

                        setLatestNewsId(params.row.id);
                        setLatestNewsHeight(params.row.sidebarHeight);
                        setLatestNewsImage(params.row.blogImage);
                        setLatestNewsTitle(params.row.blogTitle);
                        setLatestNewsBody1(params.row.blogBody1);
                        setLatestNewsBody2(params.row.blogBody2);
                        setLatestNewsBody3(params.row.blogBody3);
                        setLatestNewsSeoscore(params.row.seo_score);
                        setLatestNewsAuthor(params.row.author);
                        setLatestNewsCategory(params.row.category);
                        setLatestNewsSlug(params.row.slug);

                        setOpenLatestNewsDialog(true);
                }}
                >
                <EditIcon />
            </IconButton>
        )}
    ]

    const columnsAppointmentComments = [
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'user', headerName: 'User', width: 130 },
        { field: 'date', headerName: 'Date', width: 130 },
        { field: 'comment', headerName: 'Comment', width: 130 },
        { field: 'image', headerName: 'Image', width: 130},
        { field: 'appointmentId', headerName: 'Appointment', width: 130},
        { field: 'action', headerName: 'Action', width: 130, disableClickEventBubbling: true,
            renderCell: (params)=>(
                <IconButton onClick={(event)=>{
                    event.ignore = true;

                    var id = params.row.id;
                    var user = params.row.user;
                    var date = params.row.date;
                    var comment = params.row.comment;
                    var image = params.row.image;
                    var appointment = params.row.appointmentId;
                    var type = params.row.type;
                    
    
                    setIdC(id);
                    setUserC(user);
                    setDateC(date);
                    setCommentC(comment);
                    setImageC(image);
                    setAppointmentC(appointment);
                    setTypeC(type);

                    setOpenComment(true);
                }}>
                    <EditIcon/>
                </IconButton>
            )
        }
    ]

    const columnsEmails = [
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'message', headerName: 'Message', width: 130 },
        { field: 'request_method', headerName: 'Request method', width: 130 },
        {
          field: 'request_url',
          headerName: 'Request url',
          width: 130,
        },
        {
            field: 'remote_address',
            headerName: 'Remote address',
            width: 130,
        },
        {
            field: 'header_xff',
            headerName: 'Header XFF',
            width: 130
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 130,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <IconButton onClick={(event) => {
                    event.ignore = true;

                    
                }}>
                    <EditIcon/>
                </IconButton>
            )
        }
      ];
      
      const rowsEmails = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
        { id: 10,lastName: 'Moxie', firstName: 'Digital', age: 43}
    ];
    
 
    
    const chooseDoctor = (event) => {
        event.preventDefault();
        console.log('choose doctor');
    }

    const handleClickOpen = () => {
        setOpen(true);
    }
    
    const handleArticlesOpen = () => {
        setArticlesOpen(true)
    }

    const handleEmails = (event) => {
        setTimer(event.target.value);
        console.log(timer);
    }

    const handleCampaign = (event) => {
        setCampaign(event.target.value);
        console.log(campaign);
    }

    const handleKeyword = (event) => {
        setKeyword(event.target.value);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleContactEditClose = () => {
        setOpenContactsEdit(false);
    }

    const handleCreateCampaignClose = () => {
        setCreateCampaingOpen(false);
    }

    const handleCloseNormalized = () => {
        setOpenNormalized(false)
    }

    const handleCloseAdvertising = (event) => {
        event.preventDefault();
        setOpenAdvertiseDialog(false);
    }

    const handleCloseKeyword = () => {
        setOpenKeyword(false);
    }

    const handleArticleClose = () => {
        setArticlesOpen(false);
    }
  
    const handleCloseOfEditingArticles = () => {
        setOpen(false);
    }

    const handleSaveArticle = () => {
        setArticlesOpen(false);
    }

    React.useEffect(() => {
        fetch("http://localhost:5000/details/google/keywords")
            .then((res)=>res.json())
            .then((data)=>{
                setGoogleKeywords(data);
            }).catch((err)=>{
                db.getPostsKeywords().then((keywords)=>{
                    setGoogleKeywords(keywords);
                });
            });
    });

    React.useEffect(()=>{
        fetch('http://localhost:5000/view/bio')
            .then((res) => res.json())
            .then((data)=> {
                setNormalized(data);
            })
            .catch((err)=>{
                db.getBiographies().then((biographies)=>{
                    setNormalized(biographies);
                });
            });
    });

    React.useEffect(()=>{
        fetch('http://localhost:5000/view/view-courses')
            .then((res)=> res.json())
            .then((data)=>{
                setCourses(data);
                setCoursesTotal(data.length);
            })
            .catch((err)=>{
                db.getCourses().then((course)=>{
                    setCourses(course);
                    setCoursesTotal(course.length);
                });
            })
    },[]);



    const saveXy = (event) => {
        event.preventDefault();

        const baseURL = "http://localhost:5000/view/update-normalized-docs";

        const objNormalizedDocs = {
            id: dId,
            bio: dBio,
            certificates: dCert,
            department: dDepartment,
            learning_score: dLearnScore,
            name: dName,
            specialists:dSpec,
            image:dImage,
            picture:dPic
        }

        try{
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json",
                },
                method:"POST",
                body: JSON.stringify(objNormalizedDocs)
            }).then((response)=>{
                if(response.status === 201){
                    setDocEdit(true);
                    setOpenNormalized(false);
                }
            }).catch((error)=>{
                console.log(error)
            })

        }catch(error){
            console.log(error)
        }
    }

    const handleSaveEmails = () => {
        setOpen(false);

        const baseURL = "http://localhost:5000/hero/create";
        //setInterval(time, 1000);
    }

    const handleKeywordChange = (event) => {
        setDKeyword(event.target.value);
    }

    const deletePost = (event) => {
        event.preventDefault();

    }

    const handleLowRange = (event) => {
        setDLowRange(event.target.value);
    }

    const handleHighRange = (event) => {
        setDHighRange(event.target.value);
    }


    const handleSlugChangeKeyword = (event) => {
        setDSlug(event.target.value);
        alert(dSlug);
    }

    const saveWords = (event) => {
        event.preventDefault();

        const baseURL = "http://localhost:5000/view/update-keyword";

        const objWords = {
            id: xId,
            keyword: dKeyword,
            lowRange: dLowRange,
            highRange: dHighRange,
            slug: dSlug
        }

        console.log(objWords);

        try{
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify(objWords)
            }).then((response)=>{
                console.log(response);
            }).catch((error)=>{ 
                console.log(error);
            })
        }catch(error){
            console.log(error);
        }

        //console.log(objWords);


    }

    const handleSave = () => {
        setOpen(false);

        const baseURL = "http://localhost:5000/keywords/create";
        
        const objKeywords = {
            keyword: keyword,
            link: website,
            slug: slug
        }

        try{

            fetch(baseURL,{

                headers:{
                    "Content-Type":"application/json",
                },
                method: "POST",
                body: JSON.stringify(objKeywords)

            }).then((response)=>{
                console.log(response);
                alert("Response status: " + response.status );
            });

        }catch(error){
            console.log(error);
        }

    }

    const emailCampaign = () => {

        const baseURL = "http://localhost:5000/mailchimp/families-hereos-message";
        const reference = "";
        
        try{
            
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify({ reference:reference })
            }).then((response)=>{
                console.log(response)
            });

        }catch(error){
            console.log(error);
        }
        ///console.log('email campaign...');
    }
    const scrapeArticles = () => {

        if(pages == ""){
            console.log('please set page number')
        }else{
            const baseURL = `https://shielded-peak-42156.herokuapp.com/who/extract-news/${pages}`;

            axios.get(baseURL).then((response)=>{
                //console.log(response.data);
                //setArticles(response.data);
                //console.log(articles)
            });
        }       
    }

    const saveCampaign = (event) => {
        event.preventDefault();

        const baseURL = "http://localhost:5000/view/create-campaign";

        const objCampaign = {
            "subject":subject,
            "message":message
        }

        try{

            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method:"POST",
                body:JSON.stringify(objCampaign)
            }).then((response)=>{
                if(response.status === 201){
                    setCreateCampaingOpen(false);
                    setOpenCampaignSnackbar(true);
                }
            }).catch((err)=>{
                console.log(err);
            });

        }catch(error){
            console.log(error);
        }

    }

    const interestedEvent = () => {
        window.alert("interested event")
    }

    const getCampaign = (event) => {
        event.preventDefault();
        setCampaign(event.target.value);
        console.log(campaign);
    }

    const moveCampaign = (event) => {
        event.preventDefault();
        if(appointmentActionType == 'refferral'){
            alert("move campaign...");
        }else if(appointmentActionType == 'update_sitemap'){
            fetch('http://localhost:5000/sitemap/save-appointment-sitemap')
            .then((data)=>{
                if(data.status === 201){
                    alert(data.status);
                }
            })
            .catch((err)=>{
                console.log(err.message);
            });
        }
    }

    const getKeywords = (event) => {
        var keywordY = event.target.value;
        setKeywordResearch(keywordY);
    }

    const keywordSearch = (event) => {
        event.preventDefault();
        alert(keywordResearch);
    }

    const moveDoctor = (event) => {
        event.preventDefault();
        console.log("move a doctor!");
    }

    const loadEmails = () => {

        //setSnackBarOpen(true);
        handleClickOpen();
       
        // setting intervals
        //setTimer(timer);
        //setCampaign(campaign);
        
        var intervals = 5;

    }


    const linkStyle = {
        textDecoration: 'none',
        color: theme.palette.text.primary
    }

    const containerStyle = {
        height: '125vh',
    }

    const getHospital = (event) => {
        event.preventDefault();
        setHospital1(event.target.value)
    }

    const getAction = (event) => {
        event.preventDefault();
        setAction(event.target.value)
    }

    const generateKeywords = (event) => {
        event.preventDefault();
        console.log('generate keywords');
    }

    const handleSpecialistXY = (event) => {
        setSpecialistXY(event.target.value);
    }

    const handleNameXY = (event) => {
        setNameXY(event.target.value);
    }

    const handleNewsAuthorChange = (event) =>{
        setLatestNewsAuthor(event.target.value);
    }

    const handleNewsSlugChange = (event) => {
        setLatestNewsSlug(event.target.value);
    }

    const handleLatestNewsHeightChange = (event) => {
        setLatestNewsHeight(event.target.value);
    }

    const handleLatestNewsImage = (event) => {
        setLatestNewsImage(event.target.value);
    } 
    
    const handleLatestNewsTitle = (event) => {
        setLatestNewsTitle(event.target.value);
    }

    const handleLatestNewsCategory = (event) => {
        setLatestNewsCategory(event.target.value);
    }

    const handleLatestNewsSeoscore = (event) => {
        setLatestNewsSeoscore(event.target.value);
    }

    const handleTimeXY = (event) => {
        setTimeXY(event.target.value);
    }

    const handleDateXY = (event) => {
        setDateXY(event.target.value);
    }

    const handleDepartmentXY = (event) => {
        setDepartmentXY(event.target.value);
    }

    const handleMessageXY = (event) => {
        setMessageXY(event.target.value);
    }

    const handleEmailXY = (event) => {
        setEmailXY(event.target.value);
    }

    const handleIdC = (event) => {
        setIdC(event.target.value);
    }

    const handleDateC = (event) => {
        setDateC(event.target.value);
    }

    const handleImageC = (event) => {
        setImageC(event.target.value);
    }

    const handleTypeC = (event) => {
        setTypeC(event.target.value);
    }

    const handleUserC = (event) => {
        setUserC(event.target.value);
    }

    const editAppointment = (event) => {

        event.preventDefault();

        const baseURL = "http://localhost:5000/view/update-appointment"

        const objData = {
            id: idYX,
            specialist: specialistXY,
            name: nameXY,
            time: timeXY,
            date: dateXY,
            department: departmentXY,
            message: messageXY,
            email: emailXY
        }

        try{
            
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body:JSON.stringify(objData)
            }).then((response)=>{
                console.log(response.status);
                if(response.status === 201){
                    setOpenXY(true);
                    handleCloseAppointment(false);
                }
            }).catch((err)=>{
                console.log(err)
            });

        }catch(error){
            console.log(error);
        }

    }

    const handleStorageAction = (event) => {
        event.preventDefault();
        setStorage(event.target.value);
    }

    const commentsAction = (event) => {
        event.preventDefault();
        setCommentAppointmentAction(event.target.value);
    }

    const appointmentRelationship = (event) => {
        event.preventDefault();
        setAppointmentRelationshipAction(event.target.value);
    }

    const submitComments = (event) => {
        event.preventDefault();
        if(commentAppointmentAction == ''){
            setCommentErrorSnackbarOpen(true);
            setCommentErrorMessage("No appointment action");
        }else if(appointmentRelationshipAction == ''){
            setCommentErrorSnackbarOpen(true);
            setCommentErrorMessage("No action type");
        }else if(commentAppointmentAction == 'no_action'){
            setCommentErrorSnackbarOpen(true);
            setCommentErrorMessage("No appointment action");
        }else if(appointmentRelationshipAction == 'no_action'){
            setCommentErrorSnackbarOpen(true);
            setCommentErrorMessage("No action type");
        }
    }

    const viewPost = (event) => {
         event.preventDefault();        
         const postURL = "http://localhost:3000/learning/"+blogCategory + "/" + slug;
         window.open(postURL, '_blank');
    }

    const saveDepartment = (event) => {
        event.preventDefault();
        alert("AdvertseAd: " + advertiseAd + " advertiseDepartment: " + advertiseDepartment);
    }

    const handleAdAdvertising = (event) => {
        event.preventDefault();
        var department = event.target.value;

        if(department == ""){
            alert("department not found");
        }
        else if(department == "no_department"){
            alert("department not found");
        }
        else{
            setAdvertisingDepartment(department);
        }
    }

    const handleAdvertising = (event) => {

        var ad = event.target.value;
        if(ad == ""){
            alert("no advertising")
        }else if(ad != ""){
            setAdvertisingAd(ad);
        }

    }

    const saveAdvertising = (event) => {
        event.preventDefault();
        
        try{
            alert("advertising: " + advertiseAd + " department: " + advertiseDepartment);
        }catch(error){
            console.log(error)
        }
    }

    const updateNewsletter = (event) => {
         event.preventDefault();

         const baseURL = "http://localhost:5000/contact/update-membership";
         
         var id = document.getElementById("membershipId").value;

         const objMember = {
             "id":id,
             "email":membershipEmail
         }

         try{
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body:JSON.stringify(objMember)
            }).then((response)=>{
                if(response.status == 201){
                    handleMembershipClose();
                    setMembershipMessage("Membership has been updated!");
                    setMembershipSnackbar(true);
                }
            }).catch((err)=>{
                console.log(err)
            });
         }catch(error){
            console.log(error);
         }



    }

    const check = (event) => {
        event.preventDefault();
        
        const baseURL = "http://localhost:5000/view/meta-checker";
        const postURL = "http://localhost:3000/learning/"+blogCategory + "/" + slug;

        const objChecker = {
            "link":postURL
        }

        try{

            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method: "POST",
                body: JSON.stringify(objChecker)
            }).then((response)=>{
                console.log(response);
            }).catch((error)=>{
                console.log(error);
            })

        }catch(error){
            console.log(error)
        }
        
        //console.warn('check...');
    }

    const createAdvertising = (event) =>{
        event.preventDefault();
        setOpenAdvertiseDialog(true)
    }   

    const getDoctor = (event) => {
        event.preventDefault();

        if(action == 'get_data'){

            const objData = {
                "hospital":hospital1,
                "department":department1,
                "action":action
            }
            
            try{
                
                fetch("http://localhost:5000/view/scrape/doctor",{
                    headers:{
                        "Content-Type":"application/json"
                    },
                    method:"POST",
                    body:JSON.stringify(objData)
                }).then((response)=>{

                    if(response.status === 201){
                        setOpenOriginalEdit(true);
                        handleClose();
                        //console.log(response)
                    }

                }).catch((err)=>{
                    console.log(err);
                });

            }catch(err){
                console.log(err)
            }
        }else if(action == 'update_sitemap'){

            fetch('http://localhost:5000/sitemap/save-doctor-sitemap')
            .then((data)=>{
                if(data.status === 201){
                    alert(data.status);
                }
            })
            .catch((err)=>{
                console.log(err.message);
            });

        }else if(action == 'generate_biography'){
            
            fetch('http://localhost:5000/view/automate-biography-generation')
            .then((data)=>{
                if(data.status === 302){
                    alert("Biography found.");
                }else{
                    alert("Biography not found.");
                }
                //console.log(data.status);
            })
            .catch((err)=>{
                console.log(err.message);
            });

        }
    }

    const labels = {
        1:'Useless',
        2:'Useless+',
        3:'Poor',
        4:'Poor+',
        5:'Ok',
        6:'Ok+',
        7:'Good',
        8:'Good+',
        9:'Excellent',
        10:'Excellent+'
    }

    function getLabelText(value){
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }
       
    return( 
            <div>

                    <Drawer
                        variant="permanent"
                        sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                        }}
                    >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }} >
                    <List 
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                              Dashboard
                            </ListSubheader>
                          }
                     >

                        {['Home', 'Post', 'Doctor'].map((text, index) => (
                         <React.Fragment key={index}>
                            <Link to={"/" + text.toLowerCase()} style={linkStyle}>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                        {
                                            (()=> {
                                                switch (text) {
                                                    case 'Home': return <HomeIcon />;
                                                    case 'Post': return <ArticleIcon />;
                                                    case 'Doctor': return <MedicationIcon />;
                                                   // case 'Patients': return <PeopleIcon />;
                                                }
                                            })()
                                        }
                                        </ListItemIcon>
                                        <ListItemText primary={text}/>
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                         </React.Fragment>
                        ))}
                    </List>
                    <Divider />
                    <List 
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                              Quick Menu
                            </ListSubheader>
                          }
                     >
                        {['Users', 'Keywords', 'Appointments'].map((text, index) => (
                        <React.Fragment key={index}>
                            <Link to={text.toLowerCase()} style={linkStyle}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                        {
                                            (()=> {
                                                switch (text) {
                                                    case 'Users': return <PeopleIcon />;
                                                    case 'Keywords': return <FormatShapesIcon />;
                                                    case 'Appointments': return <EventIcon />;
                                                }
                                            })()
                                        }
                                </ListItemIcon>
                                <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                            </Link>
                         </React.Fragment>
                        ))}
                    </List>
                    <Divider/>
                    <List 
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                             Marketing
                            </ListSubheader>
                          }
                     >
                         {['Contacts', 'Notifications', 'Learn','Campaign'].map((text, index) => (
                          <React.Fragment key={index}>
                            <Link to={text.toLocaleLowerCase()} style={linkStyle}>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                        {
                                            (()=> {
                                                switch (text) {
                                                    case 'Contacts': return <SmsIcon />;
                                                    case 'Notifications': return <EmailIcon />;
                                                    case 'Learn': return <PublicIcon />;
                                                    case 'Campaign': return <PublicIcon/>
                                                }
                                            })()
                                        }
                                        </ListItemIcon>
                                        <ListItemText primary={text}/>
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                            </React.Fragment>
                         ))}
                     </List>
                   </Box>
                </Drawer>
                <Container maxWidth="sm" fixed style={containerStyle}>                       
                  <Box component="main" sx={{ flexGrow: 5, p: 3 }} >

                  {
                     (()=> {
                            switch (pathname) {
                                    case '/home': 

                                        return (                            
                                            <Main open={open}>
                                                <DrawerHeader />
                                                <h3>Home</h3>
                                                <div style={{ height: 400,width: '158%', backgroundColo: 'white',marginTop: '3%'}}>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <label htmlFor="sitemap">{"Sitemaps:"}</label>
                                                                <select className="form-control" onChange={handleHomeAction}>
                                                                    <option name={"sitemap_action"} value={"choose_a_sitemap"}>{"Choose a sitemap"}</option>
                                                                    <option name={"sitemap_action"} value={"website_sitemap"}>{"Website sitemap"}</option>
                                                                    <option name={"sitemap_action"} value={"post_sitemap"}>{"Post sitemap"}</option>
                                                                    <option name={"sitemap_action"} value={"doctor_sitemap"}>{"Doctor sitemap"}</option>
                                                                    <option name={"sitemap_action"} value={"appointment_sitemap"}>{"Appointment sitemap"}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-1" style={{marginTop: '24px'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <button onClick={homeSave} className="btn btn-success" style={{marginLeft: '-58px'}}>{"Submit"}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<div className="col-sm-3">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <label htmlFor="storage">{"Storage:"}</label>
                                                                <select className="form-control" onChange={handleStorageAction}>
                                                                    <option name={"choose_storage"} value={"choose_storage"}>{"Choose storage"}</option>
                                                                    <option name={"pouch_db"} value={"store_data"}>{"Store data"}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '24px'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <button className="btn btn-success" style={{marginLeft: '-58px'}} onClick={storageSave}>{"Submit"}</button>
                                                                </div>
                                                            </div>
                                                        </div>*/}
                                                    </div>
                                                </div>
                                                <div style={contentWidth}>
                                                    
                                                    <DrawerHeader/>
                                                    <DrawerHeader/>                                                 
                                                </div>
                                                <Snackbar open={homeSnackbarOpen} autoHideDuration={6000} onClose={homeSnackbarClose}>
                                                    <Alert onClose={homeSnackbarClose} severity="success" sx={{width: '100%'}}>
                                                        {"Sitemap successfully generated and ready for the build!"}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={homeActionSnackbar} autoHideDuration={6000} onClose={homeActionSnackbarClose}>
                                                    <Alert onClose={homeActionSnackbarClose} severity="error" sx={{width: '100%'}}>
                                                        {"Please choose a sitemap option!"}
                                                    </Alert>
                                                </Snackbar>
                                            </Main>);

                                    case '/articles': 
                                        
                                       // if (!article) return "No articles!"

                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>Articles</h3>
                                                <Button variant="contained" style={{ marginLeft: '68%', marginBottom: '4%'}} onClick={handleArticlesOpen}>Load articles</Button>       

                                                <Box sx={{ flexGrow: 1 }}>
                                                
                                                <Grid container spacing={{ xs: 3, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ marginLeft: "170px"}}>
                                                <Dialog open={open} onClose={handleCloseOfEditingArticles}>
                                                            <DialogTitle>Article update</DialogTitle>
                                                            <DialogContent>
                                                            <DialogContentText>
                                                               Brush up on titles, slugs and format text content.
                                                            </DialogContentText>
                                                            <br/>
                                                            <div contentEditable="true">
                                                                
                                                            </div>
                                                            <CKEditor
                                                                editor={ ClassicEditor }
                                                                onReady={ editor => {
                                                                    // You can store the "editor" and use when it is needed.
                                                                    console.log( 'Editor is ready to use!', editor );
                                                                } }
                                                                onChange={ ( event, editor ) => {
                                                                    const data = editor.getData();
                                                                    setBody(data);
                                                                    console.log( { event, editor, data } );
                                                                } }
                                                                onBlur={ ( event, editor ) => {
                                                                    console.log( 'Blur.', editor );
                                                                } }
                                                                onFocus={ ( event, editor ) => {
                                                                    event.preventDefault();
                                                                    console.log( 'Focus.', editor );
                                                                } }
                                                            >
                                                            </CKEditor>
                                                            </DialogContent>
                                                            <DialogActions>
                                                            <Button>Cancel</Button>
                                                            <Button>Update</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                        <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={snackBarClose}>
                                                            <Alert onClose={snackBarClose} severity="success" sx={{ width: '100%' }}>
                                                                Article deleted!
                                                            </Alert>
                                                        </Snackbar>
                                                    {articles.map((article, index) => (
                                                    <Grid item xs={2} sm={4} md={4} key={index}>
                                                        <Card sx={{ maxWidth: 360 }} style={{ height: '28vw'}}>                                                       
                                                        <CardActionArea>
                                                            <CardMedia
                                                                component="img"
                                                                height="140"
                                                                image={article.image}
                                                                alt={article.title}
                                                            />
                                                            <CardContent style={{ padding: '22px'}}>
                                                            <Typography gutterBottom variant="p" component="div">
                                                                {article.title}
                                                            </Typography>
                                                            {/*<Typography variant="body2" color="text.secondary">
                                                                {article.body}
                                                            </Typography>*/}
                                                            </CardContent>
                                                        </CardActionArea>
                                                        <CardActions>
                                                            <Button size="small" color="primary">
                                                            edit
                                                            </Button>|
                                                            <Button size="small" color="primary">
                                                            delete
                                                            </Button>|
                                                            <Button size="small" color="primary">
                                                            read
                                                            </Button>
                                                        </CardActions>
                                                        
                                                        </Card>
                                                        
                                                    </Grid>
                                                    ))}
                                                     
                                                </Grid>
                                                <Dialog
                                                    open={open}
                                                    onClose={handleClose}>
                                                        <DialogTitle>Configure emails</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                Interested in this health event...
                                                            </DialogContentText>

                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleClose}>Cancel</Button>
                                                            <Button onClick={interestedEvent}>Interested</Button>
                                                        </DialogActions>

                                                </Dialog>
                                                <Dialog
                                                   fullScreen
                                                   open={articlesOpen}
                                                   aria-label="close"
                                                   >
                                                  <AppBar sx={{ position: 'relative'}}>
                                                   <Toolbar>
                                                    <IconButton
                                                            edge="start"
                                                            color="inherit"
                                                            onClick={handleArticleClose}
                                                        >
                                                        <CloseIcon/>
                                                        </IconButton>
                                                        <Typography sx={{ ml: 2, flex: 1}} variant="h6" component="div">
                                                            Retrieve articles from different sources on the web
                                                        </Typography>
                                                        <Button autoFocus color="inherit" onClick={handleSaveArticle}>
                                                            save
                                                        </Button>
                                                   </Toolbar>
                                                   </AppBar>
                                                   <List>
                                                       <ListItem>
                                                            <h4>World Health Organization</h4>
                                                       </ListItem>
                                                       <ListItem button>
                                                            <Box
                                                                sx={{
                                                                    width: 500,
                                                                    maxWidth: '100%'
                                                                }}
                                                            >
                                                                <TextField fullWidth label="Enter page number" id="txtPages"/>
                                                            </Box>
                                                       </ListItem>
                                                   </List>
                                                 </Dialog>
                                                 <Fab color="primary" variant="contained" aria-label="add" style={{ 'marginTop': '20px', 'marginLeft':'70px'}} 
                                                      onClick={scrapeArticles}>
                                                    <AutorenewIcon />
                                                 </Fab>
                                                </Box>
                                               
                                            </Main>

                                        
                                        );
                                    case '/appointments':
                                        return(
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>{"Appointments"}</h3>
                                                {/*<Fab color="success" variant="contained" aria-label="add" style={{ marginLeft: '154%'}}>
                                                    <AddIcon/>
                                                </Fab>*/}
                                                
                                                <div style={{ height: 400, width: '158%', backgroundColor: 'white', marginLeft: '0%',marginTop:'3%'}}>
                                                <br/><br/><br/><br/><br/>             
                                                <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <label htmlFor="department">{"Department:"}</label>
                                                                <select className="form-control" onChange={chooseDoctor}>
                                                                    <option name={"choose_campaign"}>{"Choose a department"}</option>
                                                                    {department.map((item)=>{
                                                                        return(
                                                                            <option value={item.department}>{item.department}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="col-sm-offset-2 col-sm-10" style={{marginLeft: '-63px'}}>
                                                                <label htmlFor="action_type">{"Action type:"}</label>
                                                                <select className="form-control" onChange={getAppointmentAction}>
                                                                    <option value="no_action">{"No action"}</option>
                                                                    <option value={"referral"}>{"Referral"}</option>
                                                                    <option value={"update_sitemap"}>{"Save sitemap"}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '24px'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <button className="btn btn-success" style={{marginLeft: '-120px'}} onClick={moveCampaign}>{"Submit"}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '-11%',marginLeft: '-2%'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <Box sx={{minWidth: 275}}>
                                                                    <Card variant="outlined">{referralsAppointment}</Card>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '-11%', marginLeft: '-52%'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <Box sx={{ minWidth: 275}}>
                                                                    <Card variant="outlined">{appointmentTotalCard}</Card>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>                                                   
                                                    <DataGrid
                                                        rows={appointments}
                                                        columns={columnsAppointment}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                        onRowClick={(params,event)=>{
                                                            if(!event.ignore){
                                                                console.log("push -> /roles/" + params.row.id);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                                <h3>{"Comments"}</h3>
                                                <br/>
                                                <div style={{ height: 400, width: '158%', backgroundColor: 'white', marginLeft: '0%',marginTop:'3%'}}>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <label htmlFor="action_type">{"Appointment:"}</label>
                                                                <select className="form-control" onChange={commentsAction}>
                                                                    <option value={"no_action"}>{"Choose an action"}</option>
                                                                    {appointments.map((appointment)=>{
                                                                        return(
                                                                            <option value={appointment.name}>{appointment.name}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="col-sm-offset-2 col-sm-10" style={{marginLeft: '-63px'}}>
                                                                <label htmlFor="action_type">{"Action type:"}</label>
                                                                <select className="form-control" onChange={appointmentRelationship}>
                                                                    <option value={"no_action"}>{"Choose an action"}</option>
                                                                    <option value={"assign_to_appointment"}>{"Assign to appointment"}</option>
                                                                    <option value={"scrape_comments"}>{"Scrape comments"}</option>
                                                                    <option value={"scrape_replies"}>{"Scrape replies"}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '24px'}}>
                                                            <div className="col-sm-offset-2 col-sm-2">
                                                                <button className="btn btn-success" style={{marginLeft: '-120px'}} onClick={submitComments}>{"Submit"}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <DataGrid
                                                        rows={comments}
                                                        columns={columnsAppointmentComments}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                        onRowClick={(params,event)=>{
                                                            if(!event.ignore){
                                                               setOpenComment(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <br/><br/>
                                                <BootstrapDialog
                                                    onClose={handleCloseComment}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={openComment}
                                                >
                                                    <BootstrapDialogTitle>
                                                        {"View and edit item"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <div className="col-lg-36" style={{marginTop: '-100px'}}>
                                                            <div className="bg-white rounded p-5 mb-0">
                                                                <div className="row g-3" style={{marginLeft: '-41px',width: '117%'}}>
                                                                    <p style={{marginLeft: '-10px'}}><u>{"Id:"}</u></p>
                                                                    <input type="text" value={idC} className="form-control bg-light border-1" placeholder="identity" style={{borderColor: 'black'}} onChange={handleIdC}/>
                                                                </div>
                                                                <div className="row g-3" style={{marginLeft: '-41px',width: '117%'}}>
                                                                    <p style={{marginLeft: '-10px'}}><u>{"Date:"}</u></p>
                                                                    <input type="text" value={dateC} className="form-control bg-light border-1" placeholder="Date" style={{borderColor: 'black'}} onChange={handleDateC}/>
                                                                </div>
                                                                <div className="row g-3" style={{marginLeft: '-41px',width: '117%'}}>
                                                                    <p style={{marginLeft: '-10px'}}><u>{"Image:"}</u></p>
                                                                    <input type="text" value={imageC} className="form-control bg-light border-1" placeholder="Image" style={{borderColor: 'black'}} onChange={handleImageC}/>
                                                                </div>
                                                                <div className="row g-3" style={{marginLeft: '-41px',width: '465%'}}>
                                                                    <p style={{marginLeft: '-10px'}}><u>{"Appointment:"}</u></p>
                                                                    
                                                                    <select className="form-control border-primary w-25" style={{borderColor: 'black'}} onChange={handleChange}>
                                                                        <option name="appointment">{"Choose an appointment"}</option>
                                                                        {appointments.map((appointment)=>{
                                                                            return(
                                                                                <option value={appointment.name}>{appointment.name}</option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3" style={{marginLeft: '-41px', width: '117%'}}>
                                                                <p style={{marginLeft: '-10px'}}><u>{"Comment:"}</u></p>
                                                                <CKEditor
                                                                                            className="form-control bg-light border-1"
                                                                                            style={{borderColor: 'black'}}
                                                                                            placeholder="Blog body 1"
                                                                                            id="blogBody1"
                                                                                            editor={ ClassicEditor }
                                                                                            data={commentC}
                                                                                            onReady={ editor => {
                                                                                                //setBlogBody1(editor.getData());
                                                                                                setCommentC(editor.getData());
                                                                                                //console.log('editor is ready to use!',editor);
                                                                                            }}
                                                                                            onChange={(event,editor)=>{
                                                                                                //setBlogBody1(editor.getData());
                                                                                                setCommentC(editor.getData());
                                                                                                //event.preventDefault();
                                                                                                console.log('Focus.',editor);
                                                                                            }}
                                                                                        />
                                                                </div>
                                                                <div className="row g-3">
                                                                    <button className="btn btn-success">{"Generate comment"}</button>
                                                                </div>
                                                                <div className="row g-3" style={{marginLeft: '-41px',width: '117%'}}>
                                                                    <p style={{marginLeft: '-10px'}}><u>{"Type:"}</u></p>
                                                                    <input type="text" value={typeC} className="form-control bg-light border-1" placeholder="Type" style={{borderColor: 'black'}} onChange={handleTypeC}/>
                                                                </div>
                                                                <div className="row g-3" style={{marginLeft: '-41px',width: '117%'}}>
                                                                    <p style={{marginLeft: '-10px'}}><u>{"User:"}</u></p>
                                                                    <input type="text" value={userC} className="form-control bg-light border-1" placeholder="User" style={{borderColor: 'black'}} onChange={handleUserC}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={handleCloseComment}>
                                                            {"Cancel"}  
                                                        </Button>
                                                        <Button autoFocus onClick={handleSaveComment}>
                                                            {"Save"}
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                                <BootstrapDialog
                                                    onClose={handleCloseAppointment}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={openAppointment}
                                                >
                                                    <BootstrapDialogTitle>
                                                        {"View and edit item"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>

                                                        <div className="col-lg-36" style={{marginTop: '-100px'}}>
                                                            <div className="bg-white rounded p-5 m-5 mb-0">
                                                                <div className="row g-3" style={{marginLeft: '-81px',width: '141%'}}>
                                                                    <p><u>{"Id"}</u></p>
                                                                    <input type="text" value={idYX} className="form-control bg-light border-1" placeholder="identity" style={{borderColor: 'black'}}/>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3" style={{marginLeft: '-81px',width: '141%'}}>
                                                                    <p><u>{"Specialist"}</u></p>
                                                                    <select className="form-control" onChange={handleSpecialistXY} style={{borderColor: 'black'}}>
                                                                        <option name={"choose_specialist"}>{"Choose a specialist"}</option>
                                                                        {specialist.map((item)=>{
                                                                            return(
                                                                                <option value={item.name}>{item.name}</option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                    
                                                                    {/*<input type="text" value={specialistXY} className="form-control bg-light border-1" placeholder="specialist" style={{borderColor: 'black'}} onChange={handleSpecialistXY}/>*/}
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3" style={{marginLeft: '-81px',width: '141%'}}>
                                                                    <p><u>{"Name"}</u></p>
                                                                    <input type="text" value={nameXY} className="form-control bg-light border-1" placeholder="name" style={{borderColor: 'black'}} onChange={handleNameXY}/>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3" style={{marginLeft: '-81px',width: '141%'}}>
                                                                    <p><u>{"Time"}</u></p>
                                                                    <input type="text" value={timeXY} className="form-control bg-light border-1" placeholder="time" style={{borderColor: 'black'}} onChange={handleTimeXY}/>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3" style={{marginLeft: '-81px',width: '141%'}}>
                                                                    <p><u>{"Date"}</u></p>
                                                                    <input type="text" value={dateXY} className="form-control bg-light border-1" style={{borderColor: 'black'}} onChange={handleDateXY}/>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3" style={{marginLeft: '-81px',width: '141%'}}>
                                                                    <p><u>{"Department"}</u></p>
                                                                    <select className="form-control" onChange={handleDepartmentXY} style={{borderColor: 'black'}}>
                                                                        <option name={"choose_department"}>{"Choose a department"}</option>
                                                                        {department.map((item)=>{
                                                                            return(
                                                                                <option value={item}>{item}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3" style={{marginLeft: '-81px',width: '141%'}}>
                                                                    <p><u>{"Message"}</u></p>
                                                                    <input type="text" value={messageXY} className="form-control bg-light border-1" placeholder="message" style={{borderColor: 'black'}} onChange={handleMessageXY}/>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3" style={{marginLeft: '-81px',width: '141%'}}>
                                                                    <p><u>{"Email"}</u></p>
                                                                    <input type="text" value={emailXY} className="form-control bg-light border-1" placeholder="email" style={{borderColor: 'black'}} onChange={handleEmailXY}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={handleCloseAppointment}>
                                                            {"Cancel"}
                                                        </Button>
                                                        <Button autoFocus onClick={editAppointment}>
                                                            {"Save"}
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                                <Snackbar open={openXY} autoHideDuration={6000} onClose={snackBarCloseXY}>
                                                    <Alert onClose={snackBarCloseXY} severity="success" sx={{ width: '100%'}}>
                                                        {"Appointment updated successfuly!"}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={openEditSnackbar} autoHideDuration={6000} onClose={handleCommentEditClose}>
                                                    <Alert onClose={handleCommentEditClose} severity="success" sx={{width: '100%'}}>
                                                        {"Comment edit successfully!"}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={commentErrorSnackbarOpen} autoHideDuration={6000} onClose={handleCommentErrorClose}>
                                                    <Alert onClose={handleCommentErrorClose} severity="error" sx={{width: '100%'}}>
                                                        {commentErrorMessage}
                                                    </Alert>
                                                </Snackbar>
                                            </Main>
                                        );
                                    case '/post':
                                        
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>{"Post"}</h3>
                                                <br/>
                                                {/*<Fab color="success" variant="contained" aria-label="add" style={{ marginLeft: '154%'}}
                                                    onClick={createAdvertising}>
                                                    <AddIcon/>
                                                </Fab>*/}
                                                <div className="row" style={{borderColor: 'black'}}>
                                                    <div className="col-sm-3">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <label htmlFor="sel1">Departments:</label>
                                                                <select className="form-control" id="department1" onChange={getDepartment}>
                                                                    <option name="no_department">{"No department"}</option>
                                                                    {department.map((item)=>{
                                                                        return(
                                                                            <option className="h5 bg-light rounded py-2 px-2 mb-3" value={item.department}>{item.department}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <label htmlFor="sel2">Sites:</label>
                                                                <select className="form-control" id="sites" onChange={getSite}>
                                                                    <option name="no_site">{"No site"}</option>
                                                                        {sites.map((site)=>{
                                                                            return(
                                                                                <option value={site.link}>{site.name}</option>
                                                                            );
                                                                        })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <label htmlFor="sel2">{"Action Type:"}</label>
                                                                <select className="form-control" id="posts_action_type" onChange={getPostAction}>
                                                                    <option name={"action"} value={"no_action"}>{"No action"}</option>
                                                                    <option name={"action"} value={"scrape_posts"}>{"Scrape posts"}</option>
                                                                    <option name={"action"} value={"update_sitemap"}>{"Save sitemap"}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2" style={{marginTop: '24px'}}>
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <button className="btn btn-success" onClick={getPost}>{"Submit"}</button>
                                                            </div>                 
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3" style={{marginTop: '24px',marginRight: '-270%'}}>
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <Box sx={{ minWidth: 275 }}>
                                                                <Card variant="outlined">{card}</Card>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{height:400, width:'150%', backgroundColor: 'white',marginLeft:'10%',marginTop:'17%'}}>
                                                    <DataGrid
                                                        rows={posts}
                                                        columns={postColumns}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                        onRowClick={(params,event)=>{
                                                            if(!event.ignore){
                                                                console.log("push -> /roles/" + params.row.id);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <DrawerHeader/>
                                                <h3>{"Latest news"}</h3>
                                                <br/>
                                                <div className="row" style={{borderColor: 'black'}}>
                                                    <div className="col-sm-3">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <label htmlFor="sel1">Departments:</label>
                                                                <select className="form-control" id="department1" onChange={getDepartment}>
                                                                    <option name="no_department">{"No department"}</option>
                                                                    {department.map((item)=>{
                                                                        return(
                                                                            <option className="h5 bg-light rounded py-2 px-2 mb-3" value={item.department}>{item.department}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <label htmlFor="sel2">Sites:</label>
                                                                <select className="form-control" id="sites" onChange={getSite}>
                                                                    <option name="no_site">{"No site"}</option>
                                                                        {sites.map((site)=>{
                                                                            return(
                                                                                <option value={site.link}>{site.name}</option>
                                                                            );
                                                                        })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <label htmlFor="sel2">{"Action Type:"}</label>
                                                                <select className="form-control" id="posts_action_type" onChange={getPostAction}>
                                                                    <option name={"action"} value={"no_action"}>{"No action"}</option>
                                                                    <option name={"action"} value={"scrape_posts"}>{"Scrape posts"}</option>
                                                                    <option name={"action"} value={"update_sitemap"}>{"Save sitemap"}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2" style={{marginTop: '24px'}}>
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <button className="btn btn-success" onClick={getPost}>{"Submit"}</button>
                                                            </div>                 
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3" style={{marginTop: '24px',marginRight: '-270%'}}>
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <Box sx={{ minWidth: 275 }}>
                                                                <Card variant="outlined">{latestPostCard}</Card>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{height:400, width:'150%', backgroundColor: 'white',marginLeft:'10%',marginTop:'17%'}}>
                                                        <DataGrid
                                                            rows={latestNews}
                                                            columns={latestPostColumns}
                                                            pageSize={5}
                                                            rowsPerPageOptions={[5]}
                                                            checkboxSelection
                                                            onRowClick={(params,event)=>{
                                                                if(!event.ignore){
                                                                    console.log("push -> /roles/" + params.row.id);
                                                                }
                                                            }}
                                                        />
                                                </div>
                                                <Snackbar open={articleSnackOpen} autoHideDuration={6000} onClose={articleBarClose}>
                                                    <Alert onClose={articleBarClose} severity="success" sx={{ width: '100%'}}>
                                                        {"Article updated successfully, Please check the log monitor."}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={scraperSnackOpen} autoHideDuration={6000} onClose={scraperBarClose}>
                                                    <Alert onClose={scraperBarClose} severity="success" sx={{ width: '100%'}}>
                                                        {"Article scraping process was successfully, Please check the log monitor."}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={scraperStatusOpen} autoHideDuration={6000} onClose={scraperStatusClose}>
                                                    <Alert onClose={scraperStatusClose} severity="error" sx={{ width: '100%'}}> 
                                                        {"Article scraping process was not successfully, Please check the log monitor."}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={errorStatusOpen} autoHideDuration={6000} onClose={errorStatusClose}>
                                                    <Alert onClose={errorStatusClose} severity="error" sx={{ width: '100%'}}>
                                                        {"Inputs not provided, please check with departments or sites fields!"}
                                                    </Alert>
                                                </Snackbar>
                                                <BootstrapDialog
                                                    onClose={handleCloseAdvertising}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={openAdvertiseDialog}
                                                >
                                                    <BootstrapDialogTitle>
                                                        {"Create an advertise"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <>
                                                            <div className="col-lg-36" style={{marginTop: '-100px'}}>
                                                                <div className="bg-white rounded p-5 m-5 mb-0">
                                                                    <div className="row g-3">
                                                                        <p><u>{"Advertising:"}</u></p>
                                                                        <input type="text" className="form-control bg-light border-1" placeholder="Advertising" onChange={handleAdvertising}/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Department:"}</u></p>
                                                                        <select className="form-control" id="departmet" onChange={handleAdAdvertising}>
                                                                            <option name="no_department" value={"no_department"}>{"No department"}</option>
                                                                            {department.map((item)=>{
                                                                                return(
                                                                                    <option className="h5 bg-light rounded py-2 px-2 mb-3" value={item.department}>{item.department}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={handleCloseAdvertising}>
                                                            Cancel
                                                        </Button>
                                                        <Button autoFocus onClick={saveAdvertising}>
                                                            Save
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                                <BootstrapDialog
                                                    onClose={handleLatestNewsDialogClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={openLatestNewsDialog}
                                                >
                                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleLatestNewsDialogClose}>
                                                        {"View and edit item"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <>
                                                            <div className="col-lg-36" style={{marginTop: '-100px'}}>
                                                                <div className="bg-white rounded p-5 m-5 mb-0">
                                                                    <div className="row g-3">
                                                                        <p><u>{"Id"}</u></p>
                                                                        <input type="text" value={latestNewsId} className="form-control bg-light border-1" placeholder="Id" style={{borderColor: 'black'}} id="lId" readOnly/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Author"}</u></p>
                                                                        <input type="text" value={latestNewsAuthor} className="form-control bg-light border-1" placeholder="Author" style={{borderColor: 'black'}} id="lAuthor" onChange={handleNewsAuthorChange}/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Slug"}</u></p>
                                                                        <input type="text" value={latestNewsSlug} className="form-control bg-light border-1" placeholder="Slug" style={{borderColor: 'black'}} id="lSlug" onChange={handleNewsSlugChange}/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Sidebar height"}</u></p>
                                                                        <input type="text" value={latestNewsHeight} className="form-control bg-light border-1" placeholder="Sidebar height" style={{borderColor: 'black'}} id="lSidebarheight" onChange={handleLatestNewsHeightChange}/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Blog image"}</u></p>
                                                                        <input type="text" value={latestNewsImage} className="form-control bg-light border-1" placeholder="Blog image" style={{borderColor: 'black'}} id="lBlogimage" onChange={handleLatestNewsImage}/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Blog title"}</u></p>
                                                                        <input type="text" value={latestNewsTitle} className="form-control bg-light border-1" placeholder="Blog title" style={{borderColor: 'black'}} id="lBlogtitle" onChange={handleLatestNewsTitle}/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Category"}</u></p>
                                                                        <input type="text" value={latestNewsCategory} className="form-control bg-light border-1" placeholder="Category" style={{borderColor: 'black'}} id="lcategory" onChange={handleLatestNewsCategory}/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"SEO Score"}</u></p>
                                                                        <input type="text" value={latestNewsSeoscore} className="form-control bg-light border-1" placeholder="SEO Score" style={{borderColor: 'black'}} id="lseoscore" onChange={handleLatestNewsSeoscore}/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Blog body 1"}</u></p>
                                                                        <CKEditor
                                                                            className="form-control bg-light border-1"
                                                                            style={{borderColor: 'black'}}
                                                                            placeholder="Blog body 1"
                                                                            id="lblogBody1"
                                                                            editor={ ClassicEditor }
                                                                            data={latestNewsBody1}
                                                                            onReady={ editor => {
                                                                                setLatestNewsBody1(editor.getData());
                                                                            }}
                                                                            onChange={(event,editor)=>{
                                                                                setLatestNewsBody1(editor.getData());
                                                                                console.log('Focus.',editor);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Blog body 2"}</u></p>
                                                                        <CKEditor
                                                                            className="form-control bg-light border-1"
                                                                            style={{borderColor: 'black'}}
                                                                            placeholder="Blog body 2"
                                                                            id="lblogBody2"
                                                                            editor={ ClassicEditor }
                                                                            data={latestNewsBody2}
                                                                            onReady={ editor => {
                                                                                setLatestNewsBody2(editor.getData());
                                                                            }}
                                                                            onChange={(event,editor)=>{
                                                                                setLatestNewsBody2(editor.getData());
                                                                                console.log('Focus.',editor);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Blog body 3"}</u></p>
                                                                        <CKEditor
                                                                            className="form-control bg-light border-1"
                                                                            style={{borderColor: 'black'}}
                                                                            placeholder="Blog body 3"
                                                                            id="lblogBody3"
                                                                            editor={ ClassicEditor }
                                                                            data={latestNewsBody3}
                                                                            onReady={ editor => {
                                                                                setLatestNewsBody3(editor.getData());
                                                                            }}
                                                                            onChange={(event,editor)=>{
                                                                                setLatestNewsBody3(editor.getData());
                                                                                console.log('Focus.',editor);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="row g-3">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={handleLatestNewsDialogClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button autoFocus onClick={handleLatestNewsUpdates}>
                                                            Save
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                                <BootstrapDialog
                                                    onClose={handleClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={open}
                                                >
                                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                                        {"View and edit item"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <>
                                                            {filteredResults.map((result)=>{
                                                                    return(
                                                                            <div className="col-lg-36" style={{marginTop: '-100px'}}>
                                                                                <div className="bg-white rounded p-5 m-5 mb-0">
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Id"}</u></p>
                                                                                        <input type="text" value={Idx} className="form-control bg-light border-1" placeholder="id" style={{borderColor: 'black'}} id="idx" readOnly/>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Author"}</u></p>
                                                                                        <input type="text" value={author} className="form-control bg-light border-1" placeholder="author" style={{borderColor: 'black'}} id="Author" onChange={handleAuthor}/>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Slug"}</u></p>
                                                                                        <input type="text" value={slug} className="form-control bg-light border-1" placeholder="id" style={{borderColor: 'black'}} id="slugX"/>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Sidebar height"}</u></p>
                                                                                        <input type="text" value={sidebarHeightX} className="form-control bg-light border-1" style={{borderColor: 'black'}} placeholder="Sidebar height" id="sidebar height" onChange={handleSidebarHeight}/>
                                                                                    </div>
                                                                                    <br/>
                                                                                    {/*<div className="row g-3">
                                                                                        <input type="text" value={userImage} className="form-control bg-light border-1" placeholder="User Image" style={{borderColor: 'black'}} id="userImg" onChange={handleUserImage}/>
                                                                                    </div>
                                                                                    <br/>*/}
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Blog Image"}</u></p>
                                                                                        <input type="text" value={blogImage} className="form-control bg-light border-1" placeholder="Blog Image" style={{borderColor: 'black'}} id="blogImage" onChange={handleBlogImage}/>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Blog Title"}</u></p>
                                                                                        <input type="text" value={blogTitle} className="form-control bg-light border-1" placeholder="Blog title" style={{borderColor: 'black'}} id="blogTitle" onChange={handleBlogTitle}/>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Category"}</u></p>
                                                                                        <input type="text" value={blogCategory} className="form-control bg-light border-1" placeholder="Blog category" style={{borderColor: 'black'}} id="blogCat" onChange={handleBlogCategory}/>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"SEO Score"}</u></p>
                                                                                        <input type="text" value={seoScore} className="form-control bg-light border-1" placeholder="SEO Score" style={{borderColor: 'black'}} id="seo_score" onChange={handleSeoScore}/>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Blog Body 1"}</u></p>
                                                                                        <CKEditor
                                                                                            className="form-control bg-light border-1"
                                                                                            style={{borderColor: 'black'}}
                                                                                            placeholder="Blog body 1"
                                                                                            id="blogBody1"
                                                                                            editor={ ClassicEditor }
                                                                                            data={blogBody1}
                                                                                            onReady={ editor => {
                                                                                                setBlogBody1(editor.getData());
                                                                                                //console.log('editor is ready to use!',editor);
                                                                                            }}
                                                                                            onChange={(event,editor)=>{
                                                                                                setBlogBody1(editor.getData());
                                                                                                //event.preventDefault();
                                                                                                console.log('Focus.',editor);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Blog Body 2"}</u></p>
                                                                                        <CKEditor
                                                                                            className="form-control bg-light border-1"
                                                                                            placeholder="Blog body 2"
                                                                                            style={{borderColor: 'black'}}
                                                                                            editor={ ClassicEditor }
                                                                                            data={blogBody2}
                                                                                            id="blogBody2"
                                                                                            onReady={editor => {
                                                                                                setBlogBody2(editor.getData());
                                                                                                //console.log('editor is ready to use!',editor);
                                                                                            }}
                                                                                            onChange={(event,editor)=>{
                                                                                                setBlogBody2(editor.getData());
                                                                                                //event.preventDefault();
                                                                                                //console.log('Focus.',editor);
                                                                                            }}
                                                                                            onBlur={(event,editor)=>{
                                                                                                //event.preventDefault();
                                                                                                console.log('Focus.',editor);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Blog Body 3"}</u></p>
                                                                                        <CKEditor
                                                                                            className="form-control bg-light border-1"
                                                                                            placeholder="Blog body 3"
                                                                                            id="blogBody3"
                                                                                            style={{borderColor:'black'}}
                                                                                            editor={ ClassicEditor }
                                                                                            data={blogBody3}
                                                                                            onReady={editor=>{
                                                                                                setBlogBody3(editor.getData());
                                                                                            }}
                                                                                            onChange={(event,editor)=>{
                                                                                                event.preventDefault();
                                                                                                setBlogBody3(editor.getData());
                                                                                               // console.log({event, editor, data});
                                                                                            }}
                                                                                            onBlur={(event,editor)=>{
                                                                                                event.preventDefault();
                                                                                                //console.log('focus',editor);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"SEO score calculator"}</u></p>
                                                                                        <Paper
                                                                                            sx={{
                                                                                                display: 'flex',
                                                                                                justifyContent: 'center',
                                                                                                flexWrap: 'wrap',
                                                                                                listStyle: 'none',
                                                                                                p: 0.5,
                                                                                                m: 0,
                                                                                            }}
                                                                                            component="ul"
                                                                                        >
                                                                                            <Button variant="outlined" onClick={check}>calculate</Button>

                                                                                        </Paper>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="row g-3">
                                                                                        <p><u>{"Keywords"}</u></p>
                                                                                        <Paper
                                                                                            sx={{
                                                                                                display: 'flex',
                                                                                                justifyContent: 'center',
                                                                                                flexWrap: 'wrap',
                                                                                                listStyle: 'none',
                                                                                                p: 0.5,
                                                                                                m: 0,
                                                                                            }}
                                                                                            component="ul"
                                                                                            >

                                                                                                {tagPost.map((data) => {
                                                                                                    return (
                                                                                                    <ListItem key={data.slug}>
                                                                                                        <Chip
                                                                                                            label={data.keyword}                                                                                                        
                                                                                                        />
                                                                                                    </ListItem>
                                                                                                    );
                                                                                                })}
                                                                                        </Paper>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    );
                                                                })}                       
                                                        </>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={deletePost}>
                                                            Delete
                                                        </Button>
                                                        <Button onClick={viewPost}>
                                                            View
                                                        </Button>
                                                        <Button autoFocus onClick={save}>
                                                            Save
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                            </Main>
                                        );
                                    case '/doctor': 
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>{"Doctor"}</h3>
                                                <br/>
                                                {/*<Fab color="success" variant="contained" aria-label="add" style={{ marginLeft: '154%'}}>
                                                    <AddIcon/>
                                                </Fab>*/}
                                                <div className="row">
                                                        
                                                        <div className="col-sm-3">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                    <div className="form-group">
                                                                        <label htmlFor="sel1">{"Departments:"}</label>
                                                                        <select className="form-control" id="department" onChange={getDepartment}>
                                                                            <option value="no_department">{"No department"}</option>
                                                                            {department.map((item)=>{
                                                                                return(
                                                                                    <option value={item.department}>{item.department}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                                <div className="col-sm-offset-2 col-sm-10">
                                                                    <div className="form-group">
                                                                        <label htmlFor="sel2">{"Hospitals:"}</label>
                                                                        <select className="form-control" name="no_hospital" id="hospital" onChange={getHospital}>
                                                                            <option value="no_hospital">{"Select hospital"}</option>
                                                                            <option value="netcare_limited">{"Netcare Limited"}</option>
                                                                            <option value="mediclinic_international">{"Mediclinic International"}</option>
                                                                            <option value="discovery_limited">{"Discovery Limited"}</option>
                                                                            <option value="life_healthcare_group">{"Life Healthcare Group"}</option>
                                                                            <option value="unjani">{"Unjani"}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <label htmlFor="sel3">{"Action type:"}</label>
                                                                    <select className="form-control" name="action_type" id="action" onChange={getAction}>
                                                                        <option name={"action"} value="no_action">{"No action"}</option>
                                                                        <option name={"action"} value="get_data">{"Scrape doctor"}</option>
                                                                        <option name={"action"} value={"generate_biography"}>{"Generate original biography"}</option>
                                                                        <option name={"action"} value={"update_sitemap"}>{"Save sitemap"}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2" style={{marginTop: '24px'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <button type="submit" className="btn btn-success" onClick={getDoctor}>{"Submit"}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                   
                                                        <div className="col-sm-3" style={{marginTop: '24px',marginRight: '-270%'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <Box sx={{ minWidth: 275 }}>
                                                                    <Card variant="outlined">{doctorCard}</Card>
                                                                </Box>
                                                            </div>
                                                        </div>

                                                </div>
                                                <br/>
                                                <div style={{height:400, width:'150%', backgroundColor: 'white',marginLeft:'10%',marginTop:'17%'}}>    
                                                    <div className="row">
                                                        <div className="col-sm-16">
                                                            <div className="col-sm-offset-2 col-sm-20">
                                                                <h5><u>{"Normalized data of healthcare professionals in a network"}</u></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <DataGrid
                                                        rows={normalized}
                                                        columns={normalizedColumns}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                        onRowClick={(params,event)=>{
                                                            if(!event.ignore){
                                                                console.log("push -> /roles/" + params.row.id)
                                                            }
                                                        }}
                                                    />
                                                    <br/>
                                                    <BootstrapDialog
                                                        onClose={handleCloseNormalized}
                                                        aria-labelledby="customized-dialog-title-data"
                                                        open={openNormalized}  
                                                    >
                                                        <BootstrapDialogTitle id="customized-dialog-title-normalized" onClose={handleCloseNormalized}>
                                                            {"View and edit item"}
                                                        </BootstrapDialogTitle>
                                                        <DialogContent dividers>
                                                            <>
                                                                        <div className="col-lg-36" style={{marginTop: '-100px'}} key={dId}>
                                                                        <div className="bg-white rounded p-5 m-5 mb-0">
                                                                            <div className="row g-3">
                                                                                <p><u>{"Id"}</u></p>
                                                                                <input type="text" id="dId" value={dId} className="form-control bg-light border-1" placeholder="identity" style={{borderColor: 'black'}}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Biography"}</u></p>
                                                                                <CKEditor
                                                                                    id="dBio"
                                                                                    className="form-control bg-light border-1"
                                                                                    placeholder="Biography"
                                                                                    style={{borderColor: 'black'}}
                                                                                    editor={ ClassicEditor}
                                                                                    data={dBio}
                                                                                    onReady={editor=>{
                                                                                        setdBio(editor.getData());  
                                                                                    }}
                                                                                    onBlur={(editor)=>{
                                                                                        setdBio(editor.getData());
                                                                                    }}
                                                                                    onChange={(event,editor)=>{
                                                                                        setdBio(editor.getData());
                                                                                        console.log('Focus', editor);
                                                                                    }}

                                                                                />
                                                                            </div>
                                                                            <div className="row g-3">
                                                                                 <button className="btn btn-success" onClick={generateBio}>{"generate bio"}</button>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Department"}</u></p>
                                                                                <input type="text" id="dDepartment" value={dDepartment} className="form-control bg-light border-1" placeholder="department" style={{borderColor: 'black'}} onChange={handleDepartmentDocChange}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Learning score"}</u></p>
                                                                                <Rating 
                                                                                    name="customized-10" 
                                                                                    id="dLearnScore" 
                                                                                    //defaultValue={dLearnScore} 
                                                                                    max={10} 
                                                                                    precision={1}
                                                                                    value={dLearnScore}
                                                                                    className="form-control bg-light border-1" 
                                                                                    style={{borderColor: 'black'}} 
                                                                                    onChange={handleLearnScoreDocChange}
                                                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                                                />
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Image"}</u></p>
                                                                                <input type="text" value={dImage} id="dImage" className="form-control bg-light border-1" placeholder="image" style={{borderColor: 'black'}} onChange={""}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Slug"}</u></p>
                                                                                <input type="text" value={dName} id="dName" className="form-control bg-light border-1" placeholder="name" style={{borderColor: 'black'}} onChange={handleNameDoc}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Certificates"}</u></p>
                                                                                <input type="text" value={dCert} id="dCert" className="form-control bg-light border-1" placeholder="Certificates" style={{borderColor: 'black'}} onChange={handleCertificatesDoc}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Specialists"}</u></p>
                                                                                <input type="text" value={dSpec} id="dSpec" className="form-control bg-light border-1" placeholder="specialists" style={{borderColor: 'black'}} onChange={handleSpecialistDoc}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Total number of bed(s)"}</u></p>
                                                                                <input type="text" value={dPic} id="dPic" className="form-control bg-light border-1" placeholder="picture" style={{borderColor: 'black'}} onChange={handlePictureDoc}/>
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                                
                                                            </>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button autoFocus onClick={closeX}>
                                                                Cancel                   
                                                            </Button>
                                                            <Button autoFocus onClick={saveXy}>
                                                                Save
                                                            </Button>
                                                        </DialogActions>
                                                    </BootstrapDialog>
                                                    <Snackbar open={openDocEdit} autoHideDuration={6000} onClose={handleNormClose}>
                                                            <Alert onClose={handleNormClose} severity="success" sx={{ width: '100%' }}>
                                                                Doctor updated successfully!
                                                            </Alert>
                                                    </Snackbar>
                                                    <Snackbar open={openBio} autoHideDuration={6000} onClose={handleBioClose}>
                                                        <Alert onClose={handleBioClose} severity="success" sx={{ width: '100%'}}>
                                                            {"Bio generated successfully!"}
                                                        </Alert>
                                                    </Snackbar>
                                                    <Snackbar open={openCommentSnackbar} autoHideDuration={6000} onClose={handleCommentSnackbarClose}>
                                                        <Alert onClose={handleCommentSnackbarClose} severity="success" sx={{ width:  '100%'}}>
                                                           {errorCommentMessage}   
                                                        </Alert>
                                                    </Snackbar>
                                                    <hr/>
                                                    <div className="row">
                                                        <div className="col-sm-16">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <h5><u>{"Original data of healthcare professionals in a network"}</u></h5>
                                                            </div>
                                                        </div>
                                                       <br/>
                                                    </div>
                                                    <br/>
                                                    <hr/>
                                                    <DataGrid
                                                        rows={specialist}
                                                        columns={doctorColumns}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                        onRowClick={(params,event)=>{
                                                            if(!event.ignore){
                                                                console.log("push -> /roles/" + params.row.id);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <BootstrapDialog
                                                    onClose={handleClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={open}
                                                >
                                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                                        {"original data of doctor"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <>
                                                            
                                                                    <div className="col-lg-36" style={{marginTop: '-100px'}}>
                                                                        <div className="bg-white rounded p-5 m-5 mb-0">
                                                                            <div className="row g-3">
                                                                                <input type="hidden" value={telY}/>
                                                                            </div>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Id"}</u></p>
                                                                                <input type="text" value={idY} className="form-control bg-light border-1" placeholder="Id" style={{borderColor: 'black'}}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Name"}</u></p>
                                                                                <input type="text" value={nameY} className="form-control bg-light border-1" placeholder="User name" style={{borderColor: 'black'}} onChange={handleOriginalDoctorName}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Email"}</u></p>
                                                                                <input type="text" value={emailY} className="form-control bg-light border-1" placeholder="Email" style={{borderColor: 'black'}} onChange={handleOrignalDoctorEmail}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Department"}</u></p>
                                                                                <input type="text" value={departmentY} className="form-control bg-light border-1" placeholder="Department" style={{borderColor: 'black'}} onChange={handleOriginalDoctorDepartment}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Description"}</u></p>
                                                                                <CKEditor
                                                                                    className="form-control bg-light border-1"
                                                                                    placeholder="Description"
                                                                                    style={{borderColor: 'black'}}
                                                                                    editor={ ClassicEditor }
                                                                                    data={descriptionY}
                                                                                    onReady={ editor => {
                                                                                        setDocDesc(editor.getData());
                                                                                        console.log('editor is ready to use!',editor);
                                                                                    }}
                                                                                    onChange={(event, editor)=>{
                                                                                        //event.preventDefault();
                                                                                        const data = editor.getData();
                                                                                        setDocDesc(editor.getData());
                                                                                        console.log({event, editor, data});
                                                                                    }}
                                                                                    onBlur={(event,editor)=>{
                                                                                        //event.preventDefault();
                                                                                        console.log('Focus.',editor);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="row g-3">
                                                                                <button className="btn btn-success" onClick={generateBioX}>{"generate bio"}</button>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Image"}</u></p>
                                                                                <input type="text" value={imageY} className="form-control bg-light border-1" placeholder="Image" style={{borderColor: 'black'}} onChange={handleImageUpdate}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Latitude"}</u></p>
                                                                                <input type="text" value={latitudeY} className="form-control bg-light border-1" placeholder="Latitude" style={{borderColor: 'black'}} onChange={handleLatitudeDoctor}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Longitude"}</u></p>
                                                                                <input type="text" value={longitudeY} className="form-control bg-light border-1" placeholder="Longitude" style={{borderColor: 'black'}} onChange={handleLongitudeDoctor}/>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                {/*<input type="text" value={result.message_quality} placeholder="Learning score" className="form-control bg-light border-1" style={{borderColor: 'black'}}/>*/}
                                                                                <p><u>{"Learning score"}</u></p>
                                                                                <Rating 
                                                                                    name="customized-10" 
                                                                                    //defaultValue={learningScoreY} 
                                                                                    value={learningScoreY}
                                                                                    precision={1}
                                                                                    max={10} 
                                                                                    //readonly 
                                                                                    className="form-control bg-light border-1" 
                                                                                    style={{borderColor: 'black'}} 
                                                                                    onChange={handleLearningScoreDoctor}
                                                                                />
                                                                            </div>
                                                                            <br/>
                                                                            <div className="row g-3">
                                                                                <p><u>{"Bed"}</u></p>
                                                                                <input type="text" value={bedY} className="form-control bg-light border-1" placeholder="Bed" style={{borderColor: 'black'}} onChange={handleBedChangeX}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                
                                                        </>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={handleClose}>
                                                            {"Cancel"}
                                                        </Button>
                                                        <Button autoFocus onClick={saveX}>
                                                            {"Save"}
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                                <Snackbar open={openOriginalEdit} autoHideDuration={6000} onClose={handleOriginalDocClose}>
                                                            <Alert onClose={handleOriginalDocClose} severity="success" sx={{ width: '100%' }}>
                                                                Original doctor data updated successfully!
                                                            </Alert>
                                                </Snackbar>
                                            </Main>
                                        );
                                    case '/category':
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>{"Category"}</h3>
                                                <Button variant="contained" style={{ marginLeft: '68%', marginBottom: '4%'}} 
                                                >Load events</Button>
                                                <Button variant="contained" style={{ marginLeft: '68%', marginBottom: '4%'}} 
                                                >illnesses & conditions</Button>
                                                
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Grid container spacing={{ xs: 3, md: 3}} columns={{ xs: 4, sm: 8, md: 12}} style={{ marginLeft: "170px"}}>

                                                    </Grid>
                                                </Box>
                                            </Main>
                                        );
                                    case '/users':
                                        return(
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>{"Users"}</h3>
                                                <Fab color="success" variant="contained" aria-label="add" style={{ marginLeft: '154%'}} onClick={createCampaign}>
                                                    <AddIcon/>
                                                </Fab>
                                                <div style={{height:400,width:'150%',backgroundColor: 'white', marginLeft: '10%',marginTop:'17%'}}>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            {/*<div className="col-sm-offset-2 col-sm-10">
                                                                <select className="form-control" onChange={chooseDoctor}>
                                                                    <option name={"choose_campaign"}>{"Choose a specialists"}</option>
                                                                    {specialists.map((item)=>{
                                                                        return(
                                                                            <option value={item.id}>{item.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>*/}
                                                        </div>
                                                        <div className="col-sm-3">
                                                            {/*<div className="col-sm-offset-2 col-sm-2">
                                                                <button className="btn btn-success" style={{marginLeft: '-63px'}} onClick={moveCampaign}>{"assign"}</button>
                                                            </div>*/}
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '-11%',marginLeft: '-13%'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <Box sx={{minWidth: 275}}>
                                                                    <Card variant="outlined">{deliveredCampaignCard}</Card>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '-11%', marginLeft: '5%'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <Box sx={{ minWidth: 275}}>
                                                                    <Card variant="outlined">{campaignCard}</Card>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                        <div className="row" style={{height:400,width:'96%',backgroundColor: 'white'}}>
                                                            <DataGrid
                                                                rows={users}
                                                                columns={usersColumns}
                                                                pageSize={5}
                                                                rowsPerPageOptions={[5]}
                                                                checkboxSelection
                                                                onRowClick={(params,event)=>{
                                                                    if(!event.ignore){
                                                                        console.log("push -> /roles/" + params.row.id);
                                                                    }
                                                                }}
                                                            >
                                                            </DataGrid>
                                                        </div>
                                                </div>
                                                <BootstrapDialog
                                                    onClose={handleCreateCampaignClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={createCampaingOpen}
                                                >
                                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCreateCampaignClose}>
                                                        {"Create a campaign"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <div className="col-lg-36" style={{marginTop: '-100px'}}>
                                                            <div className="bg-white rounded p-5 m-5 mb-0">
                                                                <br/>
                                                                <div className="row g-3">
                                                                    <p><u>{"Subject"}</u></p>
                                                                    <input type="text" className="form-control bg-light border-1" placeholder="Enter subject" style={{borderColor: 'black'}} id="subject" onChange={(e) => setSubject(e.target.value)}/>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3">
                                                                    <p><u>{"Message"}</u></p>
                                                                    <CKEditor
                                                                        className="form-control bg-light border-1"
                                                                        style={{borderColor: 'black'}}
                                                                        placeholder="Enter message"
                                                                        id="message"
                                                                        editor={ ClassicEditor }
                                                                        data={message}
                                                                        onReady={ editor => {
                                                                            setMessage(editor.getData());
                                                                        }}
                                                                        onChange={(event,editor)=>{
                                                                            //event.preventDefault();
                                                                            setMessage(editor.getData());
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={handleCreateCampaignClose}>
                                                            {"Cancel"}
                                                        </Button>
                                                        <Button autoFocus onClick={saveCampaign}>
                                                            {"Save"}
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                                <Snackbar open={openCampignSnackbar} autoHideDuration={6000} onClose={createCampaignSnackbarClose}>
                                                      <Alert onClose={createCampaignSnackbarClose} severity="success" sx={{ width: '100%'}}>
                                                            {"Campaign created successfully!"}
                                                      </Alert>
                                                </Snackbar>
                                            </Main>
                        
                                        );
                                    case '/campaign':
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>Campaign</h3>
                                                                                               
                                            </Main>
                                        );
                                    case '/keywords':
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>Keywords</h3>
                                                {/*<Fab color="success" variant="contained" aria-label="add" style={{ marginLeft: '154%'}} onClick={generateKeywords}>
                                                    <AddIcon />
                                                </Fab>*/}
                                                <div style={{ height: 400, width: '107%', backgroundColor: 'white',marginLeft:'30%',marginTop:'3%'}}>
                                                    <div className="row">
                                                            <div className="col-sm-3" style={{marginTop: '0%', marginLeft: '75%'}}>
                                                                <div className="col-sm-offset-2 col-sm-10">
                                                                    <Box sx={{ minWidth: 275}}>
                                                                        <Card variant="outlined">{keywordCard}</Card>
                                                                    </Box>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <br/>                
                                                    <DataGrid
                                                            rows={googleKeywords}
                                                            columns={keywordsColumns}
                                                            pageSize={5}
                                                            rowsPerPageOptions={[5]}
                                                            checkboxSelection
                                                            onRowClick={(params, event) => {
                                                                if(!event.ignore){
                                                                    console.log("push -> /roles/" + params.row.id);
                                                                }
                                                            }}
                                                        />
                                                </div>
                                                {/*<Fab color="success" variant="contained" aria-label="add" style={{ 'marginTop': '20px'}} 
                                                    onClick={scrapeKeywords}>
                                                    <AutorenewIcon />
                                                </Fab>*/}
                                                
                                                <Dialog
                                                    fullScreen
                                                    open={open}
                                                    aria-label="close"                                                                            
                                                    >
                                                    <AppBar sx={{ position: 'relative' }}>
                                                        <Toolbar>
                                                            <IconButton
                                                            edge="start"
                                                            color="inherit"
                                                            onClick={handleClose}
                                                            aria-label="close"
                                                            >
                                                            <CloseIcon />
                                                            </IconButton>
                                                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                                                Link keywords to an article
                                                            </Typography>
                                                            <Button autoFocus color="inherit" onClick={handleSave}>
                                                                save
                                                            </Button>
                                                        </Toolbar>
                                                     </AppBar>
                                                     <List>
                                                        <ListItem button>
                                                            <Box
                                                                    sx={{
                                                                        width: 500,
                                                                        maxWidth: '100%',
                                                                    }}
                                                                    >
                                                                    <TextField fullWidth label="Enter keyword" id="txtKeyword" onChange={handleKeyword}/>
                                                            </Box>                                                        
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem button>
                                                            {/*<Box
                                                                    sx={{
                                                                        width: 500,
                                                                        maxWidth: '100%',
                                                                    }}
                                                                    >
                                                                    <TextField fullWidth label="Enter website" id="txtLink" onChange={handleWebsite} />
                                                            </Box>*/} 
                                                            <Box 
                                                                sx={{
                                                                    width: 500,
                                                                    maxWidth: '100%'
                                                                }}
                                                              >
                                                            </Box>
                                                        </ListItem>
                                                        <Divider/>
                                                        <ListItem button>
                                                            <div style={{height:400,width:'150%',backgroundColor:'white',marginLeft:'10%',marginTop:'17%'}}>
                                                                    <DataGrid
                                                                        rows={keywords}
                                                                        columns={postColumns}
                                                                        pageSize={5}
                                                                        rowsPerPageOptions={[5]}
                                                                        checkboxSelection
                                                                        onRowClick={(params,event)=>{
                                                                            if(!event.ignore){
                                                                                console.log("push -> /roles/" + params.row.id);
                                                                            }
                                                                        }}
                                                                    />
                                                            </div>
                                                        </ListItem>
                                                        </List>
                                                </Dialog>
                                                <BootstrapDialog
                                                    onClose={handleCloseKeyword}
                                                    open={openKeyword}
                                                >
                                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseKeyword}>
                                                        {"View and edit item"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <>
                                                            <div className="col-lg-36" style={{marginTop:'-100px'}}>
                                                            <div className="bg-white rounded p-5 m-5 mb-0">
                                                                <div className="row g-3">
                                                                    <div className="form-group">
                                                                        <p style={{marginLeft: '-23%'}}><u>{"Id"}</u></p>
                                                                        <input type="text" value={xId} className="form-control bg-light border-1" placeholder="identity" style={{borderColor: 'black', marginLeft: '-90px',width: '145%'}}/>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3">
                                                                    <div className="form-group">
                                                                        <p style={{marginLeft: '-23%'}}><u>{"Keyword"}</u></p>
                                                                        <input type="text" value={dKeyword} className="form-control bg-light border-1" placeholder="keyword" style={{borderColor: 'black', marginLeft: '-90px',width: '145%'}} onChange={handleKeywordChange}/>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3">
                                                                    <div className="form-group">
                                                                        <p style={{marginLeft: '-23%'}}><u>{"Low range"}</u></p>
                                                                        <input type="text" value={dLowRange} className="form-control bg-light border-1" placeholder="low range" style={{borderColor: 'black', marginLeft: '-90px',width: '145%'}} onChange={handleLowRange}/>
                                                                    </div>                                                       
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3">
                                                                    <div className="form-group">
                                                                        <p style={{marginLeft: '-23%'}}><u>{"High range"}</u></p>
                                                                        <input type="text" value={dHighRange} className="form-control bg-light border-1" placeholder="high range" style={{borderColor: 'black', marginLeft: '-90px',width: '145%'}} onChange={handleHighRange}/>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div className="row g-3">
                                                                    <div className="form-group">
                                                                        <p style={{marginLeft: '-23%'}}><u>{"Assign a keyword to an article"}</u></p>
                                                                        <select className="form-control" id="keywordX" style={{borderColor: 'black', marginLeft: '-90px',width: '145%'}} onChange={handleSlugChangeKeyword}>
                                                                            {keywordX.map((keywordXY)=>{
                                                                                return(
                                                                                    <option value={keywordXY.slug}>{keywordXY.slug}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {/*<div className="row g-3">
                                                                    <div className="form-group">
                                                                        <input type="text" value={dSlug} className="form-control bg-light border-1" placeholder="slug" style={{borderColor: 'black', marginLeft: '-90px',width: '269%'}} onChange={handleSlugChangeKeyword}/>
                                                                    </div>
                                                                </div>*/}
                                                                <br/>
                                                            </div>
                                                        </div>

                                                            
                                                        </>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus>
                                                            {"Cancel"}
                                                        </Button>
                                                        <Button autoFocus onClick={saveWords}>
                                                            {"Save"}
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                            </Main>
                                        );
                                    case '/tags':
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>Tags</h3>
                                            </Main>
                                        );
                                    case '/contacts':
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>Contacts</h3>
                                                <br/><br/>
                                                <div className="row">
                                                    <div className="col-sm-4" style={{marginLeft: '118%'}}>
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                             <Box sx={{minWidth: 275}}>
                                                                <Card variant="outlined">
                                                                    {contactsCard}
                                                                </Card>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4" style={{marginLeft: '75%',marginTop:'-16%'}}>
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                             <Box sx={{minWidth: 275}}>
                                                                <Card variant="outlined">
                                                                    {newsletterCard}
                                                                </Card>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <label htmlFor="contacts">
                                                                    {"Action type:"}
                                                                </label>
                                                                <select className="form-control">
                                                                    <option name="choose_contacts" value={"choose_a_action"}>{"Choose a action"}</option>
                                                                    <option name="scrape_contacts" value={"scrape_contacts"}>{"Scrape contacts"}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '0px'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <label htmlFor="sel3"></label>
                                                                    <button className="btn btn-success" style={{marginLeft: '-46px',marginTop: '24px'}}>{"Submit"}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                <br/>
                                                <div style={{height:400,width:'110%',backgroundColor:'white',marginLeft:'0%',marginTop:'3%'}}>
                                                    <br/>                                                   
                                                    <DataGrid
                                                            rows={contacts}
                                                            columns={contactsColumns}
                                                            pageSize={5}
                                                            rowsPerPageOptions={[5]}
                                                            checkboxSelection
                                                        />
                                                </div>
                                                <DrawerHeader/>
                                                <h3>{"Newsletter"}</h3>
                                                <br/><br/>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <label htmlFor="contacts">
                                                                    {"Action type:"}
                                                            </label>
                                                            <select className="form-control">
                                                                <option name="choose_a_action">{"Choose a action"}</option>
                                                                <option name="scrape_newsletter">{"Scrape newsletter"}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <button className="btn btn-success" style={{marginLeft: '-46px',marginTop: '24px'}}>{"Submit"}</button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div style={{height:400,width:'54%',backgroundColor:'white',marginLeft:'0%',marginTop:'3%'}}>
                                                    <br/>
                                                    <DataGrid
                                                        rows={newsletter}
                                                        columns={newsletterColumns}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                    />
                                                </div>
                                                <BootstrapDialog
                                                    onClose={handleMembershipClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={membershipDialog}
                                                >
                                                    <BootstrapDialogTitle>
                                                        {"View and edit item"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <>
                                                            <div className="col-lg-36" style={{marginTop:'-100px'}}>
                                                                <div className="bg-whiterounded p-5 m-5 mb-0">
                                                                    <div className="row g-3">
                                                                        <p><u>{"Id:"}</u></p>
                                                                        <input type="text" className="form-control bg-light border-1" placeholder="id" style={{borderColor: 'black'}} value={membershipId}
                                                                            id="membershipId" readOnly/>
                                                                    </div>
                                                                    <div className="row g-3">
                                                                        <p><u>{"Email:"}</u></p>
                                                                        <input type="text" className="form-control bg-light border-1" placeholder="email" style={{borderColor:'black'}} value={membershipEmail}
                                                                        id="membershipEmail"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClose={handleMembershipClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button autoFocus onClick={updateNewsletter}>
                                                            Save
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                                <BootstrapDialog
                                                    onClose={handleContactEditClose}
                                                    aria-labelledby='customized-dialog-title'
                                                    open={openContactsEdit}
                                                >
                                                    <BootstrapDialogTitle>
                                                        {"View and edit item"}
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <>
                                                            <div className="col-lg-36" style={{marginTop:'-100px'}}>
                                                                <div className="bg-whiterounded p-5 m-5 mb-0">
                                                                    <div className="row g-3">
                                                                        <p><u>{"Id:"}</u></p>
                                                                        <input type="text" value={contactId} className="form-control bg-light border-1" placeholder="id" style={{borderColor: 'black'}} id="contactusId" readOnly/>
                                                                     </div>
                                                                     <div className="row g-3">
                                                                        <p><u>{"Subject:"}</u></p>
                                                                        <input type="text" value={contactSubject} className="form-control bg-light border-1" placeholder="Subject" style={{borderColor: 'black'}} id="contactusSubject" onChange={subjectFun}/>
                                                                     </div>
                                                                     <div className="row g-3">
                                                                        <p><u>{"Name:"}</u></p>
                                                                        <input type="text" value={contactName} className="form-control bg-light border-1" placeholder="Name" style={{borderColor: 'black'}} id="contactusName" onChange={nameFun}/>
                                                                     </div>
                                                                     <div className="row g-3">
                                                                        <p><u>{"Email:"}</u></p>
                                                                        <input type="text" value={contactEmail} className="form-control bg-light border-1" placeholder="Email" style={{borderColor: 'black'}} id="contactusEmail" onChange={emailFun}/>
                                                                     </div>
                                                                     <div className="row g-3">
                                                                        <p><u>{"Message:"}</u></p>
                                                                        <CKEditor
                                                                            className="form-control bg-light border-1"
                                                                            style={{borderColor: 'black'}}
                                                                            id="contactusMessage"
                                                                            editor={ClassicEditor}
                                                                            data={contactMessage}
                                                                            onReady={editor=>{
                                                                                setContactMessage(editor.getData());
                                                                            }}
                                                                            onChange={(event,editor)=>{
                                                                                setContactMessage(editor.getData());
                                                                                console.log("Focus",editor)
                                                                            }}
                                                                        />
                                                                     </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={handleContactEditClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button autoFocus onClick={updateContactInfo}>
                                                            Save
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                                <Snackbar 
                                                    open={contactInfoSnackbar}
                                                    autoHideDuration={6000}
                                                    onClose={handleContactInfoClose}
                                                    >
                                                    <Alert
                                                        onClose={handleContactInfoClose}
                                                        severity="success"
                                                        sx={{width:'100%'}}
                                                    >
                                                        {contactInfoMessage}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar
                                                    open={membershipSnackbar}
                                                    autoHideDuration={6000}
                                                    onClose={handleMembershipSnackbarClose}
                                                >
                                                    <Alert
                                                        onClose={handleMembershipSnackbarClose}
                                                        severity="success"
                                                        sx={{width:'100%'}}
                                                    >
                                                        {membershipMessage}
                                                    </Alert>
                                                </Snackbar>
                                            </Main>
                                        );
                                    case '/notifications':
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>{"Notifications"}</h3>
                                                {/*<Button variant="contained" style={{ marginLeft: '68%', marginBottom: '4%'}} onClick={loadEmails}>Set emails intervals</Button>*/}

                                                <br/>
                                                <div className="row" style={{borderColor: 'black'}}>
                                                  {/*  <div className="col-sm-3">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <label htmlFor="sel1">{"Date:"}</label>
                                                                <select className="form-control" id="date-1">
                                                                    <option name="no_date">{"No date"}</option>
                                                                    <option className="h5 bg-light rounded py-5 px-5 mb-3">{"department 1"}</option>
                                                                    <option className="h5 bg-light rounded py-5 px-5 mb-3">{"department 2"}</option>
                                                                    <option className="h5 bg-light rounded py-5 px-5 mb-3">{"department 3"}</option>
                                                                </select>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3" style={{marginTop: '24px'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <label htmlFor="sel3"></label>
                                                                    <button className="btn btn-success" style={{marginLeft: '-46px'}}>{"export"}</button>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <div className="col-sm-3" style={{marginTop:'5%',marginLeft: '5%'}}>
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <Box sx={{minWidth: 275}}>
                                                                <Card variant="outlined">{logsCard}</Card>
                                                            </Box>
                                                        </div>
                                                    </div>*/}
                                                    <br/><br/>
                                                    <div className="col-sm-3" style={{marginTop: '-16%', marginLeft: '99%'}}>
                                                            <Box sx={{minWidth: 275}}>
                                                                <Card variant="outlined">{notificationsCard}</Card>
                                                            </Box>
                                                    </div>
                                                </div>
                                                <br/>
                                                <br/>
                                                <div className="row" style={{borderColor: 'black'}}>
                                                    <div className="col-sm-3">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <label htmlFor="sel1">{"Section:"}</label>
                                                                <select className="form-control" id="date-1" onChange={getNotificationSection}>
                                                                    <option name="no_date">{"No section:"}</option>
                                                                    <option className="h5 bg-light rounded py-5 px-5 mb-3" value={'posts'}>{"Posts"}</option>
                                                                    <option className="h5 bg-light rounded py-5 px-5 mb-3" value={'doctor'}>{"Doctor"}</option>
                                                                    <option className="h5 bg-light rounded py-5 px-5 mb-3" value={'appointments'}>{"Appointments"}</option>
                                                                    <option className="h5 bg-light rounded py-5 px-5 mb-3" value={'courses'}>{"Courses"}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3" style={{marginTop: '24px'}}>
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <div className="form-group">
                                                                <label htmlFor="sel3"></label>
                                                                <button className="btn btn-success" style={{marginLeft: '-46px'}} onClick={pushNotifications}>{"push notifications"}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ height: 400, width: '125%',marginLeft:'17%',marginTop:'3%',backgroundColor:'white'}}>
                                                    <DataGrid
                                                        rows={notifications}
                                                        columns={notificationColumns}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                    />
                                                </div>
                                                {/*<Fab color="primary" variant="contained" aria-label="add" style={{ 'marginTop': '20px'}}
                                                    onClick={emailCampaign}>
                                                    <EmailIcon />
                                                </Fab>*/}
                                                <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={snackBarClose}>
                                                    <Alert onClose={snackBarClose} severity="success" sx={{ width: '100%'}}>
                                                        Process is a success!
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={openNotificationSnackbar} autoHideDuration={6000} onClose={snackBarNotificationClose}>
                                                    <Alert onClose={snackBarNotificationClose} severity="success" sx={{ width: '100%'}}>
                                                        {notificationMessage}
                                                    </Alert>
                                                </Snackbar>
                                            </Main>
                                        );
                                    case '/learn':
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>{"Learn"}</h3>

                                                <div style={{ height: 400, width: '158%', backgroundColor: 'white', marginLeft: '0%',marginTop:'3%'}}>
                                                    <br/>           
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <label htmlFor="sel1">{"Categories:"}</label>
                                                                    <select className="form-control" id="categories">
                                                                        <option name="no_department">{"No categories"}</option>
                                                                        {lmsCategories.map((item)=>{
                                                                            return(
                                                                                <option className="h5 bg-light rounded py-2 px-2 mb-3" value={item.name}>{item.name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginLeft: '-53px',marginLeft: '-60px'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <label htmlFor="sel2">{"Sites:"}</label>
                                                                    <select className="form-control" id="sites">
                                                                        <option name="no_site">{"No site"}</option>
                                                                        <option name="site1">{"Site 1"}</option>
                                                                        <option name="site2">{"Site 2"}</option>
                                                                        <option name="site3">{"Site 3"}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '24px'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <div className="form-group">
                                                                    <label htmlFor="sel3"></label>
                                                                    <button className="btn btn-success">{"Get data"}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3" style={{marginTop: '24px',marginLeft: '-76px'}}>
                                                            <div className="col-sm-offset-2 col-sm-10">
                                                                <Box sx={{ minWidth: 275}}>
                                                                    <Card variant="outlined">{coursesCard}</Card>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                        <div style={{height:400, width:'80%', backgroundColor: 'white',marginLeft:'10%',marginTop:'5%'}}>
                                                              <DataGrid
                                                                rows={courses}
                                                                columns={coursesColumns}
                                                                pageSize={5}
                                                                rowsPerPageOptions={[5]}
                                                                checkboxSelection
                                                                onRowClick={(params,event)=>{
                                                                    if(!event.ignore){
                                                                        console.log("push -> /roles/" + params.row.id);

                                                                        setOpenX(true);
                                                                    }
                                                                }}
                                                              />

                                                        <BootstrapDialog
                                                            onClose={handleCloseXYX}
                                                            aria-labelledby="customized-dialog-title"
                                                            open={openXYX}
                                                        >
                                                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseXYX}>
                                                                    {"View and edit item"}
                                                            </BootstrapDialogTitle>
                                                            <DialogContent dividers>
                                                                <>
                                                                    <div className="col-lg-36" style={{marginTop: '-100px'}}>
                                                                        <div className="bg-white rounded p-5 m-5 mb-0">
                                                                            <div className="row g-3" style={{marginLeft: '-31%',width: '160%'}}>
                                                                                <p><u>{"Id"}</u></p>
                                                                                <input type="text" value={logId} className="form-control bg-light border-1" placeholder="id" style={{borderColor: 'black'}} id="" readonly/>
                                                                            </div>
                                                                            <div className="row g-3" style={{marginLeft: '-31%',width: '160%'}}>
                                                                                <p><u>{"Title"}</u></p>
                                                                                <input type="text" value={logTitle} className="form-control bg-light border-1" placeholder="Title" style={{borderColor: 'black'}} onChange={handleCourseTitle}/>
                                                                            </div>
                                                                            <div className="row g-3" style={{marginLeft: '-31%',width: '160%'}}>
                                                                                <p><u>{"Course"}</u></p>
                                                                                <input type="text" value={logCourse} className="form-control bg-light border-1" placeholder="Course" style={{borderColor: 'black'}} onChange={handleCourse}/>
                                                                            </div>
                                                                            <div className="row g-3" style={{marginLeft: '-31%',width: '160%'}}>
                                                                                <p><u>{"Instructor"}</u></p>
                                                                                <input type="text" value={logInstructor} className="form-control bg-light border-1" placeholder="Instructor" style={{borderColor: 'black'}} onChange={handleLogInstructor}/>
                                                                            </div>
                                                                            <div className="row g-3" style={{marginLeft: '-31%',width: '160%'}}>
                                                                                <p><u>{"Duration"}</u></p>
                                                                                <input type="text" value={logDuration} className="form-control bg-light border-1" placeholder="Duration" style={{borderColor: 'black'}} onChange={handleLogDuration}/>
                                                                            </div>
                                                                            <div className="row g-3" style={{marginLeft: '-31%',width: '160%'}}>
                                                                                <p><u>{"Students"}</u></p>
                                                                                <input type="text" value={logStudent} className="form-control bg-light border-1" placeholder="Students" style={{borderColor: 'black'}} onChange={handleLogStudent}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button autoFocus onClose={handleCloseXYX}>
                                                                    {"Cancel"}
                                                                </Button>
                                                                <Button autoFocus onClick={updateCourse}>
                                                                    {"Save"}
                                                                </Button>
                                                            </DialogActions>
                                                        </BootstrapDialog>
                                                        <Snackbar open={openY} autoHideDuration={6000} onClose={handleCourseClose}>
                                                            <Alert onClose={handleCourseClose} severity="success" sx={{ width: '100%'}}>
                                                                Course updated successfully!
                                                            </Alert>
                                                        </Snackbar>
                                                        </div>
                                                      
                                                    </div>
                                                </div>
                                            </Main>
                                        );
                                    case '/hereos':
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>Hereos</h3>
                                            </Main>
                                        );
                                    {/*case '/patients':
                                        return (
                                            <Main open={open}>
                                                <DrawerHeader/>
                                                <h3>Events</h3>
                                                <Fab color="primary" variant="contained" aria-label="add" style={{ marginLeft: '154%'}} onClick={handleClickOpen}>
                                                    <AddIcon />
                                                </Fab>
                                                <div style={{ height: 400, width: '129%', backgroundColor: 'white',marginLeft:'19%',marginTop:'3%'}}>
                                                    <DataGrid
                                                        rows={patientRows}
                                                        columns={partientsColumns}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                    />
                                                </div>
                                                <Fab color="primary" variant="contained" aria-label="add" style={{ 'marginTop': '20px','marginLeft':'70px'}} 
                                                    onClick={scrapeKeywords}>
                                                    <AutorenewIcon />
                                                </Fab>
                                                <Dialog
                                                    fullScreen
                                                    open={open}
                                                    aria-label="close"
                                                                                                                
                                                    >
                                                    <AppBar sx={{ position: 'relative' }}>
                                                        <Toolbar>
                                                            <IconButton
                                                            edge="start"
                                                            color="inherit"
                                                            onClick={handleClose}
                                                            aria-label="close"
                                                            >
                                                            <CloseIcon />
                                                            </IconButton>
                                                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                                                Extract patient profiles from hospitals
                                                            </Typography>
                                                            <Button autoFocus color="inherit" onClick={handlePatientSave}>
                                                                save
                                                            </Button>
                                                        </Toolbar>
                                                     </AppBar>
                                                     <List>
                                                        <ListItem button>
                                                            <Box
                                                                    sx={{
                                                                        width: 500,
                                                                        maxWidth: '100%',
                                                                    }}
                                                                    >
                                                                    <TextField fullWidth label="Enter keyword" id="txtKeyword" onChange={handleKeyword}/>
                                                            </Box>                                                        
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem button>
                                                            <Box
                                                                    sx={{
                                                                        width: 500,
                                                                        maxWidth: '100%',
                                                                    }}
                                                                    >
                                                                    <TextField fullWidth label="Enter website" id="txtLink" onChange={handleWebsite} />
                                                            </Box> 
                                                        </ListItem>
                                                        <Divider/>
                                                        <ListItem button>
                                                            <Box 
                                                                sx={{
                                                                    width: 500,
                                                                    maxWidth: '100%'
                                                                }}
                                                              >
                                                              <Select 
                                                                   fullWidth
                                                                   labelId="slug-select"
                                                                   id="slug-select"
                                                                   value={slug}
                                                                   onChange={handleChange}
                                                                   label="Slug"
                                                                >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                <MenuItem value="superbets">
                                                                     Superbets
                                                                </MenuItem>
                                                                <MenuItem value="betway">
                                                                     Betway
                                                                </MenuItem>
                                                                <MenuItem value="world_sports_betting">
                                                                     World Sports Betting
                                                                </MenuItem>                                                                
                                                               </Select>
                                                            </Box>
                                                        </ListItem>
                                                        </List>
                                                </Dialog>
                                            </Main>
                                        )*/}
                                }
                            })()

                     }

                  </Box>
                </Container>
            </div>
            
    );
}

export default Sidebar;