import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import './css/main.css';
import Topbar from './../main/Topbar';
import Footer from './Footer';
import DB from '../../db.js';
import { Contacts } from '@mui/icons-material';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import "./css/slidingtext.css";

const Alert = React.forwardRef(function Alert(props, ref){
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Contact = () => {
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [openContactSnackbar, setOpenContactSnackbar ] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);    

    function backendContactUs(objContact){
        fetch('http://localhost:8080/contact-us',{
            headers:{
                'Content-Type':'application/json'
            },
            method:'POST',
            body:JSON.stringify(objContact)
        }).then((res)=>{
            if(res.ok){
                console.log('contact creation a success!');
            }else if(!res.ok){
                console.log('contact creation not a success!');
            }
        }).catch((err)=>{
            console.log(err);
        });
    }

    const submitContact = async (e) => {
        e.preventDefault();
        const db = new DB('family_hero');
        const data = [];

        setTimeout(()=>{

            if(name == ''){
                setErrorMessage("Your name is not filled!");
                setOpenErrorSnackbar(true);
            }else if(email == ''){
                setErrorMessage("Your email is not filled!");
                setOpenErrorSnackbar(true);
            }else if(subject == ''){
                setErrorMessage("Subject is not filled!");
                setOpenErrorSnackbar(true);
            }else if(message == ''){
                setErrorMessage("Message is not filled!");
                setOpenErrorSnackbar(true);
            }else if((name != '') && (email != '') && (subject != '') && (message != '')){
                var contacts = [];
                const newItem = {
                    name:name,
                    email:email,
                    subject:subject,
                    message:message,
                    type:"contact",
                    createdAt: new Date().toString(),
                    updatedAt: new Date().toString()
                };
                contacts.push(newItem);
                db.createContact(contacts);
                backendContactUs(newItem);
                setContactMessage('Contact details have been submitted successfully!');
                setOpenContactSnackbar(true);

                document.getElementById('name').value = '';
                document.getElementById('email').value = '';
                document.getElementById('subject').value = '';
                document.getElementById('message').value = '';
            }
        },400);        
    }

    

    const handleErrorClose = () => {
        setOpenErrorSnackbar(false);
    }

    const handleClose = () => {
        setOpenContactSnackbar(false);
    }

    const setTokenFunc = (getToken) => {
        setToken(getToken);
    }


    return(
        
        <div>
            <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{"Contact us"}</title>
                    <meta name="author" content="Sanjay Kumar"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                    <link rel="canonical" href="https://www.worldofhealthcare.co.za/contact"/>
            </Helmet>
            <Topbar/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs>
                </Grid>
                <Grid item xs={20}>
                    <br/><br/><br/><br/>
                    <div className="container-fluid pt-5">
                        <div className="container">
                            <div className="text-center mx-auto mb-5" style={{maxWidth:'500px'}}>
                                <h2 className="d-inline-block text-primary text-uppercase border-bottom border-5">{"Any Questions?"}</h2>
                                <h3 className="display-4">{"Please Feel Free To Contact Us"}</h3>
                            </div>
                            <div className="row g-5 mb-5">
                                <div className="col-lg-4">
                                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:'200px'}}>
                                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:'100px',height:'70px',transform:'rotate(-15deg)'}}>
                                            <i className="fa fa-2x fa-envelope-open text-white" style={{transform:'rotate(15deg)'}}></i>
                                        </div>
                                        <h2 className="mb-0">{"Granada Centre, 3 Corlett Dr, Illovo, 2196, Andiccio24"}</h2>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:'200px'}}>
                                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:'100px',height:'70px',transform:'rotate(-15deg)'}}>
                                            <i className="fa fa-2x fa-envelope-open text-white" style={{transform:'rotate(15deg)'}}></i>
                                        </div>
                                        <h3 className="mb-0">{"+27 78 834 6604"}</h3>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:'200px'}}>
                                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:'100px',height:'70px',transform:'rotate(-15deg)'}}>
                                            <i className="fa fa-2x fa-envelope-open text-white" style={{transform:'rotate(15deg)'}}></i>
                                        </div>
                                        <h4 className="mb-0">{"lesiba.less@gmail.com"}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" style={{height:'500px'}}>
                                    <div className="position-relative h-100">
                                        <iframe className="position-relative w-100 h-100"
                                            src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"}
                                            tabIndex="0"
                                            aria-hidden="false"
                                            style={{border:0}}
                                            allowFullScreen=""
                                            ></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center position-relative" style={{marginTop:'-200px',zIndex:1}}>
                                <div className="col-lg-8">
                                    <div className="bg-white rounded p-5 m-5 mb-0">
                                        <form>
                                            <div className="row g-3">
                                                <div className="col-12 col-sm-6">
                                                    <input type="text" className="form-control bg-light border-0 medium-font" id="name" placeholder="Your Name" style={{height:'55px'}} onChange={(e) => setName(e.target.value)}/>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <input type="email" className="form-control bg-light border-0 medium-font" id="email" placeholder="Your Email" style={{height:'55px'}} onChange={(e) => setEmail(e.target.value)}/>
                                                </div>
                                                <div className="col-12">
                                                    <input type="text" className="form-control bg-light border-0 medium-font" id="subject" placeholder="Subject" style={{height:'55px'}} onChange={(e) => setSubject(e.target.value)}/>
                                                </div>
                                                <div className="col-12">
                                                    <textarea className="form-control bg-light border-0 medium-font" rows="5" id="message" placeholder="Message" onChange={(e) => setMessage(e.target.value)}></textarea>
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn btn-dark w-100 py-3 medium-font" type="submit" onClick={submitContact}>{"Send Message"}</button>
                                                </div>
                                            </div>
                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTA_KEY}>
                        <GoogleReCaptcha
                            className="google-recaptcha-custom-class"
                            onVerify={setToken}
                            refreshReCaptcha={refreshReCaptcha}
                        />
                    </GoogleReCaptchaProvider>
                </Grid>
                <Grid item xs>
                </Grid>
            </Grid>
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%'}}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openContactSnackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%'}}>
                    {contactMessage}
                </Alert>
            </Snackbar>
        <Footer/>
        </div>
    )
}

export default Contact; 