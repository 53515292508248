import React from 'react';
import Topbar from './Topbar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const CommentReplies = () => {
    return(
        <div>
            <Topbar/>
            <div>
                <h3>Comment Replies</h3>
            </div>
            {/*<AppBar position="fixed" 
                    variant="outlined" 
                    color="primary" 
                    sx={{ top: 'auto', bottom: 0, fontSize: '3rem' }} 
                    >
                <Toolbar
                    style={{ minHeight: '154px', fontSize: '1rem'}}
                >
                    <div>
                            <IconButton color="inherit">
                                <Link to={"/contact"} style={{textDecoration: 'none'}}>
                                    <Typography
                                        style={{color:"#ffffff"}}
                                    >
                                        Contact |
                                    </Typography>
                                </Link>
                            </IconButton>
                            <IconButton color="inherit">
                                <Link to={"/careers"} style={{textDecoration: 'none'}}>
                                    <Typography
                                    style={{color:"#ffffff"}}
                                        >
                                     Careers |
                                    </Typography>
                                </Link>
                                 
                            </IconButton>
                            <IconButton color="inherit">
                                <Link to={"#"} style={{textDecoration: 'none'}}>
                                    <Typography
                                    style={{color:"#ffffff"}}
                                    >
                                        Sitemap |
                                    </Typography>
                                </Link>
                            </IconButton>
                            <IconButton color="inherit">
                                <Link to={"/faq"} style={{textDecoration: 'none'}}>
                                    <Typography
                                    style={{color:"#ffffff"}}
                                        >
                                     Frequently asked questions |
                                    </Typography>
                                </Link>
                                 
                            </IconButton>
                    </div>
                    <div>
                        <Typography paragraph>
                            - © 2022 Family heroes ZA. <br/>
                            - Granada Centre, 3 Corlett Dr, Illovo, Johannesburg, 2196, Andiccio24 🍕<br/>
                            - Lesiba@tsatsile.co.za <br/>
                            - Developer
                        </Typography>
                    </div>

                </Toolbar>
            </AppBar>*/}
        </div>
    )
}

export default CommentReplies;