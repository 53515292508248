import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { Badge } from '@mui/material';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import DB from '../../db.js';
import isoLanguages from './languages/ISO-639-1-language.json';
import useGeolocation from './useGeolocation';
import useGeolocationIPData from './useGeolocationIPData';
import './css/slidingtext.css';

const Topbar = () => {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState(null);
    const { languages, timezone } = useGeolocation();
    const { ip_ipdata, languages_ipdata, city_ipdata, longitude_ipdata, latitude_ipdata, time_zone_ipdata, country_name_ipdata } = useGeolocationIPData();

    //let matchedLanguages = []; // Global array to store matched languages
    const [matchedLanguages, setMatchedLanguages] = useState([]);
    const [continent, setContinent] = useState('');

    let arr = "";
    let arrIpdata = [];  // Initialize arrIpdata as an arry

    //console.log(languages_ipdata);

    useEffect(() => {
        if ( languages && languages.trim() !== "" ) {
            // Check if languages has content and is not an empty string
            arr = languages.split(',');
            //console.log(arr);
        } else {
            // Check if languages has content and is not an empty string
            if (languages_ipdata && languages_ipdata.length > 0) {
                languages_ipdata.map((lang_ipdata) => {
                    //console.log(lang_ipdata.code); // Optional: Log each language
                    arrIpdata.push(lang_ipdata.code); // Add each language to the arrIpdata array
                })
            }
        }
    },[languages, languages_ipdata]);

    useEffect(() => {
        if (typeof timezone !== 'undefined') {
          const extractedContinent = timezone.split('/')[0];
          //sessionStorage.setItem('timezone', timezone);
          setContinent(extractedContinent);
        } else if (typeof timezone === 'undefined') {
            if (typeof time_zone_ipdata !== 'undefined') {
                const extractedContinent = time_zone_ipdata.name.split('/')[0];
                //sessionStorage.setItem('timezone', time_zone_ipdata);
                setContinent(extractedContinent);
            }
        }
    }, [timezone, time_zone_ipdata]); // This ensures the effect runs only when 'timezone' changes

    const location = useLocation();
    const pathname = location.pathname;
    const indexie = pathname.lastIndexOf('/');

    const name = pathname.substring(indexie + 1).toLowerCase();
    var cl = document.getElementsByClassName('nav-item');

    if(name == ""){
      if(cl.length > 0){
          cl[0].classList.add('active');
      }
    }else if(name == "about"){
      if(cl.length > 0){
          cl[1].classList.add('active');
      }
    }else if(name == "find-a-doctor"){
      if(cl.length > 0){
          cl[2].classList.add('active');
      }
    }else if(name == "appointment"){
      if(cl.length > 0){
          cl[3].classList.add('active');
      }
    }else if(name == "view-courses"){
      if(cl.length > 0){
          cl[4].classList.add('active');
      }
    }else if(name == "contact"){
      if(cl.length > 0){
          cl[5].classList.add('active');
      }
    }

    var indexOfAppointment = pathname.indexOf("appointment");
    var indexOfAppointmentDetails = pathname.indexOf("appointment-details");
    
    if((indexOfAppointment > -1) || (indexOfAppointmentDetails > -1)){
        if(cl.length > 0){
            cl[3].classList.add('active');
        }
    }

    var indexOfSpecialist = pathname.search(name);
    if((indexOfAppointmentDetails <= -1) && (indexOfSpecialist >= 1) && (indexOfAppointment >= 1)){
        if(cl.length > 0){
            cl[3].classList.add('active');
        }
    }

    if((indexOfAppointmentDetails <= -1) && (indexOfSpecialist >= 1) && (indexOfAppointment <= -1)){
        if(cl.length > 0){
            cl[2].classList.add('active');
        }
    }

    var indexOfFacilityDetails = pathname.indexOf("facility");
    var indexOfMedicalServices = pathname.indexOf("medical-services");
    var indexOfLearning = pathname.indexOf("learning");

    if((indexOfFacilityDetails > -1) || (indexOfMedicalServices > -1) || (indexOfLearning > -1)){
        if(cl.length > 0){
            cl[0].classList.add('active');
        }
    }

    var indexOfCoursesDetails = pathname.indexOf("course-details");
    var indexOfQuiz = pathname.indexOf("take-a-quiz");
    var indexOfCourse = pathname.indexOf("view-courses");

    if((indexOfCoursesDetails <= -1) && (indexOfSpecialist >= 1) && (indexOfCourse >= 1)){
        if(cl.length > 0){
            cl[2].classList.remove('active');
            cl[4].classList.add('active');
        }
    }
    if((indexOfCoursesDetails >= 1) && (indexOfSpecialist >= 1) && (indexOfCourse >= -1)){
        if(cl.length > 0){
            cl[2].classList.remove('active');
            cl[4].classList.add('active');
        }
    }

    if((indexOfCoursesDetails > -1) || (indexOfQuiz > -1)){
        if(cl.length > 0){
            cl[4].classList.add('active');
        }
    }

    var indexOfContact = pathname.indexOf("contact");
    if((indexOfContact >= 1) && (indexOfSpecialist >= 1)){
        if(cl.length > 0){
            cl[2].classList.remove('active');
            cl[5].classList.add('active');
        }
    }

    var indexOfAbout = pathname.indexOf("about");
    if((indexOfAbout >=1) && (indexOfSpecialist >= 1)){
        if(cl.length > 0){
            cl[2].classList.remove('active');
            cl[1].classList.add('active');
        }
    }
    if((indexOfQuiz >= 1) && (indexOfSpecialist >= 1)){
        if(cl.length > 0){
            cl[2].classList.remove('active');
            cl[4].classList.add('active');
        }
    }


    const [languagesX, setLanguagesX] = useState([]);
    const [localLanguages, setLocalLanguages] = useState([]);
    const [notifications, setNotifications] = useState([]); 
    const [notificationsTotal, setNotificationsTotal] = useState(0);


    const handleClick = (newPlacement) => (event) => {

        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
        
        const db = new DB('family_hero');
        let objNotifications = {};

        db.getNotifications().then((notifications)=>{
            notifications.map((notification)=>{
                if(notification.priority === 'high'){
                  objNotifications.title = notification.title;
                  objNotifications.image = notification.image;
                  objNotifications.about = notification.about;
                  objNotifications.priority = 'medium';
                  objNotifications.user = notification.user;
                  objNotifications.date = notification.date;
                  objNotifications.slug = notification.slug;
                  //objNotifications._rev = notification._doc_id_rev;
                  objNotifications._id = notification._id;
                  
                  //console.log(objNotifications);
                }
            });
        });
        setNotificationsTotal(0); 
    };

    useEffect(()=>{
        const db = new DB('family_hero');
        const notificationsArr = [];
        
        if ( arr.length > 0) {
          const filteredLanguages = isoLanguages.filter((lang) => arr.includes(lang.code));
          setMatchedLanguages(filteredLanguages);
        } else {
          if ( arrIpdata.length > 0) {
            const filteredLanguages = isoLanguages.filter((lang) => arrIpdata.includes(lang.code));
            setMatchedLanguages(filteredLanguages);
          }
        }

        db.getNotifications().then((notifications)=>{
            
            notifications.map((notification)=>{
                if(notification.priority === 'high'){
                    notificationsArr.push(notification); 
                    setNotifications(notificationsArr);
                    setNotificationsTotal(notificationsArr.length);                
                }
            });
            
        });
    },[languages]);


    return(
        <AppBar>
        <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350} style={{marginTop: '23px'}} className="container-fluid py-2 border-bottom d-none d-lg-block bg-white">
              <Paper>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {notifications.map((item,index)=>{
                   return(
                      <a href={"https://worldofhealthcare.co.za/" + item.slug} style={{textDecoration: 'none'}} key={index}>
                        <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar src={item.image}/>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.title}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                               {/*item.user*/}
                              </Typography>
                              {/*item.about + '...'*/} 
                              {
                                (()=>{
                                    if((item.category == "Appointment")){
                                      return(
                                        <>
                                          <br/><i><b><a href="https://worldofhealthcare.co.za/appointment" style={{textDecoration:'none'}}>{item.category}</a></b></i>
                                        </>
                                      )
                                    }else if((item.category == "Posts")){
                                        return(
                                          <>
                                            <br/><i><b><a href="https://worldofhealthcare.co.za/" style={{textDecoration:'none'}}>{item.category}</a></b></i>
                                          </>
                                        )
                                    }else if((item.category == "Public health online courses")){
                                        <>
                                          <br/><i><b>{item.category + ' [23 min ago]'}</b></i>
                                        </>
                                    }
                                })()
                              }
                            </React.Fragment>
                          }
                        />
                        </ListItem>   
                      </a>                     
                   );
                })}
              </List>
              </Paper>
            </Fade>
          )}
        </Popper>

        <div className="container-fluid py-2 border-bottom d-none d-lg-block bg-white">
          <div className="container">
              <div className="row">
                <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                  <div className="d-inline-flex align-items-center">
                    <div className="text-decoration-none text-body pe-2 form-group">
                      <i className="bi bi-telephone me-2"></i>
                      {matchedLanguages.length > 0 ? (
                        <select id="language" 
                                disabled={matchedLanguages.length === 0}
                                style={{
                                    width: '99%',
                                    padding: '10px',
                                    borderRadius: '14px',
                                    border: '1px solid #ccc',
                                    fontSize: '16px',
                                    backgroundColor: matchedLanguages.length === 0 ? '#f5f5f5' : '#fff',
                                    color: matchedLanguages.length === 0 ? '#aaa' : '#000',
                                  }}
                        >
                          {matchedLanguages.map((language, index) => (
                            <option key={index} value={language.code}>
                              {language.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p>No languages available</p>
                      )}
                    </div>
                    <span className="text-body">|</span>
                    <a className="text-decoration-none text-body px-3 large-font" href="#"><i className="bi bi-envelope me-2"></i>lesiba.less@gmail.com</a>
                  </div>
                </div>
                <div className="col-md-6 text-center text-lg-end">
                  <div className="d-inline-flex align-items-center">
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a className="text-body px-2" href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div className="container-fluid sticky-top bg-white shadow-sm">
            <div className="container">
                <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0">
                  <a href="/" className="navbar-brand">
                    <h1 className="m-0 text-uppercase text-primary"><i className="fa fa-clinic-medical me-2"></i>
                      {continent} Family Hero
                    </h1>
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                      <a href="/" className="nav-item nav-link">Home</a>              
                      <a href="/about" className="nav-item nav-link" style={{'marginTop': '0px'}}>About</a>
                      <MenuItem>
                          <IconButton
                            style={{marginRight: '-31px',color: 'lightgray'}}
                            size="large"
                            aria-label="show number of beds" 
                            color="inherit"
                            onClick={handleClick('bottom')}
                          >
                          <Badge badgeContent={notificationsTotal} color="success">
                              <NotificationsIcon/>
                          </Badge>                   
                          </IconButton>
                          <a href="/find-a-doctor" className="nav-item nav-link" style={{'marginTop': '-7px'}}>Find A Doctor</a>
                      </MenuItem>
                      <a href="/appointment" className="nav-item nav-link">Appointment</a>
                      <a href="/view-courses" className="nav-item nav-link">Learn</a>
                      <a href="/contact" className="nav-item nav-link">Contact</a>
                    </div>
                  </div>
                </nav>
            </div>
          </div>
          </AppBar>
    );

}

export default Topbar;