import React, { useEffect } from 'react';

const AdsFeatures = (props) => {
    const { dataAdSlot } = props;

    useEffect(()=>{
        try{
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }catch (e){
            console.log(e);
        }
    },[]);


    return (
        <>
                <ins className="adsbygoogle"
                    style={{display: "block"}}
                    data-ad-client="ca-pub-2340613167692358"
                    data-ad-slot={dataAdSlot}
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>        
        </>
    )
}

export default AdsFeatures;