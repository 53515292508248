import React, { useState, useEffect, Fragment } from 'react';
import Topbar from './Topbar';
import { Helmet } from 'react-helmet';
import DB from '../../db.js';
import Grid from '@mui/material/Grid';
import Footer from './Footer';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import '../../components/main/css/quiz.css';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Rating from '@mui/material/Rating';


const Alert = React.forwardRef(function Alert(props, ref){
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props}/>;
});
const PractiseQuiz = () => {


    const [questions, setQuestions] = useState([]);
    var [questionNumber, setQuestionNumber] = useState(1);

    var [playerScore, setPlayerScore] = useState(0);

    const [displayQuestion, setDisplayQuestion] = useState("Where is world tallest building located ?");
    const [optionOne, setOptionOne] = useState("Africa");
    const [optionTwo, setOptionTwo] = useState("California");
    const [optionThree, setOptionThree] = useState("Dubai");
    const [optionFour, setOptionFour] = useState("Italy");

    const [answerErrorSnackbar, setAnswerErrorSnackbar] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [answer, setAnswer] = useState([]);
    const [courses, setCourses] = useState([]);

    useEffect(()=>{
        const db = new DB('family_hero');
        db.getAllQuestions().then((question)=>{
            setQuestions(question);           
        });
        db.getCourses().then((courses)=>{
            setCourses(courses);
        });
    },[]);

    const snackBarClose = (event, reason) => {
        if(reason == 'clickaway'){
            return;
        }
        setAnswerErrorSnackbar(false);
    }
    const selectAnswer = (event) => {
        event.preventDefault();
        var option = event.target.innerHTML;
        setAnswer(option);
    }

    let indexNumber = 0;
    let shuffledQuestions = [];
    let wrongAttempt = 0;
    
    const saveQuizToMongo = (objQuiz) => {
        fetch('http://localhost:8080/learning-test',{
            headers:{
                'Content-Type':'application/json'
            },
            method:'POST',
            body:JSON.stringify(objQuiz)
        }).then((res)=>{
            console.log(res);
        }).catch((err)=>{
            console.log(err);
        });
    }

    const handleNextQuestion = (event) => {
        event.preventDefault();
        while(shuffledQuestions.length <= 9){
            const random = questions[Math.floor(Math.random() * questions.length)];
            if(!shuffledQuestions.includes(random)){
                shuffledQuestions.push(random);
            }
        }
        // check for answer
        const currentQuestion = shuffledQuestions[indexNumber];
        const currentQuestionAnswer = currentQuestion.correctOption;
        console.log('currentQuestionAnswer: '+ currentQuestionAnswer);
        console.log('answer: '+ answer);

        if(answer === ""){
            setAnswerErrorSnackbar(true);
            setErrorMessage("Please choose an answer, before going forward!");
        }else if((answer === currentQuestion.correctOption)){
            //playerScore++ // adding to player's score
            playerScore++
            indexNumber++ // adding 1 to index so it has to display next question...
            //set to delay question number till when next question loads
            setTimeout(()=>{
                questionNumber++;
            },1000);        
        }else if(answer != currentQuestion.correctOption){
            wrongAttempt++;
            indexNumber++;
            setTimeout(()=>{
                questionNumber++;
            },1000);
        }

        setTimeout(()=>{
            if(indexNumber <= 9){
                const nextQuestion = shuffledQuestions[indexNumber];
                setQuestionNumber(questionNumber);
                console.log(playerScore);
                //setPlayerScore(playerScore);
                setDisplayQuestion(nextQuestion.question);
                setOptionOne(nextQuestion.optionA);
                setOptionTwo(nextQuestion.optionB);
                setOptionThree(nextQuestion.optionC);
                setOptionFour(nextQuestion.optionD);

                var newItem = {
                    "course":"",
                    "question":nextQuestion.question,
                    "answer":answer,
                    "score":playerScore
                }

                console.log(newItem);

            }else{
                console.log('new item');
            }
        },1000);

        // next question
        
    }


    const questionCard = (
        <Fragment>
            <CardContent>
                <Typography variant="h5" component="div">
                    {displayQuestion}
                </Typography>
            </CardContent>
        </Fragment>
    );

    const answerCardOne = (
        <Fragment>
            <CardContent>
                <Typography variant="h5" component="div" onClick={selectAnswer}>
                    {optionOne}
                </Typography>
            </CardContent>
        </Fragment>
    );

    const answerCardTwo = (
        <Fragment>
            <CardContent>
                <Typography variant="h5" component="div" onClick={selectAnswer}>
                    {optionTwo}
                </Typography>
            </CardContent>
        </Fragment>
    );

    const answerCardThree = (
        <Fragment>
            <CardContent>
                <Typography variant="h5" component="div" onClick={selectAnswer}>
                    {optionThree}
                </Typography>
            </CardContent>
        </Fragment>
    );

    const answerCardFour = (
        <Fragment>
            <CardContent>
                <Typography variant="h5" component="div" onClick={selectAnswer}>
                    {optionFour}
                </Typography>
            </CardContent>
        </Fragment>
    );
    
    return(
        <>
            <Helmet>
                <meta charSet="uft-8"/>
                <title>{"Quiz"}</title>
                <meta name="description" content={""}/>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="canonical" href="http://www.africanfamilyhero.co.za"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
            <Topbar/>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs></Grid>
                <Grid item xs={20}>
                    
                    <div className="container-xxl py-5">
                            <div className="container">
                            <div className="row justify-content-center position-relative" style={{marginTop:'-30px',marginRight:'-120px',zIndex:1}}>
                                 <div className="col-lg-8">
                                    <div className="bg-white rounded p-5 m-5 mb-0">
                                        <div className="row g-3">
                                            <div className="col-12 col-sm-6">
                                                <h1>Score: {playerScore}/10</h1>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <h1>Question: {questionNumber}/10</h1>
                                            </div>
                                            <div className="col-20">
                                                <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center game-quiz-container">
                                                    <Box>
                                                        <Card variant="outlined">{questionCard}</Card>
                                                    </Box>
                                                </div>
                                                <br/>
                                                <div className="bg-light rounded d-flex flex-column aligh-items-center justify-content-center text-center game-question-container">
                                                    <Box>
                                                        <div className="row g-3" style={{marginLeft: '14%'}}>
                                                            <div className="col-5">
                                                                <Box>
                                                                    <Card variant="outlined">
                                                                        {answerCardOne}
                                                                    </Card>
                                                                </Box>
                                                            </div>
                                                            <div className="col-5">
                                                                <Box>
                                                                    <Card variant="outlined">
                                                                        {answerCardTwo}
                                                                    </Card>
                                                                </Box>
                                                            </div>
                                                            <div className="col-5">
                                                                <Box>
                                                                    <Card variant="outlined">
                                                                        {answerCardThree}
                                                                    </Card>
                                                                </Box>
                                                            </div>
                                                            <div className="col-5">
                                                                <Box>
                                                                    <Card variant="outlined">
                                                                        {answerCardFour}
                                                                    </Card>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop:'30px'}}>
                                                            <button className="btn btn-dark rounded-pill py-md-3 px-md-5 mx-2" onClick={handleNextQuestion}>{"Next Question"}</button>
                                                        </div>
                                                    </Box>
                                                    <Snackbar open={answerErrorSnackbar} autoHideDuration={6000} onClose={snackBarClose}>
                                                        <Alert onClose={snackBarClose} severity="error" sx={{width:'100%'}}>
                                                            {errorMessage}
                                                        </Alert>
                                                    </Snackbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Footer/>
        </>
    )
}

export default PractiseQuiz;