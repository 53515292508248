import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import styles from '../main/css/modal.module.css';
import DB from '../../db.js';
import Swal from 'sweetalert2';


const Modal = ({ setIsOpen }) => {

  const db = new DB('family_hero');

  const [username, setUsername] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [option, setOption] = useState("");
  const [countryIndex, setCountryIndex] = useState("");
  const [healthWorker, setHealthWorker] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tokenCheck, setTokenCheck] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const url = "ws://localhost:8080/myWebsocket";
  const mywsServer = new WebSocket(url);


  let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;


  useEffect(() => {
      const token = sessionStorage.getItem("token");
      const email = sessionStorage.getItem('email');

      if((token !== null) && (email !== null)){
          getSignedInUser(token,email);
      }else if((token === null) && (email === null)){
          setLoading(false);
          setError(false);
          setMessage("Sign in or sign up to chat with the health on hero.");
      }
  },[]);

  async function backendSignup(objUser){
      (async () => {
          const rawResponse = await fetch('http://localhost:8080/sign-up', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(objUser)
          });
          const content = await rawResponse.json();

          sessionStorage.setItem('token',content.token);
          sessionStorage.setItem('email',content.email);

          getSignedInUser(content.token,content.email);
      })();
  }


  async function getSignedInUser(token,email){
    (async () => {
        const rawResponse = await fetch(`http://localhost:8080/user/me?email=${email}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        });
        
        setLoading(false);
        setError(false);

        const content = await rawResponse.json();

        if((content.username === undefined) && (content.message === 'Invalid Token')){
          setMessage("Sign in or sign up to chat with a health worker on hero chat.");
        }else{
          
          setUsername(content.username);
          setEmailAddress(content.email);
          setMessage("Hi, " + content.username);

          var specialist = document.getElementById("healthWorker");
          var operationExists = document.getElementById("operation");

          if(typeof(specialist) != 'undefined' && specialist != null){
              document.getElementById("healthWorker").value = content.worker;
          }
          if(typeof(operationExists) != 'undefined' && operationExists != null){
            document.getElementById("operation").value = content.auth;
          }
        }
    })();
  }

  async function backendSignin(objUser){
      (async () => {
          const rawResponse = await fetch('http://localhost:8080/sign-in', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(objUser)
          });
          const content = await rawResponse.json();

          sessionStorage.setItem('token',content.token);
          sessionStorage.setItem('email',content.email);

          getSignedInUser(content.token,content.email);
      })();
  }

  function isOpen(ws){
      return ws.readyState === ws.OPEN
  }

  async function saveInfo(){
      if((option === "sign_up")){
        if((username !== "") && (emailAddress !== "") && (healthWorker !== "")){
          if (!emailRegex.test(emailAddress)) {
            //console.log("invalid email address");
            const Toast = Swal.mixin({
                toast:true,
                position:"top-end",
                showCloseButton: false,
                timer: 3000,
                timerProgressBar: true,
                didopen: (toast) => {
                   toast.onmouseenter = Swal.stopTimer;
                   toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon:"error",
                title:"Invalid email address"
            });
            setIsOpen(false);
          } else {

             const objUser = {};

             objUser._id = emailAddress;
             objUser.username = username;
             objUser.handle = "@"+username.toLowerCase();
             objUser.email = emailAddress;
             //objUser.country = countryIndex;
             objUser.worker = healthWorker;
             objUser.date = new Date();
             objUser.type = "bot";
             objUser.auth = "sign_up";


             db.botSaveInfo(objUser).then((res)=>{
                if(res[0] === 'conflict'){
                    Swal.fire({
                        title:"Error",
                        text:"Try a different user, this one already exists!",
                        icon:"error",
                        confirmButtonText: `Continue&nbsp;<i class="fa fa-arrow-right"></i>`
                    });
                    setIsOpen(false);
                    
                }else if(res[0] === true){
                    
                    Swal.fire({
                        title:"Info",
                        text:"User sign up, complete!",
                        icon:"info",
                        confirmButtonText: `Continue&nbsp;<i class="fa fa-arrow-right"></i>`
                    });

                    setIsOpen(false);
                    //console.log(objUser);
                    backendSignup(objUser);
                    //if(!isOpen(mywsServer)) return;
                    //mywsServer.send(JSON.stringify(objUser));
                }else if(res[0] === false){
                    Swal.fire({
                        title:"Error",
                        text:"Cannot sign up user!",
                        icon:"error",
                        confirmButtonText:"Cool"
                    });
                    setIsOpen(false);
                }
             }).catch((err)=>{
                console.log(err);
             });
          }
        }
      }else if((option === "sign_in")){
         if((username !== "") && (emailAddress !== "") && (healthWorker !== "")){
            if(!emailRegex.test(emailAddress)){
                const Toast = Swal.mixin({
                   toast:true,
                   position:"bottom-left",
                   showCloseButton: false,
                   timer: 3000,
                   timerProgressBar: true,
                   didOpen: (toast) => {
                      toast.onmouseenter = Swal.stopTimer;
                      toast.onmouseleave = Swal.resumeTimer;
                   }
                });
                Toast.fire({
                    icon:"error",
                    title:"Invalid email address"
                });
                setIsOpen(false);
            }else{
              const objUser = {};

              objUser._id = emailAddress;
              objUser.username = username;
              objUser.handle = "@"+username.toLowerCase();
              objUser.email = emailAddress;
              //objUser.country = countryIndex;
              objUser.worker = healthWorker;
              objUser.date = new Date();
              objUser.type = "bot";
              objUser.auth = "sign_in";

              await db.botUserInfo(objUser).then((res)=>{

                  if(res[0] === 'not_found'){
                      Swal.fire({
                          title:"Info",
                          text:"User not found, please sign up",
                          icon:"info",
                          confirmButtonText:"Cool"
                      });
                  }else{

                      const Toast = Swal.mixin({
                          toast: true,
                          position:"bottom-left",
                          showCloseButton: false,
                          timer: 3000,
                          timerProgressBar: false,
                          didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                          }
                      });

                      fetch('http://localhost:8080/check-user-token',{
                          method:'POST',
                          headers:{
                            'Content-Type':'application/json'
                          },
                          body:JSON.stringify(objUser)
                      }).then((data)=>{
                        if((data.status === 200)){
                            setIsOpen(true);
                            objUser.tokenized = true;
                            backendSignin(objUser);
                            Toast.fire({
                              icon:"success",
                              title:"Signed in successfully"
                            })
                        }
                      })

                  }

                  if(res[0] === 'not_found'){
                      //Swal.fire({
                      //    title:"Info",
                      //    text:"User not found, please sign up!",
                      //    icon:"info",
                      //    confirmButtonText:"Cool"
                      //});
                      const Toast = Swal.mixin({
                         toast: true,
                         position:"bottom-left",
                         showCloseButton: false,
                         timer: 3000,
                         timerProgressBar: true,
                         didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                         }
                      });
                      setIsOpen(false);
                      fetch('http://localhost:8080/check-user-token',{
                          method:'POST',
                          headers: {
                             'Content-Type':'application/json'
                          },
                          body:JSON.stringify(objUser)
                      }).then((data)=>{
                          if((data.status === 200)){
                              objUser.tokenized = true;
                              backendSignin(objUser);
                              Toast.fire({
                                  icon:"success",
                                  title:"Signed in successfully"
                              });
                          }else{
                              objUser.tokenized = false;
                              backendSignin(objUser);
                              Toast.fire({
                                  icon:"success",
                                  title:"Signed in successfully"
                              });
                          }
                          //console.log(data);
                      }).catch((err)=>{
                          console.log(err);
                      });
                  }else{
                      
                      const Toast = Swal.mixin({
                          toast:true,
                          position:"bottom-left",
                          showCloseButton: false,
                          timer: 3000,
                          timerProgressBar: true,
                          didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                          }
                      });
     
                      fetch('http://localhost:8080/check-user-token',{
                          method:'POST',
                          headers:{
                            'Content-Type':'application/json'
                          },
                          body:JSON.stringify(objUser)
                      }).then((data)=>{
                          if((data.status === 200)){
                              setIsOpen(false);
                              objUser.tokenized = true;
                              backendSignin(objUser);
                              Toast.fire({
                                 icon:"success",
                                 title:"Signed in successfully"
                              });
                          }else{
                              setIsOpen(false);
                              objUser.tokenized = false;
                              backendSignin(objUser);
                              Toast.fire({
                                  icon:"success",
                                  title:"Signed in successfully"
                              });
                          }
                      });
                      //setIsOpen(false);
                      //if(!isOpen(mywsServer)) return;
                      //mywsServer.send(JSON.stringify(objUser));

                      //backendSignin(objUser);
                  } 
               }).catch((err)=>{
                  console.log(err);
               });
            }
         }
      }else if((option === "choose_operation")){
         const Toast = Swal.mixin({
            toast:true,
            position:"bottom-left",
            showCloseButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
               toast.onmouseenter = Swal.stopTimer;
               toast.onmouseleave = Swal.resumeTimer;
            }
         });
         Toast.fire({
            icon:"error",
            title:"Choose either sign up or sign in!"
         });
         setIsOpen(false);
      }else if((countryIndex === "choose_health_worker")){
         const Toast = Swal.mixin({
            toast:true,
            position:"bottom-left",
            showCancelButton:false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
               toast.onmouseenter = Swal.stopTimer;
               toast.onmouseleave = Swal.resumeTimer;
            }
         });
         Toast.fire({
            icon:"error",
            title:"Choose a health worker!"
         });
         setIsOpen(true);
      }
  }

  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            {/*<h5 className={styles.heading}>
              
            </h5>*/}
              {loading ? (
                <h5 className={styles.heading}>Loading...</h5>
              ) : error ? (
                <h5 className={styles.heading}>Error: {"contact administrator."}</h5>
              ) : (
                <h5 className={styles.heading}>{message}</h5>
              )}
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className={styles.modalContent}>
            <form>
              <div className="row g-3">
                  <div className="col-12 col-sm-6">
                     <input type="text" className="form-control bg-light border-0" placeholder="username" onChange={(e) => setUsername(e.target.value)} value={username || ''}/>
                  </div>
                  <div className="col-12 col-sm-6">
                     <input type="email" className="form-control bg-light border-0" placeholder="email address" onChange={(e) => setEmailAddress(e.target.value)} value={emailAddress || ''}/>
                  </div>
              </div>
              <div className="row g-3">
                  <div className="col-24 col-sm-6">
                    <select className="form-select form-select-sm" id="operation" style={{marginTop:'12px',width:'208%'}} aria-label=".form-select-sm example" onChange={(e) => setOption(e.target.value)}>
                      <option selected value="choose_operation">Choose to either sign in or sign up</option>
                      <option value="sign_up">Sign up</option>
                      <option value="sign_in">Sign in</option>
                      {/*<option value="sign_out">Sign out</option>*/}
                    </select>
                  </div>
              </div>
              <div className="row g-3">
                  <div className="col-12 col-sm-6">
                    <select className="form-select form-select-sm" id="healthWorker" style={{marginTop:'12px',width:'208%'}} aria-label=".form-select-sm health-worker" onChange={(e) => setHealthWorker(e.target.value)}>
                      <option selected value="choose_health_worker">Choose health worker</option>
                      <option value="specialist">Specialist</option>
                      <option value="patient">Patient</option>
                    </select>
                  </div>
              </div>
              {/*<div className="row g-3">
                   <div className="col-24 col-sm-6">
                      <select className="form-select form-select-sm" id="country" style={{marginTop:'12px',width:'208%'}} aria-label=".form-select-sm example" onChange={(e) => setCountryIndex(e.target.value)}>
                        <option selected value="choose_country">Choose your country</option>
                        <option value="afghanistan">Afghanistan</option>
                        <option value="albania">Albania</option>
                        <option value="algeria">Algeria</option>
                        <option value="american_samoa">American Samoa</option>
                        <option value="andorra">Andorra</option>
                        <option value="angola">Angola</option>
                        <option value="anguilla">Anguilla</option>
                        <option value="antarctica">Antarctica</option>
                        <option value="antigua_and_barbuda">Antigua & Barbuda</option>
                        <option value="argentina">Argentina</option>
                        <option value="america">Armenia</option>
                        <option value="aruba">Aruba</option>
                        <option value="australia">Australia</option>
                        <option value="austria">Austria</option>
                        <option value="azerbaijan">Azerbaijan</option>
                        <option value="bahamas">Bahamas</option>
                        <option value="bahrain">Bahrain</option>
                        <option value="bangladesh">Bangladesh</option>
                        <option value="barbados">Barbados</option>
                        <option value="belarus">Belarus</option>
                        <option value="belgium">Belgium</option>
                        <option value="belize">Belize</option>
                        <option value="benin">Benin</option>
                        <option value="bermuda">Bermuda</option>
                        <option value="bhutan">Bhutan</option>
                        <option value="bolivia">Bolivia</option>
                        <option value="bosnia_and_herzegovina">Bosnia & Herzegovina</option>
                        <option value="botswana">Botswana</option>
                        <option value="bouvet_island">Bouvet Island</option>
                        <option value="brazil">Brazil</option>
                        <option value="british_virgin_islands">British Virgin Islands</option>
                        <option value="brunei">Brunei</option>
                        <option value="bulgaria">Bulgaria</option>
                        <option value="burundi">Burundi</option>
                        <option value="cambodia">Cambodia</option>
                        <option value="cameroon">Cameroon</option>
                        <option value="canada">Canada</option>
                        <option value="cape_verde">Cape Verde</option>
                        <option value="caribbean_netherlands">Caribbean Netherlands</option>
                        <option value="cayman_islands">Cayman Islands</option>
                        <option value="central_african_republic">Central African Republic</option>
                        <option value="chad">Chad</option>
                        <option value="chile">Chile</option>
                        <option value="china">China</option>
                        <option value="christmas_island">Christmas Island</option>
                        <option value="cocos_island">Cocos (Keeling) Island</option>
                        <option value="colombia">Colombia</option>
                        <option value="comoros">Comoros</option>
                        <option value="congo_brazzaville">Congo - Brazzaville</option>
                        <option value="congo_kinshasa">Congo - Kinshasa</option>
                        <option value="cook_islands">Cook Islands</option>
                        <option value="costa_rica">Costa Rica</option>
                        <option value="crotia">Crotia</option>
                        <option value="cuba">Cuba</option>
                        <option value="curacao">Curacao</option>
                        <option value="cyprus">Cyprus</option>
                        <option value="czechia">Czechia</option>
                        <option value="cote_d_lvoire">Cote dlvoire</option>
                        <option value="denmark">Denmark</option>
                        <option value="djibouti">Djibouti</option>
                        <option value="dominica">Dominica</option>
                        <option value="dominican_republic">Dominican Republic</option>
                        <option value="ecuador">Ecuador</option>
                        <option value="egypt">Egypt</option>
                        <option value="el_salvador">El Salvador</option>
                        <option value="equatorial_guinea">Equatorial Guinea</option>
                        <option value="eritrea">Eritrea</option>
                        <option value="estonia">Estonia</option>
                        <option value="ethiopia">Ethiopia</option>
                        <option value="falkland_islands">Falkland Islands (Islas Malvinas)</option>
                        <option value="foroe_islands">Faroe Islands</option>
                        <option value="fiji">Fiji</option>
                        <option value="finland">Finland</option>
                        <option value="france">France</option>
                        <option value="french_guiana">French Guiana</option>
                        <option value="french_polynesia">French Polynesia</option>
                        <option value="french_southern_territories">French Southern Territories</option>
                        <option value="gabon">Gabon</option>
                        <option value="gambia">Gambia</option>
                        <option value="georgia">Georgia</option>
                        <option value="germany">Germany</option>
                        <option value="ghana">Ghana</option>
                        <option value="gilbraltar">Gilbraltar</option>
                        <option value="greece">Greece</option>
                        <option value="greenland">Greenland</option>
                        <option value="grenada">Grenada</option>
                        <option value="guadeloupe">Guadeloupe</option>
                        <option value="guam">Guam</option>
                        <option value="guatemala">Guatemala</option>
                        <option value="guernsey">Guernsey</option>
                        <option value="guinea">Guinea</option>
                        <option value="guinea_bissau">Guinea-Bissau</option>
                        <option value="guyana">Guyana</option>
                        <option value="haiti">Haiti</option>
                        <option value="heard_and_mcdonald_islands">Heard & McDonald Islands</option>
                        <option value="honduras">Honduras</option>
                        <option value="hong_kong">Hong Kong</option>
                        <option value="hungary">Hungary</option>
                        <option value="iceland">Iceland</option>
                        <option value="india">India</option>
                        <option value="indonesia">Indonesia</option>
                        <option value="iran">Iran</option>
                        <option value="iraq">Iraq</option>
                        <option value="ireland">Ireland</option>
                        <option value="isle_of_man">Isle of Man</option>
                        <option value="israel">Israel</option>
                        <option value="italy">Italy</option>
                        <option value="jamaica">Jamaica</option>
                        <option value="japan">Japan</option>
                        <option value="jersey">Jersey</option>
                        <option value="jordan">Jordan</option>
                        <option value="kazakhstan">Kazakhstan</option>
                        <option value="kenya">Kenya</option>
                        <option value="kiribati">Kiribati</option>
                        <option value="kuwait">Kuwait</option>
                        <option value="kyrgyzstan">Kyrgyzstan</option>
                        <option value="laos">Laos</option>
                        <option value="lativa">Latvia</option>
                        <option value="lebanon">Lebanon</option>
                        <option value="lesotho">Lesotho</option>
                        <option value="liberia">Liberia</option>
                        <option value="libya">Libya</option>
                        <option value="liechtenstein">Liechtenstein</option>
                        <option value="lithuania">Lithuania</option>
                        <option value="luxembourg">Luxembourg</option>
                        <option value="macau">Macau</option>
                        <option value="macedonia">Macedonia(FYROM)</option>
                        <option value="madagascar">Madagascar</option>
                        <option value="malawi">Malawi</option>
                        <option value="malaysia">Malaysia</option>
                        <option value="maldives">Maldives</option>
                        <option value="mali">Mali</option>
                        <option value="malta">Malta</option>
                        <option value="marshall_islands">Marshall Islands</option>
                        <option value="martinique">Martinique</option>
                        <option value="mauritania">Mauritania</option>
                        <option value="mauritius">Mauritius</option>
                        <option value="mayotte">Mayotte</option>
                        <option value="mexico">Mexico</option>
                        <option value="micronesia">Micronesia</option>
                        <option value="moldova">Moldova</option>
                        <option value="monaco">Monaco</option>
                        <option value="mongolia">Mongolia</option>
                        <option value="montenegro">Montenegro</option>
                        <option value="montserrat">Montserrat</option>
                        <option value="morocco">Morocco</option>
                        <option value="mozambique">Mozambique</option>
                        <option value="mynmar">Myanmar (Burma)</option>
                        <option value="namibia">Namibia</option>
                        <option value="nauru">Nauru</option>
                        <option value="nepal">Nepal</option>
                        <option value="netherlands">Netherlands</option>
                        <option value="new_caledonia">New Caledonia</option>
                        <option value="new_zealand">New Zealand</option>
                        <option value="nicaragua">Nicaragua</option>
                        <option value="niger">Niger</option>
                        <option value="nigeria">Nigeria</option>
                        <option value="niue">Niue</option>
                        <option value="norfolk_island">Norfolk Island</option>
                        <option value="north_korea">North Korea</option>
                        <option value="northen_mariana_islands">Northen Mariana Islands</option>
                        <option value="norway">Norway</option>
                        <option value="oman">Oman</option>
                        <option value="pakistan">Pakistan</option>
                        <option value="palau">Palau</option>
                        <option value="palestine">Palestine</option>
                        <option value="panama">Panama</option>
                        <option value="papua_new_guinea">Papua New Guinea</option>
                        <option value="paraguay">Paraguay</option>
                        <option value="peru">Peru</option>
                        <option value="phillippines">Phillippines</option>
                        <option value="pitcairn_islands">Pitcairn Islands</option>
                        <option value="poland">Poland</option>
                        <option value="portugal">Portugal</option>
                        <option value="puerto_rico">Puerto Rico</option>
                        <option value="qatar">Qatar</option>
                        <option value="romania">Romania</option>
                        <option value="russia">Russia</option>
                        <option value="rwanda">Rwanda</option>
                        <option value="reunion">Reunion</option>
                        <option value="samoa">Samoa</option>
                        <option value="san_marino">San Marino</option>
                        <option value="saudi_arabia">Saudi Arabia</option>
                        <option value="senegal">Senegal</option>
                        <option value="serbia">Serbia</option>
                        <option value="seychelles">Seychelles</option>
                        <option value="sierra_leone">Sierra Leone</option>
                        <option value="singapore">Singapore</option>
                        <option value="sint_maarten">Sint Maarten</option>
                        <option value="slovakia">Slovakia</option>
                        <option value="solomon_islands">Solomon Islands</option>
                        <option value="somalia">Somalia</option>
                        <option value="south_africa">South Africa</option>
                        <option value="south_georgia_and_south_sandwich_islands">South Georgia & South Sandwich Islands</option>
                        <option value="south_sudan">South Sudan</option>
                        <option value="spain">Spain</option>
                        <option value="sri_lanka">Sri Lanka</option>
                        <option value="st_barthelemy">St. Barthelemy</option>
                        <option value="st_helema">St. Helena</option>
                        <option value="st_kitts_and_nevis">St. Kitts & Nevis</option>
                        <option value="st_lucia">St. Lucia</option>
                        <option value="st_martin">St. Martin</option>
                        <option value="st_pierre_and_miquelon">St. Pierre & Miquelon</option>
                        <option value="st_vincent_and_grenadines">St. Vincent & Grenadines</option>
                        <option value="sudan">Sudan</option>
                        <option value="suriname">Suriname</option>
                        <option value="svalbard_and_jan_mayen">Svalbard & Jan Mayen</option>
                        <option value="swaziland">Swaziland</option>
                        <option value="sweden">Sweden</option>
                        <option value="switzerland">Switzerland</option>
                        <option value="syria">Syria</option>
                        <option value="sao_tome_and_principe">Sao Tome & Principe</option>
                        <option value="taiwan">Taiwan</option>
                        <option value="tajikistan">Tajikistan</option>
                        <option value="tanzania">Tanzania</option>
                        <option value="thailand">Thailand</option>
                        <option value="timor_leste">Timor-Leste</option>
                        <option value="togo">Togo</option>
                        <option value="tokelau">Tokelau</option>
                        <option value="tonga">Tonga</option>
                        <option value="trinidad_and_tobago">Trinidad & Tobago</option>
                        <option value="tunisia">Tunisia</option>
                        <option value="turkey">Turkey</option>
                        <option value="turkmenistan">Turkmenistan</option>
                        <option value="turks_and_caicos_islands">Turks & Caicos Islands</option>
                        <option value="tuvalu">Tuvalu</option>
                        <option value="us_outlying_islands">U.S Outlying Islands</option>
                        <option value="us_virgin_islands">U.S Virgin Islands</option>
                        <option value="uganda">Uganda</option>
                        <option value="ukraine">Ukraine</option>
                        <option value="united_arab_emirates">United Arab Emirates</option>
                        <option value="united_kingdom">United Kingdom</option>
                        <option value="united_states">United States</option>
                        <option value="uruguay">Uruguay</option>
                        <option value="uzbekistan">Uzbekistan</option>
                        <option value="vanuatu">Vanuatu</option>
                        <option value="vatican_city">Vatican City</option>
                        <option value="venezuela">Venezuela</option>
                        <option value="vietnam">Vietnam</option>
                        <option value="wallis_and_futuna">Wallis & Futuna</option>
                        <option value="western_sahara">Western Sahara</option>
                        <option value="yemen">Yemen</option>
                        <option value="zambia">Zambia</option>
                        <option value="zimbabwe">Zimbabwe</option>
                        <option value="aland_islands">Aland Islands</option>
                      </select>
                   </div>
              </div>*/}
            </form>
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button className={styles.saveBtn} onClick={() => saveInfo(false)}>
                Go
              </button>
              <button
                className={styles.cancelBtn}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;