import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

export default function useGeolocation(){
	const [locationData, setLocationData] = useState(null);
	useEffect(()=>{
		getLocation();
	},[]);

	// Connecting to location api
	//async function getLocation(){
	//	const res = await axios.get("https://ipapi.co/json/");
		//console.log(res);
	//	if (res.status === 200)
	//		setLocationData(res.data);
	//}

	async function getLocation(){
		try{
			const res = await axios.get("https://ipapi.co/json");
			if (res.status === 200) {
				setLocationData(res.data);
			} else {
				console.error(`Unexpected response status: ${res.status}`);
			}
		}catch (error){
			if (error.response){
				// The request was made and the server responded with a status code outside the 2xx range
				console.error("Response error:", error.response.data);
				console.error("Response status: ", error.response.status);
				console.error("Respnse headers:", error.response.headers);
			} else if (error.request) {
				// The request was made out no response was received
				console.error("No response received:", error.request);
			} else {
				// Something happened in setting up the request
				console.error("Error setting up request:", error.message);
			}
		}
	}

	return {
		ip: locationData?.ip,
		network: locationData?.network,
		version: locationData?.version,
		city: locationData?.city,
		region: locationData?.region,
		region_code: locationData?.region_code,
		country: locationData?.country,
		country_name: locationData?.country_name,
		country_code: locationData?.country_code,
		country_code_iso3: locationData?.country_code_iso3,
		country_capital: locationData?.country_capital,
		country_tld: locationData?.country_tld,
		contient_code: locationData?.contient_code,
		in_eu: locationData?.in_eu,
		postal: locationData?.postal,
		longitude: locationData?.longitude,
		latitude: locationData?.latitude,
		timezone: locationData?.timezone,
		utc_offset: locationData?.utc_offset,
		country_calling_code: locationData?.country_calling_code,
		currency: locationData?.currency,
		currency_name: locationData?.currency_name,
		languages: locationData?.languages,
		country_area: locationData?.country_area,
		country_population: locationData?.country_population,
		asn: locationData?.asn,
		org: locationData?.org
	}
}