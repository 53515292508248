import React,{ useState, useEffect } from 'react';
import Topbar from '../main/Topbar';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import { useLocation } from 'react-router-dom';

const PublicChart = () => {
     
     const [scorecardName, setScorecardName] = useState([]);
     
     const location = useLocation();
     const pathname = location.pathname;
     const indexie = pathname.lastIndexOf('/');
     const keyword = pathname.substring(indexie + 1);

     useEffect(()=>{                
        if(keyword == 'scorecard'){
            setScorecardName('General');
        }
        /*else{
            setScorecardName(keyword);
        }*/
     },[]);

     return(
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{scorecardName + " " + "scorecard"}</title>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://www.africanfamilyhero.co.za/scorecard"/>
            </Helmet>
            <Topbar/>
            <br/><br/><br/><br/>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h2 className="section-title bg-white text-center text-primary px-3">{"African health scorecard"}</h2>
                        <h2 className="mb-5">{scorecardName + " "+ "scorecard"}</h2>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
     )
}

export default PublicChart;