import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import './components/main/css/bootstrap.min.css';
import './components/main/css/style.css'

import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { HelmetProvider } from 'react-helmet-async';
import DB from './db.js';

const db = new DB('family_hero');

db.createAllData();
//db.setOnlineMarketing();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <Router>
                <CssBaseline />
                <HelmetProvider>
                    <App />
                </HelmetProvider>
        </Router>
);