import React, { useEffect, useState } from 'react';
import Topbar from '../main/Topbar';
import Footer from './Footer';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import DB from '../../db.js';
import Rating from '@mui/material/Rating';

import elearning from '../main/elearning/img/course-2.jpg';

import fbIcon from './images/social/fb-icon.png';
import instagramIcon from './images/social/instagram-icon.png';
import twitterIcon from './images/social/twitter-icon.png';

const CourseCategoryDetails = () => {

    const location = useLocation();
    const pathname = location.pathname;

    const [courses, setCourses] = useState([]);
    const indexie = pathname.lastIndexOf('/');
    const [category, setCategory] = useState('');
    const categoryFilter = pathname.substring(indexie + 1);
    
    useEffect(()=>{
        const db = new DB('family_hero');
        db.getCourses().then((courses)=>{
            const filteredCourses = courses.filter((item)=>{
                return Object.values(item).join('').toLowerCase().includes(categoryFilter.toLowerCase());
            });      
            setCourses(filteredCourses);
            if(categoryFilter == 'web-design'){
                setCategory('Web design');
            }else if(categoryFilter == 'graphic-design'){
                setCategory('Graphic design');
            }else if(categoryFilter == 'video-editing'){
                setCategory('Video editing');
            }else if(categoryFilter == 'online-marketing'){
                setCategory('Online marketing');
            }
        });
    },[]);

    return(
        <div>
            <Helmet>
                <meta charSet="uft-8"/>
                <title>{category + ' ' + ' courses'}</title>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
            <Topbar/>  
            <br/><br/><br/><br/>    
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">Courses</h6>
                        <h1 className="mb-5">{category + ' ' + 'Courses'}</h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        {
                            courses.map((course)=>{
                                return(
                                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="course-item bg-light">
                                            <div className="position-relative overflow-hidden">
                                                <img className="img-fluid" src={course.image} alt={course.title}/>
                                                <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                                    <a href={"/course-details/"+course.title.replace(/\s+/g, '-').toLowerCase()} className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end" style={{borderRadius: '30px 0 0 30px'}}>Read More</a>
                                                    <a href={"/take-a-quiz/"+course.title.replace(/\s+/g, '-').toLowerCase()} className="flex-shrink-0 btn btn-sm btn-primary px-3" style={{borderRadius: '0 30px 30px 0'}}>Take a test</a>
                                                </div>
                                            </div>
                                            <div className="text-center p-4 pb-0">
                                                <h3 className="mb-0">{course.price}</h3>
                                                <div className="mb-3">
                                                    <Rating 
                                                        name="customized-10" 
                                                        defaultValue={course.rating} 
                                                        max={10} 
                                                        readOnly
                                                    />
                                                </div>
                                                <h5 className="mb-4">{course.title}</h5>
                                            </div>
                                            <div className="d-flex border-top">
                                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-user-tie text-primary me-2"></i>{course.instructor}</small>
                                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-clock text-primary me-2"></i>{course.duration}</small>
                                                <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>{course.students + ' ' + 'Students'}</small>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default CourseCategoryDetails;