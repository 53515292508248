import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Auth from './Auth';
import Dashboard from './components/dashboard/Dashboard';
import { Routes, Route } from "react-router-dom";
import React from 'react';
import Hereos from './components/main/Hereos';
import About from './components/main/About';
import Contact from './components/main/Contact';
import Careers from './components/main/Careers';
import CommentReplies from './components/main/CommentReplies';
import SEOSitemap from './components/main/SEOSitemap';
import Pricing from './components/main/Pricing';
import Post from './components/main/Post';
import DoctorFinderApp from './components/main/DoctorFinderApp';
import LatestMedicalBlogPost from './components/main/LatestMedicalBlogPost';
import Details from './components/main/Details';
import Courses from './components/main/Courses';
import LatestNews from './components/main/LatestNews';
import PricingDetails from './components/main/PricingDetails';
import LatestNewsDetails from './components/main/LatestNewsDetails';
import CourseDetails from './components/main/CourseDetails';
import CourseCategoryDetails from './components/main/CourseCategoryDetails';
import JoinNow from './components/main/JoinNow';
import SignIn from './components/main/SignIn';
import AboutLearn from './components/main/AboutLearn';
import Ambulance from './components/main/Ambulance';
import ReadMore from './components/main/ReadMore';
import Services from './components/main/Services';
import PublicChart from './components/main/PublicChart';
import Quiz from './components/main/Quiz';
import MedicalServices from './components/main/MedicalServices';
import Body from './components/main/Body';
import Instructor from './components/main/Instructor';
import PractiseQuiz from './components/main/PractiseQuiz';
import FullResource from './components/main/FullResource';
import Chat from './components/main/Chat';
import Analytics from './components/main/Analytics';

function App() {

  return (
    <div className="App">
            <Routes>
                <Route path="/" exact element={<Body/>}/>               
                <Route path="/find-a-doctor" exact element={<Hereos/>}/>
                <Route path="/about" exact element={<About/>}/>
                <Route path="/appointment" exact element={<Pricing/>}/>
                <Route path="/appointment/:department/:specialist" exact element={<Pricing/>}/>
                <Route path="/appointment-details/:id/:department/:specialist" exact element={<PricingDetails/>}/>
                <Route path="/contact" exact element={<Contact/>}/>
                <Route path="/careers" exact element={<Careers/>}/>
                <Route path="/sitemap" exact element={<SEOSitemap/>}/>
                <Route path="/latest-news/:category/:slug" exact element={<LatestNewsDetails/>}/>
                <Route path="/comment-replies" exact element={<CommentReplies/>}/>
                <Route path="/admin" exact element={<Auth/>}/>
                <Route path="/*" exact element={<Dashboard />}/>
                <Route path="/doctor-finder-app" exact element={<DoctorFinderApp/>}/>
                <Route path="/latest-courses" exact element={<LatestMedicalBlogPost/>}/>
                <Route path="/latest-news" exact element={<LatestNews/>}/>
                <Route path="/details/:department/:specialist" exact element={<Details/>}/>
                <Route path="/learning/:adslot/:category/:slug" exact element={<Post/>}/>
                <Route path="/view-courses" exact element={<Courses/>}/>
                <Route path="/course-details/:title" exact element={<CourseDetails/>}/>
                <Route path="/course-details/courses/:category" exact element={<CourseCategoryDetails/>}/>
                <Route path="/join-now/:title" exact element={<JoinNow/>}/>
                <Route path="/join-now" exact element={<JoinNow/>}/>
                <Route path="/join-now/sign-in" exact element={<SignIn/>}/>
                <Route path="/elearning/about" exact element={<AboutLearn/>}/>
                <Route path="/free-ambulance" exact element={<Ambulance/>}/>
                <Route path="/appointment/read-more" exact element={<ReadMore/>}/>
                <Route path="/facility/:title" exact element={<Services/>}/>
                {/*<Route path="/scorecard/:keyword" exact element={<PublicChart/>}/>*/}
                <Route path="/scorecard" exact element={<PublicChart/>}/>
                <Route path="/take-a-quiz/:slug" exact element={<Quiz/>}/>
                <Route path="/take-a-practise-quiz/:slug" exact element={<PractiseQuiz/>}/>
                <Route path="/medical-services/:name" exact element={<MedicalServices/>}/>
                <Route path="/instructor/:name" exact element={<Instructor/>}/>
                <Route path="/full-resource/:slug" exact element={<FullResource/>}/>
                <Route path="/chat" exact element={<Chat/>}/>
                <Route path="/start-a-chat" exact element={<Chat/>}/>
                <Route path="/referrals" exact element={<Analytics/>}/>
            </Routes>
    </div>   
  );
}

export default App;