import React from 'react';
import Footer from './Footer';

const Analytics = () => {

	return(
		<>
		    <div className="container-fluid bg-primary my-5 py-5">
		        <div className="container py-5">
		            <div className="row gx-5" style={{ marginTop: '-70px'}}>
		                <div className="col-lg-6 mb-5 mb-lg-0">
		                    <div className="mb-4">
		                        <h5 className="d-inline-block text-white text-uppercase border-bottom border-5">Referrals</h5>
		                    </div>
		                </div>
		                <div className="col-lg-12 mb-5 mb-lg-10">
		                	<div className="bg-white text-center rounded p-5" style={{ height: '230%'}}>
		                	</div>
		                </div>
		            </div>
		        </div>
		    </div>
		    <div className="container-fluid py-5">
		        <div className="container">
		            <div className="text-center mx-auto mb-5" style={{maxWidth: '500px'}}>
		                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Bounce rate</h5>
		                <h1 className="display-4">Today</h1>
		            </div>
		            <div className="text-center mx-auto mb-5" style={{maxWidth: '500px'}}>
		            	<h2>{0}</h2>
		            </div>
		        </div>
		    </div>
			<div className="container-fluid py-5">
		       <div className="container">
		            <div className="text-center mx-auto mb-5" style={{maxWidth: '500px'}}>
		                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Bounce rate</h5>
		                <h1 className="display-4">Yesterday</h1>
		            </div>
		            <div className="text-center mx-auto mb-5" style={{maxWidth: '500px'}}>
		            	<h2>{0}</h2>
		            </div>
		       </div>
    		</div>
    		<Footer/>
		</>
	);
}

export default Analytics;