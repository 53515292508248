import React, { useEffect, useState } from 'react';
import Topbar from '../main/Topbar';
import Footer from './Footer';
import {Helmet} from 'react-helmet';
import DB from '../../db.js';
import '../main/elearning/css/elearning.css';
import cat5 from '../main/elearning/img/1.jpg';
import cat7 from '../main/elearning/img/3.jpeg';
import cat9 from '../main/elearning/img/5.png';
import cat14 from '../main/elearning/img/10.jpeg';
import Rating from '@mui/material/Rating';

import elearning from '../main/elearning/img/course-1.jpg';

import fbIcon from './images/social/fb-icon.png';
import instagramIcon from './images/social/instagram-icon.png';
import twitterIcon from './images/social/twitter-icon.png';

import eInstructor from '../main/elearning/img/team-1.jpg';
import Instructor from '../main/Instructor';

import course1 from '../main/images/courses/course_1.png';
import course2 from '../main/images/courses/course_2.png';
import course3 from '../main/images/courses/course_3.png';
import "./css/slidingtext.css";

import AdsCourseAllied from './ads/AdsCourseAllied';
import AdsBiomedical from './ads/AdsBiomedical';
import AdsAlternative from './ads/AdsAlternative';
import AdsBiology from './ads/AdsBiology';
import AdsCourse from './ads/AdsCourse';
import useGeolocation from './useGeolocation';
import useGeolocationIPData from './useGeolocationIPData';

const Courses = () => {

    const [courses, setCourses] = useState([]);
    const [instructors, setInstructors] = useState([]);
    const [webdesignsTotal, setWebdesignsTotal] = useState([]);
    const [graphicdesignsTotal, setGraphicdesignsTotal] = useState([]);
    const [videoeditingsTotal, setVideoeditingsTotal] = useState([]);
    const [onlinemarketingsTotal, setOnlinemarketingsTotal] = useState([]);

    const { city, latitude, longitude, timezone, country_name } = useGeolocation();
    const { city_ipdata, latitude_ipdata, time_zone_ipdata, country_name_ipdata } = useGeolocationIPData();
    const [ continent, setContinent ] = useState('');

    useEffect(()=>{
        const db = new DB('family_hero');
        db.getCourses().then((courses)=>{
            setCourses(courses);
        });
        db.getInstructors().then((instructors)=>{
            setInstructors(instructors);
        });
        db.getWebDesignTotal().then((webdesigns)=>{
            setWebdesignsTotal(webdesigns + ' Course(s)');
        });
        db.getGraphicDesignTotal().then((graphicdesigns)=>{
            setGraphicdesignsTotal(graphicdesigns + ' Course(s)');
        });
        db.getVideoEditingTotal().then((videoeditings)=>{
            setVideoeditingsTotal(videoeditings + ' Course(s)');
        });
        db.getOnlineMarketingTotal().then((onlinemarketings)=>{
            setOnlinemarketingsTotal(onlinemarketings + ' Course(s)');
        });
        
    },[]);

    useEffect(() => {
        if (typeof timezone !== 'undefined') {
            const extractedContinent = timezone.split('/')[0];
            setContinent(extractedContinent);
        } else if (typeof time_zone_ipdata !== 'undefined') {
            const extractedContinent = time_zone_ipdata.name.split('/')[0];
            setContinent(extractedContinent);
        }
    }, [timezone, time_zone_ipdata]); // This ensures the effect runs only when 'timezone' changes


    useEffect(()=>{
        const metaDescription = document.querySelector('meta[name="description"]');

        const descriptionContent = `${continent} family hero range of free online healthcare courses explores highly important aspects of children and adult primary care.`;
        
        if (metaDescription) {
            // Update existing meta description content
            metaDescription.setAttribute('content', descriptionContent);
        } else {
            // Create new meta description if it doesn't exist
            const meta = document.createElement('meta');
            meta.name = 'description';
            meta.description = metaDescription;
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

    },[continent]); // Runs whenever continent or city changes

    return(
        <div>
           
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{"Health courses"}</title>
                <meta name="author" content="Sanjay Kumar"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="http://worldofhealthcare.co.za/view-courses"/>
            </Helmet>
            <Topbar/>
            <br/><br/>
            <div className="container-fluid bg-primary py-5 mb-5 page-header">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 text-center">
                            <h1 className="display-3 text-white animated slideInDown">Courses</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a className="text-white medium-font" href="/">Home</a></li>
                                    <li className="breadcrumb-item"><a className="text-white medium-font" href="/view-courses">Learn</a></li>
                                    <li className="breadcrumb-item text-white active medium-font" aria-current="page">Courses</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5 category">
              <div className="text-center wow fadeInUp" style={{marginTop:'70px !important'}}>
                    <h2 className="section-title bg-white text-center text-primary px-3 large-font">Variety of healthcare courses</h2>
                    <h3 className="mb-5 medium-font">{`${continent} family hero range of free online healthcare courses explores highly important aspects of children healthcare and adult primary care. Whether you are looking for free health courses for your own wellbeing, your loved ones, or for career in the field of healthcare, these online health courses, from nutrition to exercise, can benefit you in really fundamental ways.`}</h3>
               </div>
                <div className="container">
                    <div className="row g-3">
                        <div className="col-lg-7 col-md-6">
                            <div className="row g-3">
                                <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                                    <a className="position-relative d-block overflow-hidden" href="/course-details/courses/allied-healthcare">
                                        {/*<img className="img-fluid" src={cat14} alt=""/>*/}
                                        <AdsCourseAllied dataAdSlot={"1881495322"}/>
                                        <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin: '1px'}}>
                                            <h4 className="m-0 large-font">Allied Healthcare</h4>
                                            <small className="text-primary medium-font">{ webdesignsTotal }</small>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                                    <a className="position-relative d-block overflow-hidden" href="/course-details/courses/alternative-medicine">
                                        {/*<img className="img-fluid" src={cat5} alt=""/>*/}
                                        <AdsAlternative dataAdSlot={"6773260744"}/>
                                        <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin: '1px'}}>
                                            <h5 className="m-0 large-font">Alternative Medicine</h5>
                                            <small className="text-primary medium-font">{ graphicdesignsTotal }</small>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                                    <a className="position-relative d-block overflow-hidden" href="/course-details/courses/biology">
                                        {/*<img className="img-fluid" src={cat9} alt=""/>*/}
                                        <AdsBiology dataAdSlot={"5441466388"}/>
                                        <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin: '1px'}}>
                                            <h5 className="m-0 large-font">Biology</h5>
                                            <small className="text-primary medium-font">{ videoeditingsTotal }</small>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{minHeight: '350px'}}>
                            <a className="position-relative d-block h-100 overflow-hidden" href="/course-details/courses/biomedical-studies">
                                {/*<img className="img-fluid position-absolute w-100 h-100" src={cat7} alt="" style={{objectFit: 'cover'}}/>*/}
                                <AdsBiomedical dataAdSlot={"3988783916"}/>
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin: '1px'}}>
                                    <h5 className="m-0 large-font">Biomedical Studies</h5>
                                    <small className="text-primary medium-font">{ onlinemarketingsTotal }</small>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3 large-font">{"Popular Courses"}</h6>
                        <h2 className="mb-5 medium-font">{`Popular courses in healthcare are offered in a wide variety of subjects and are designed for moving forward personal and professional development. Specialists can develop a background in health content, as well as gain exceptional skills. Healthcare specialists are highly sought after around ${city}, and taking a course in a health related field can prepare you for numerious career paths.`}</h2>
                    </div>
                    <div className="row g-4 justify-content-center">
                        {
                            courses.map((course)=>{
                                return(
                                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="course-item bg-light">
                                            <div className="position-relative overflow-hidden">
                                                {/*<img className="img-fluid" src={course.image} alt={course.title}/>*/}
                                                {/*<img className="img-fluid" src={course.image} alt={course.title}/>*/}
                                                <AdsCourse
                                                    dataAdSlot={"9703510563"}
                                                    image={course.image}
                                                />
                                                <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                                    <a href={"/course-details/"+course.title.replace(/\s+/g, '-').toLowerCase()} className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end medium-font" style={{borderRadius: '30px 0 0 30px'}}>{"Read More"}</a>
                                                    <a href={"/take-a-quiz/"+course.title.replace(/\s+/g, '-').toLowerCase()} className="flex-shrink-0 btn btn-sm btn-primary px-3 medium-font" style={{borderRadius: '0 30px 30px 0'}}>{"Take a test"}</a>
                                                </div>
                                            </div>
                                            <div className="text-center p-4 pb-0">
                                                {/*<h3 className="mb-0">{course.price}</h3>*/}
                                                <div className="mb-3">
                                                    <Rating 
                                                        name="customized-10" 
                                                        defaultValue={course.rating} 
                                                        max={10} 
                                                        readOnly
                                                        size="large"
                                                    />
                                                </div>
                                                <h4 className="mb-4 medium-font">{course.title}</h4>
                                            </div>
                                            <div className="d-flex border-top">
                                                <small className="flex-fill text-center border-end py-2 small-font"><i className="fa fa-user-tie text-primary me-2"></i>{course.instructor}</small>
                                                <small className="flex-fill text-center border-end py-2 small-font"><i className="fa fa-clock text-primary me-2"></i>{course.duration}</small>
                                                <small className="flex-fill text-center py-2 small-font"><i className="fa fa-user text-primary me-2"></i>{course.students + ' ' + 'Students'}</small>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {/*<div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h5 className="section-title bg-white text-center text-primary px-3 large-font">{"Instructors"}</h5>
                        <h1 className="mb-5 medium-font">{``}</h1>
                    </div>
                    <div className="row g-4">
                        {
                            instructors.map((instructor)=>{
                                return(                                   
                                     <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="team-item bg-light">
                                            <div className="overflow-hidden">
                                                <img className="img-fluid" src={eInstructor} alt=""/>
                                            </div>
                                            <div className="position-relative d-flex justify-content-center" style={{marginTop: '-23px'}}>
                                                <div className="bg-light d-flex justify-content-center pt-2 px-1">
                                                    <a className="btn btn-sm-square btn-primary mx-1" href="">
                                                        <i className="fab fa-facebook-f" style={{marginLeft: '-19px',marginTop: '-5px'}}>
                                                            <img src={fbIcon} style={{marginTop: '-13px'}}/>
                                                        </i>
                                                    </a>
                                                    <a className="btn btn-sm-square btn-primary mx-1" href="">
                                                        <i className="fab fa-twitter" style={{marginLeft: '-19px',marginTop: '-5px'}}>
                                                            <img src={twitterIcon} style={{marginTop: '-13px'}}/>
                                                        </i>
                                                    </a>
                                                    <a className="btn btn-sm-square btn-primary mx-1" href="">
                                                        <i className="fab fa-instagram" style={{marginLeft: '-19px',marginTop: '-5px'}}>
                                                            <img src={instagramIcon} style={{marginTop: '-13px'}}/>
                                                        </i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="text-center p-4">
                                                <h5 className="mb-0"><a href={"/instructor/"+instructor.name.replace(/\s+/g, '-').toLowerCase()} style={{textDecoration:'none'}}>{instructor.name}</a></h5>
                                                <small>{instructor.designation}</small>
                                            </div>
                                        </div>
                                      </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>*/}
            <Footer/>
        </div>
    )

}

export default Courses;