import React, { useState, useEffect} from 'react';
import { RiCloseLine } from "react-icons/ri";
//import styles from '../main/css/quiz.module.css';
import styles from '../main/css/modal.module.css';
import Swal from 'sweetalert2';

const QuizModal = ({ setIsOpen, course, remarks, grade, score, wrong }) => {

	const objQuiz = {
		course: course,
		remarks: remarks,
		grade: grade,
		wrong: wrong,
		right: score,
		createdAt: new Date().toString(),
		updatedAt: new Date().toString()
	}

	const saveQuizToMongo = (objQuiz) => {
        fetch('http://localhost:8080/learning-quiz',{
            headers:{
                'Content-Type':'application/json'
            },
            method:'POST',
            body:JSON.stringify(objQuiz)
        }).then((res)=>{
            console.log(res);
        }).catch((err)=>{
            console.log(err);
        });
    }

	return(
		<>
			<div className={styles.darkBG} onClick={() => setIsOpen(false)}>
				<div className={styles.centered}>
					<div className={styles.modal}>
						<div className={styles.modalHeader}>
							<h4>{'Heading...'}</h4>
						</div>
					</div>
					<button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
						 <RiCloseLine style={{ marginBottom: "-3px" }} />
					</button>
					<div className={styles.modalContent} style={{ marginTop: '-58%'}}>
						<div className={styles.modalMainContent}>
							<p><b>Remarks: { remarks }</b></p>
							<p><b>Grade percentage: { grade }</b></p>
							<p><b>Wrong answers: { wrong }</b></p>
							<p><b>Right answers: { score }</b></p>
						</div>
					</div>
					<div className={styles.modalActions} style={{marginBbottom: '-46px'}}>
						<div className={styles.actionsContainer} style={{marginBottom: '-20%'}}>
							<button className={styles.saveBtn} onClick={() => saveQuizToMongo(objQuiz)}>
								Okay
							</button>
							<button 
								className={styles.cancelBtn}
								onClick={() => setIsOpen(false)}
							> 
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default QuizModal;