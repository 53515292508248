import React, { useState, useEffect } from 'react';
import Topbar from './../main/Topbar';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import {Helmet} from 'react-helmet';
import Footer from './Footer';
import AboutUsImage from './images/img-4.png';
import AdsBody from './ads/AdsBody';
import AdsAbout from './ads/AdsAbout';
import "./css/slidingtext.css";
import useGeolocation from './useGeolocation';
import useGeolocationIPData from './useGeolocationIPData';

const About = () => {
        
        const { city, latitude, longitude, timezone, country_name } = useGeolocation();
        const { city_ipdata, latitude_ipdata, time_zone_ipdata, country_name_ipdata } = useGeolocationIPData();

        const [ continent, setContinent ] = useState('');

        useEffect(() => {
            if (typeof timezone !== 'undefined') {
              const extractedContinent = timezone.split('/')[0];
              setContinent(extractedContinent);
            } else if (typeof time_zone_ipdata !== 'undefined'){
                console.log(time_zone_ipdata);
                //const extractedContinent = time_zone_ipdata.split('/')[0];
                //setContinent(extractedContinent);
            }
        }, [timezone, time_zone_ipdata]); // This ensures the effect runs only when 'timezone' changes

        useEffect(()=>{
            const metaDescription = document.querySelector('meta[name="description"]');

            const descriptionContent = `${continent} leading healthcare solution in ${city}. We are building future of healthcare processing.`;

            if (metaDescription) {
                // Update existing meta description content
                metaDescription.setAttribute('content', descriptionContent);
            } else {
                // Create new meta description if it doesn't exist
                const meta = document.createElement('meta');
                meta.name = 'description';
                meta.description = metaDescription;
                document.getElementsByTagName('head')[0].appendChild(meta);
            }

        },[continent, city, city_ipdata]); // Runs whenever continent or city changes


        return(
       
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{"About Us"}</title>
                    <meta name="author" content="Sanjay Kumar"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                    <link rel="canonical" href="https://www.worldofhealthcare.co.za/about"/>
                </Helmet>
                <Topbar/>
                <div>
                <br/>
                <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item xs>               
                    </Grid>
                    <Grid item xs={10}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className="container-fluid py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '500px'}}>
                                    <div className="position-relative h-100">
                                        <AdsAbout dataAdSlot="5806458153"/>
                                    </div>
                                </div>
                                <div className="col-lg-7" style={{marginTop:'29px'}}>
                                    <div className="mb-4">
                                        <h2 className="d-inline-block text-primary text-uppercase border-bottom border-5">About</h2>
                                        <h3 className="display-4">We are building the future of healthcare processing.</h3>
                                    </div>
                                    <p className="medium-font"><b>Our Purpose: </b>{`As you thrive, we thrive. Our mission is to simplify healthcare payments for businesses across ${continent}, supporting your growth every step of the way. We aim to foster greater healthcare inclusion and contribute to the growth of ${continent} economies.`}</p><p className="medium-font"><b>{"Our vision: "}</b>{"At WorldOfHealthcare, we are dedicated to delivering personal healthcare solutions that cater directly to your needs. By combining innovative technology and strategic collaborations, we ensure a seamless healthcare experience for you. Plus, we're here to simplify and automate your behind-the-scenes operations, empowering you to focus on what matters most - growing your business."}</p><p className="medium-font"><b>{"Our history: "}</b>{`We are an African tech company focused on helping ${continent} entrepreneurs and business grow their sales. We love solving problems using innovative approaches. We believe that technology has the power to improve commerce and lives. Starting in ${country_name}. WorldOfHealthcare now empowers merchants across ${continent}. Come and join us on our journey.`}</p>
                                    <div className="row g-3 pt-3">
                                    <div className="col-sm-3 col-6">
                                        <div className="bg-light text-center rounded-circle py-4">
                                            <i className="fa fa-3x fa-user-md text-primary mb-3 medium-font"></i>
                                            <h6 className="mb-0 medium-font">Qualified<small className="d-block text-primary medium-font">Doctors</small></h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-6">
                                        <div className="bg-light text-center rounded-circle py-4 medium-font">
                                            <i className="fa fa-3x fa-procedures text-primary mb-3 medium-font"></i>
                                            <h6 className="mb-0 medium-font">Emergency<small className="d-block text-primary">Services</small></h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-6">
                                        <div className="bg-light text-center rounded-circle py-4 medium-font">
                                            <i className="fa fa-3x fa-microscope text-primary mb-3 medium-font"></i>
                                            <h6 class="mb-0 medium-font">Accurate<small className="d-block text-primary medium-font">Testing</small></h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-6 medium-font">
                                        <div className="bg-light text-center rounded-circle py-4 medium-font">
                                            <i className="fa fa-3x fa-ambulance text-primary mb-3 medium-font"></i>
                                            <h6 className="mb-0">Free<small className="d-block text-primary medium-font">Ambulance</small></h6>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Grid>
                    <Grid item xs>
    
                    </Grid>
                </Grid>
                </Box>
                <Footer/>
                </div>
            </div>
        );
    
}

export default About;