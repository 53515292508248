import React from 'react';
import Topbar from './../main/Topbar';
import {Helmet} from 'react-helmet';
import Box from '@mui/material/Box';

const FullResource = () => {

    return(
        <div>
            <Helmet>
                <meta charSet="uft-8"/>
                <title>{"Full resource"}</title>
                <meta name="author" content="Sanjay Kumar"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://www.africanfamilyhero.co.za/course-details/{insertslughere}"/>
            </Helmet>
            <Topbar/>
            <div>
                <br/>
                <Box sx={{ flexGrow: 1}}>
                    
                </Box>
            </div>
        </div>
    );
}

export default FullResource;