import React, { useEffect, useState } from 'react';
import Topbar from '../main/Topbar';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Footer from './Footer';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ListItemText } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {Helmet} from 'react-helmet';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import fbIcon from './images/social/fb-icon.png';
import linkedIcon from './images/social/linkedin-icon.png';
import twitterIcon from './images/social/twitter-icon.png';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AdsFinder from './ads/AdsFinder';
import DB from '../../db.js';
import Card from '@mui/material/Card';
import { red } from '@mui/material/colors';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import DoctorFinderMap from './maps/DoctorFinderMap';
import "./css/slidingtext.css";
import useGeolocation from './useGeolocation';
import useGeolocationIPData from './useGeolocationIPData';

function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
};
  
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

const Alert = React.forwardRef(function Alert(props, ref){
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Hereos = () => {

    //const [languages, setLanguages] = React.useState(null);
    //const [notifications, setNotifications] = React.useState(null);

    const [expanded, setExpanded] = useState(false);
    const [department, setDepartment] = useState([]);
    const [specialists, setSpecialists] = useState([]);
    const [option, setOption] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [open, setOpen] = useState(false);
    const [messageOpen, setMessageOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [options, setOptions] = useState([]);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const [finding, setFinding] = useState([]);

    const [searchName, setSearchName] = useState('');
    const [searchDescription, setSearchDescription] = useState('');
    const [searchDepartment, setSearchDepartment] = useState('');
    const [searchLatitude, setSearchLatitude] = useState(0);
    const [searchLongitude, setSearchLongitude] = useState(0);
    const { city, latitude, longitude, timezone, country_name } = useGeolocation();
    const { city_ipdata, latitude_ipdata, time_zone_ipdata, country_name_ipdata } = useGeolocationIPData();

    const loading = openSearch && options.length === 0;


    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleErrorClose = () => {
        //event.preventDefault();
        setOpenErrorSnackbar(false);
    }

    const handleCloseMessage = () =>{
        setMessageOpen(false);
    }

    useEffect(()=>{
        const db = new DB('family_hero');
        db.getDepartments().then((department)=>{
            setDepartment(department);
        });
        db.getSpecialists().then((specialist)=>{
            setSpecialists(specialist);
        });
    },[]);

    useEffect(() => {
        let active = true;

        if(!loading){
            return undefined;
        }

        (async () => {
            await sleep(1e3);

            if(active){
                setOptions([...specialists]);
            }
        })();

        return () => {
            active = false;
        }

    },[loading]);

    useEffect(() => {
        if(!openSearch){
            setOptions([]);
        }
    },[openSearch]);

    function formatDate(date){
        const h = "0" + date.getHours();
        const m = "0" + date.getMinutes();
    
        return `${h.slice(-2)}:${m.slice(-2)}`;
    }


    const moveToPricing = (e) => {
        e.preventDefault();
        window.open("http://localhost:3000/appointment");
    }

    const handleChange = (e) => {
        console.log("Department selected!!");
        setOption(e.target.value);
        console.log(option)
    }

    const searchItems = (searchValue) => {
        console.log(searchValue);
        setSearchInput(searchValue);    
        if(searchInput !== ''){
            console.log('searchInput: ' + searchInput);
            const filteredData = specialists.filter((item)=>{
                return Object.values(item).join('').toLowerCase().includes(searchInput.toString().toLowerCase());
            });
            setFilteredResults(filteredData);
        }else{
            setFilteredResults(specialists);
        }
    }

    async function shareToChat(event){
        event.preventDefault();
        
        var message = {};
        var arrMessage = [];
        var BOT_IMG = "https://image.flaticon.com/icons/svg/327/327779.svg";

        for(var i=0;i<filteredResults.length;i++){

            message.department = filteredResults[i].department;
            message.message = filteredResults[i].description;
            message.latitude = filteredResults[i].latitude;
            message.longitude = filteredResults[i].longitude;
            message.username = filteredResults[i].name;
            message.action = 'share';
            message.date = formatDate(new Date());
            message.connection = 'search_result';
            message.from = 'search_result';
            message.type = 'bot';
            message.side = 'left';
            message.image = BOT_IMG;

        }
        arrMessage.push(message);
        const rawResponse = await fetch('http://localhost:8080/doctor-finder',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(arrMessage)
        });
        const content = await rawResponse.json();
        if((content.message.message === 'message_shared')){
            setOpenErrorSnackbar(true);
            setErrorMessage('Placard shared successfully.');
        }else if((content.message.message === 'message_exists')){
            setOpenErrorSnackbar(true);
            setErrorMessage('Placard has been shared already.');
        }
    }

    async function favoriteSpecialist(event){
        event.preventDefault();

        var message = {};
        var arrMessage = [];
        var BOT_IMG = 'https://image.flaticon.com/icons/svg/327/327779.svg';

        for(var i=0;i<filteredResults.length;i++){

            message.department = filteredResults[i].department;
            message.message = filteredResults[i].description;
            message.latitude = filteredResults[i].latitude;
            message.longitude = filteredResults[i].longitude;
            message.username = filteredResults[i].name;
            message.action = 'favorite';
            message.date = formatDate(new Date());
            message.connection = 'search_result';
            message.from = 'search_result';
            message.type = 'bot';
            message.side = 'left';
            message.image = BOT_IMG;

        }
        arrMessage.push(message);
        const rawResponse = await fetch('http://localhost:8080/doctor-finder',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(arrMessage)
        });
        const content = await rawResponse.json();
        if((content.message.message === 'favorite_added')){
            setOpenErrorSnackbar(true);
            setErrorMessage('Placard added to favorites successfully.');
        }else if ((content.message.message === 'favorite_exists')){
            setOpenErrorSnackbar(true);
            setErrorMessage('Placard has been added to favorites already.')
        }
    }

    async function specialistFinder(specialist){
        const rawResponse = await fetch('http://localhost:8080/doctor-finder',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(specialist)
        });
        const content = await rawResponse.json();

        setSearchName(content.message.name);
        setSearchDescription(content.message.description);
        setSearchDepartment(content.message.department);
        setSearchLatitude(content.message.latitude);
        setSearchLongitude(content.message.longitude);
    }

    async function saveSearchResultsToMongo(search){
        const rawResponse = await fetch('http://localhost:8080/search-results',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(search)
        });
        const content = await rawResponse.json();
        console.log(content);
    }

    let findadoctor = (e) => {
        e.preventDefault();
        if((searchInput !== '') && (option !== '')){
            console.log(searchInput);
            const filteredData = specialists.filter((item)=>{
                return Object.values(item).join('').toLowerCase().includes(searchInput.toString().toLowerCase());
            });
            var searchResults = {
                "department":option,
                "specialist":searchInput,
                "createdAt":new Date().toString(),
                "updatedAt":new Date().toString()
            }
            saveSearchResultsToMongo(searchResults);
            specialistFinder(filteredData);
            setFilteredResults(filteredData);
            setOpen(true);

        }else{
            if((option == "")){
                setOpenErrorSnackbar(true);
                setErrorMessage("Please provide hero's department!")
            }else if((searchInput == "")){
                setOpenErrorSnackbar(true);
                setErrorMessage("Please provide a hero search!");
            }
        }
    }

    return(
        
        <div>        
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{"Find a doctor"}</title>
                <meta name="author" content="Sanjay Kumar"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://www.worldofhealthcare.co.za/find-a-doctor"/>
            </Helmet>
            <Topbar/>
            <br/><br/>
            <Grid container item spacing={6}>
                <div className="container-fluid my-5 py-5">
                    <div className="container py-5">
                        <Grid container item spacing={{ xs:2, md: 3}} columns={{ xs: 4, sm: 8, md: 12}}>
                            <Grid container item xs={2} sm={4} md={3}>
                            </Grid>
                            <Grid container item xs={4} sm={4} md={6} variant="div">
                                <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px'}}>
                                    <br/><br/>
                                    <h2 className="d-inline-block text-black text-uppercase border-bottom border-5">{"Find A Doctor"}</h2>
                                    <br/>
                                    <h3 className="display-4 mb-4">{"Find An Healthcare Professionals"}</h3>
                                    <h2 className="text-black fw-normal">{"Make use of the family hero website to search a qualified african healthcare professional close to you or in a specific area and find out if they are available in order to make an appointment."}</h2>
                                </div>
                            </Grid>
                            <Grid container item xs={6} sm={4} md={4}>
                            </Grid>
                        </Grid>
                        <div className="mx-auto" style={{ width: '100%',maxWidth:'600px'}}>
                            <div className="input-group">
                                <select className="form-control border-primary w-25 medium-font" style={{ height: '70px'}} onChange={handleChange}>
                                    <option name="no_department">{"Department"}</option>
                                    {department.map((item)=>{
                                        return (
                                            <option value={item.department}>{item.department}</option>
                                        )
                                    })}
                                </select>
                                <Autocomplete
                                    //onChange={(e) => searchItems(e.target.value)}
                                    onChange={(event,value) => searchItems(value.name)}
                                    className="form-control border-primary w-50 medium-font"
                                    id="asynchronouse-search"
                                    sx={{ width: 300 }}
                                    open={openSearch}
                                    //open={open}
                                    onOpen={() => {
                                        //setSearch(false);
                                        setOpenSearch(true);
                                    }}
                                    onClose={() => {
                                        //setSearch(false);
                                        setOpenSearch(false);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name}
                                    options={specialists}
                                    loading={loading}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search family hero"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment:(
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                {/*<input type="text" className="form-control border-primary w-50" placeholder="Enter doctor's name" onChange={(e) => searchItems(e.target.value)}/>*/}
                                <button className="btn btn-dark border-0 w-25" onClick={findadoctor}>{"Search"}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5" style={{marginTop: '-125px'}}>
                    <div className="container">
                        <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px'}}>
                            <h3 className="d-inline-block text-primary text-uppercase border-bottom border-5">{"Our Doctors"}</h3>
                            <h4 className="display-4">{"Qualified Healthcare Professionals"}</h4>
                            <h2>{specialists.length}</h2>
                        </div>
                        <div className="position-relative">
                                    
                                {specialists.map((item,index)=>{
                                    return(
                                        <>
                                          <a href={"/details/" + item.department.replace(/\s+/g, '-').toLowerCase() + "/" + item.name.replace(/\s+/g, '-').toLowerCase()} style={{textDecoration:"none"}}>
                                            <div className="team-item">
                                                <div className="row g-0 bg-light rounded overflow-hidden" style={{height: '100%'}}>
                                                    <div className="col-12 col-sm-5 h-100">
                                                        {/*<img className="img-fluid h-100" src={item.image} alt={item.department.toLowerCase()} style={{objectFit: 'cover',filter: 'grayscale(100%)'}}/>*/}
                                                        <AdsFinder dataAdSlot="1086003152"/>
                                                    </div>
                                                    <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                                                        <div className="mt-auto p-4 large-font">
                                                            <h2 dangerouslySetInnerHTML={{__html: item.name}} style={{color:'black'}}></h2>
                                                            <h3 className="fw-normal fst-italic text-primary mb-4" dangerouslySetInnerHTML={{__html: item.department}}></h3>
                                                            <p className="m-0" dangerouslySetInnerHTML={{__html: item.description}} style={{textDecoration: 'none',color: 'black'}}></p>
                                                        </div>
                                                        <div className="mt-auto p-4">
                                                            <Typography className="m-0" style={{color:'black'}}><b className={"medium-font"}>{"Availability"}</b></Typography>
                                                            <Rating 
                                                                name="customized-10" 
                                                                defaultValue={Number.parseInt(item.learning_score)} 
                                                                max={10} 
                                                                readOnly
                                                                size="large"
                                                            />
                                                        </div>
                                                        <div className="d-flex mt-auto border-top p-4">
                                                               {/*<a className="btn btn-primary me-3" href="/view-courses">{"view courses"}</a>
                                                               <a href="/view-courses">{"view courses"}</a> 
                                                               <Tooltip title="click to do an appointment" arrow>
                                                                    <a className="btn btn-primary me-3 large-font" href="/appointment">{item.bed + " beds available"}</a>
                                                               </Tooltip>*/}

                                                               <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-3" href="#">
                                                                    <i className="fab fa-twitter" style={{marginLeft: '-13px',marginTop: '-5px'}}>
                                                                        <a href={"https://bit.ly/3XZqtC5"}>
                                                                            <img src={twitterIcon} style={{marginTop: '-5px'}}/>
                                                                        </a>
                                                                    </i>
                                                                </a>
                                                               <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-3" href="#">
                                                                    <i className="fab fa-facebook-f" style={{marginLeft: '-13px',marginTop: '-5px'}}>
                                                                        <a href={"https://bit.ly/3XZqtC5"}>
                                                                            <img src={fbIcon} style={{marginTop: '-5px'}}/>
                                                                        </a>
                                                                    </i>
                                                                </a>
                                                               <a className="btn btn-lg btn-primary btn-lg-square rounded-circle" href="#">
                                                                    <i className="fab fa-linkedin-in" style={{marginLeft: '-13px'}}>
                                                                        <a href={"https://bit.ly/3XZqtC5"}>
                                                                           <img src={linkedIcon} style={{marginTop: '-5px'}}/>
                                                                        </a>
                                                                    </i>
                                                               </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            </a>
                                        </>
                                    )
                                   
                                })}
                        </div>
                    </div>
                </div>
            </Grid>
            <br/>
            <BootstrapDialog
                onClose={handleCloseMessage}
                aria-labelledby="customized-dialog-title"
                open={messageOpen}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseMessage}>
                    {"Write doctor a message in order to consult"}
                </BootstrapDialogTitle>
                <DialogContent dividers>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus>
                        {"Send"}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {"Search results of healthcare professionals around you"}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Card sx={{ maxWidth: 500}}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="doctor">
                                    {Array.from(searchName)[0]}
                                </Avatar>
                            }
                            title={searchName}
                            subheader={searchDepartment}
                        />
                        <DoctorFinderMap lat={searchLatitude} lng={searchLongitude}/>
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {searchDescription}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <Tooltip title="Add to favorites">
                                <IconButton aria-label="add to favorites" onClick={favoriteSpecialist}>
                                    <FavoriteIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Share to HeroChat as placard">
                                <IconButton aria-label="share" onClick={shareToChat}>
                                    <ShareIcon/>
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    </Card>
                </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={moveToPricing}>
                    Make an appointment
                </Button>
            </DialogActions>
            </BootstrapDialog>
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%'}}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="success" sx={{ width: '100%'}}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            {/* End patients attended dialog box */}
            <Footer/>
        </div>
    );
}

export default Hereos;