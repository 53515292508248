import React, { useEffect, useState } from 'react';
import Topbar from '../main/Topbar';
import {Helmet} from 'react-helmet';
import Footer from './Footer';
import AdsBody from './ads/AdsBody';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DB from '../../db.js';

const Alert = React.forwardRef(function Alert(props, ref){
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props}/>
});
const SignIn = () => {

    const [email, setEmail] = useState([]);
    const [password, setPassword] = useState([]);
    
    const [joineeSnackbar, setJoineeSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [users, setUsers] = useState([]);

    useState(()=>{
        const db = new DB('family_hero');
        db.getUser().then((user)=>{
            setUsers(user);
        });
    },[]);

    const snackBarClose = (event, reason) => {
        if(reason == 'clickaway'){
            return;
        }
        setJoineeSnackbar(false);
    }

    const signIn = (event) => {
        event.preventDefault();
        if(email == ""){
            setErrorMessage("Your email address is not filled!");
            setJoineeSnackbar(true);
        }else if(password == ""){
            setErrorMessage("Password is not filled!");
            setJoineeSnackbar(true);
        }else if((email != "") & (password != "")){
            let user = {
                "email":email,
                "password":password
            }

            users.map((user)=>{
                if((user.email === email) && (user.password === password)){
                    console.log(user);
                }
            });
            
        }
        
    }
    return(
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{"Health courses - sign in"}</title>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://www.africanfamilyhero.co.za/join-now/sign-in"/>
            </Helmet>
            <Topbar/>
            <br/><br/>
            <div className="container-fluid bg-primary py-5 mb-5 page-header">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 text-center">
                            <h2 className="display-3 text-white animated slideInDown">{"Join Now"}</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="/" className="text-white">{"Home"}</a></li>
                                    <li className="breadcrumb-item"><a href="/view-courses" className="text-white">{"Learn"}</a></li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">{"Sign in"}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h2 className="section-title bg-white text-center text-primary px-3">{"Sign in"}</h2>
                            <h3 className="mb-5">{"Sign in to start learning"}</h3>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <h3>{"Join now"}</h3>
                                <p className="mb-4">{"Sign in to the african family hero web application in order to access all elearning and other health resources free from your account for download."}</p>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width:'50px',height:'50px'}}>
                                        <i className="fa fa-map-marker-alt text-white"></i>
                                    </div>
                                    <div className="ms-3">
                                        <h3 className="text-primary">Office</h3>
                                        <p className="mb-0">Granada Centre, 3 Corlett Dr, Illovo 2196, Andiccio24</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width:'50px',height:'50px'}}>
                                        <i className="fa fa-phone-alt text-white"></i>
                                    </div>
                                    <div className="ms-3">
                                        <h4 className="text-primary">Mobile</h4>
                                        <p className="mb-0">078 834 6604</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width: '50px',height:'50px'}}>
                                        <i className="fa fa-envelope-open text-white"></i>
                                    </div>
                                    <div className="ms-3">
                                        <h5 className="text-primary">Email</h5>
                                        <p className="mb-0">admin@africanfamilyhero.co.za</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <AdsBody dataAdSlot="1086003152"/>
                            </div>
                            <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="email" placeholder="Your email address" onChange={(e) => setEmail(e.target.value)}/>
                                                <label htmlFor="email-address">{"Email address"}</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="password" className="form-control" id="password" placeholder="Your password" onChange={(e) => setPassword(e.target.value)}/>
                                                <label htmlFor="confirm-password">{"Password"}</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit" onClick={signIn}>Sign in</button>
                                        </div>
                                        <b><a href="/join-now" className="position-relative d-block overflow-hidden" style={{textDecoration:'none',color:'black'}}>Don't have an account, create one now.</a></b>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
            <Footer/>
            <Snackbar open={joineeSnackbar} autoHideDuration={6000} onClose={snackBarClose}>
                <Alert onClose={snackBarClose} severity="error" sx={{width: '100%'}}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    )

}

export default SignIn;