import React,{ useEffect, useState } from 'react';
import Topbar from '../main/Topbar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Footer from './Footer';
import './css/owl.carousel.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import useGeolocation from './useGeolocation';
import useGeolocationIPData from './useGeolocationIPData';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DB from '../../db';
//import AdsBody from './ads/AdsBody';
import Swal from 'sweetalert2';
import "./css/slidingtext.css";
import AdsAppointment from './ads/AdsAppointment';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Pricing = () => {

    const db = new DB('family_hero');

    const [specialists, setSpecialists] = useState([]);
    const [department, setDepartment ] = useState([]);

    const [optionDepartment, setOptionDepartment] = useState('')
    const [optionSpecialists, setOptionSpecialists] = useState('');
    const [specialistEmail, setSpecialistEmail] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [message, setMessage] = useState('');
    const [appointment, setAppointment] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [appointmentErrorSnackbarOpen, setAppointmentErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [appointmentMessage, setAppointmentMessage] = useState('');
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { city, latitude, longitude, timezone, country_name } = useGeolocation();
    const { city_ipdata, latitude_ipdata, time_zone_ipdata, country_name_ipdata } = useGeolocationIPData();

    const location = useLocation();
    const pathname = location.pathname;
    
    const indexie = pathname.lastIndexOf("/");
    const departmentx = pathname.substring(indexie + 1);
    const doctorx = pathname.slice(13, indexie);
    var referral = "";
    
    useEffect(()=>{
        setInterval(()=>{
            viewAppointment();
        },100);
    },[]);


    useEffect(()=>{
        const metaDescription = document.querySelector('meta[name="description"]');

        if (typeof city !== 'undefined'){
            const descriptionContent = `Make an appointment around ${city} in order to visit a healthcare specialist by making an online appointment, `;

            if (metaDescription) {
                // Update existing meta description content
                metaDescription.setAttribute('content', descriptionContent);
            } else {
                // Create new meta description if it doesn't exist
                const meta = document.createElement('meta');
                meta.name = 'description';
                meta.description = metaDescription;
                document.getElementsByTagName('head')[0].appendChild(meta);
            }
        } else if (typeof city_ipdata !== 'undefined'){
            const descriptionContent = `Make an appointment around ${city_ipdata} in order to visit a healthcare specialist by making an online appointment, `;

            if (metaDescription) {
                // Update existing meta description content
                metaDescription.setAttribute('content', descriptionContent);
            } else {
                // Create new meta description if it doesn't exist
                const meta = document.createElement('meta');
                meta.name = 'description';
                meta.description = metaDescription;
                document.getElementsByTagName('head')[0].appendChild(meta);
            }
        }


    },[city, city_ipdata]);


    useEffect(()=>{

        if((departmentx == "") && (doctorx == "")){
            console.log('department or doctor parameters are not available.')
        }else if((departmentx != "") && (doctorx != "")){
            var re = /-/gi;
            var newDpt = departmentx.replace(re," ");
            var newDoc = doctorx.replace(re," ");
            console.log(newDpt);
            console.log(newDoc);
        }

        const db = new DB('family_hero');

        db.getDepartments().then((department)=>{
            setDepartment(department);
        });
        db.getSpecialists().then((specialist)=>{
            setSpecialists(specialist);
        });
        db.getAppointments().then((appointment)=>{
            setAppointment(appointment);
        });
    },[]);


    const departmentOptionChange = (e) => {
        setOptionDepartment(e.target.value);
    }

    const specialistOptionChange = (e) => {
        db.getSpecialists().then((specialist)=>{
            for(var i=0;i<specialist.length;i++){
                if((e.target.value) === specialist[i].name){
                    setSpecialistEmail(specialist[i].email);
                }
            }
        });
        setOptionSpecialists(e.target.value);
    }

    useEffect(() => {
        // Update the document's title when the continent changes
        document.title = `Make an appointment to see a healthcare specialist in ${city}.`;

    },[city]);


    const viewAppointment = () => {
        fetch("http://localhost:8080/view-appointment")
        .then((res) => res.json())
        .then((data) => {
            setAppointments(data);
        }).catch((err)=>{
            console.log(err);
        });
    }

    const userName = (userName) => {
        setName(userName)
    }

    const userEmail = (userEmail) => {
        setEmail(userEmail);
    }

    const saffairDate = (saffairDate) => {
        setDate(saffairDate)
    }

    const saffairTime = (saffairTime) => {
        setTime(saffairTime);
    }

    const snackBarClose = (event, reason) => {
        if(reason == 'clickaway'){
            return;
        }
        setSnackBarOpen(false);
    }

    const handleSnackbarClose = (event, reason) => {
        if(reason == 'clickaway'){
            return;
        }
        setAppointmentErrorSnackbarOpen(false)
    }

    const openingSnackBar = () => {
        setSnackBarOpen(true);
    }

    function backendAppointment(objAppointment){
        fetch('http://localhost:8080/appointment',{
            headers:{
                'Content-Type':'application/json'
            },
            method:'POST',
            body:JSON.stringify(objAppointment)
        }).then((res)=>{
            if(res.ok){
                console.log('user appointment creation a success.');
            }else if(!res.ok){
                console.log('user appointment creation not a success.');
            }
            //console.log(res);
        }).catch((err)=>{
            console.log(err);
        });
    }

     async function sendCommentToServer(comment){
        fetch('http://localhost:8080/user-comment',{
            headers:{
                'Content-Type':'application/json'
            },
            method:'POST',
            body:JSON.stringify(comment)
        }).then((data)=>{
            console.log(data);
        }).catch((err)=>{
            console.log(err);
        });
    }

    async function sendPatientCommentToServer(id,name,email,comment,department){
        fetch(`http://localhost:8080/patient-comment?id=${id}&name=${name}&email=${email}&comment=${comment}`)
            .then((res) => res.json())
            .then((data) => {
                //console.log(data);

                referral = "/appointment-details/" + id + "/" + department.replace(/\s+/g, '-').toLowerCase() + "/" + name.replace(/\s+/g, '-').toLowerCase() + "#" + data.message;

                setAppointmentMessage("Appointment's comment created, please wait while we're redirecting...");
                openingSnackBar(true);

                setTimeout(()=>{
                    window.location = referral
                },5000);

            }).catch((err) => {
                console.log(err);
        });
    }

    async function appointmentCommentPouchDB(e,id,department, name){
        e.preventDefault();
        const db = new DB('family_hero');
        var arrComment = [];
        if((comment !== '')){
            var objComment = {
                "appointmentId":id,
                "user":name,
                "date":new Date().toString(),
                "email":email,
                "image":"user/image.jpg",
                "comment":comment,
                "reason":"comment",
                "description":"appointment_details"
            }
            arrComment.push(objComment);
            db.createContact(arrComment)
              .then((res) => {
                 if((res[0].ok === true)){
                    //sendCommentToServer(objComment);
                    sendPatientCommentToServer(id,name,email,comment,department);
                    //setAppointmentMessage("Appointment's comment created!");
                    //openingSnackBar(true);

                    var commentBox = document.getElementById("commentPouchDB").value;
                    //console.log(commentBox);

                    //referral = "/appointment-details/"+id+"/"+department.replace(/\s+/g, '-').toLowerCase()+"/"+name.replace(/\s+/g, '-').toLowerCase();
                    
                 }
              }).catch((err) => {
                 console.log(err);
              });

              document.getElementById("commentPouchDB").value = "";

        }else{
            setAppointmentErrorSnackbarOpen(true);
            setErrorMessage("Please enter appointment's comment");
        }

        setTimeout(()=>{
            //if((referral !== "")){
                //window.location = referral
            //}
        },2000);
    }

    async function appointmentCommentMongoDB(e,id,department,name){
        e.preventDefault();
        const db = new DB('family_hero');
        var arrComment = [];
        if((comment !== '')){
            var objComment = {
                "appointmentId":id,
                "user":name,
                "date":new Date().toString(),
                "email":email,
                "image":"user/image.jpg",
                "comment":comment,
                "reason":"comment",
                "description":"appointment_details"
            }
            arrComment.push(objComment);
            db.createContact(arrComment)
              .then((res) => {
                 if((res[0].ok === true)){
                    //sendCommentToServer(objComment);
                    sendPatientCommentToServer(id,name,email,comment,department);

                    //setAppointmentMessage("Appointment's comment created!");
                    //openingSnackBar(true);

                    var commentBox = document.getElementById("commentMongoDB").value;
                    console.log(commentBox);

                    //referral = "/appointment-details/"+id+"/"+department.replace(/\s+/g, '-').toLowerCase()+"/"+name.replace(/\s+/g, '-').toLowerCase();
                    //console.log(referral);
                 }
              }).catch((err) => {
                 console.log(err);
              });

              document.getElementById("commentPouchDB").value = "";

        }else{
            setAppointmentErrorSnackbarOpen(true);
            setErrorMessage("Please enter appointment's comment");
        }

        setTimeout(()=>{
            //if((referral !== "")){
            //    window.location = referral
            //}
        },2000);
    }

    async function sendMessage(objMessage){
        (async () => {
            const message = await fetch('http://localhost:8080/message',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(objMessage)
            });
            const data = await message.json();
            console.log(data);
        })();
    }

    async function backendSignup(objUser){
        (async () => {
            const rawResponse = await fetch('http://localhost:8080/sign-up',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(objUser)
            });
            const content = await rawResponse.json();

            sessionStorage.setItem('token', content.token);
            sessionStorage.setItem('email', content.email);

            getSignedInUser(content.token,content.email);
        })();
    }

    async function appointmentUserAccount(objUser){
        (async () => {
            const rawResponse = await fetch('http://localhost:8080/appointment-user-account',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(objUser)
            });
            const content = await rawResponse.json();
        })();
    }

    async function getSignedInUser(token,email){
        (async () => {
            const rawResponse = await fetch(`http://localhost:8080/user/me?email=${email}`,{
                headers:{
                    'Content-Type':'application/json',
                    'token':token
                }
            });

            setLoading(false);
            setError(false);

            const content = await rawResponse.json();

            if((content.username === undefined) && (content.message === 'Invalid Token')){
                setMessage("Sign in or sign up to chat with a health worker on hero chat.");
            }else{
                setMessage("Welcome to african family hero.");
            }

        })();
    }

    function createAppointment(event){
        event.preventDefault();
        const db = new DB('family_hero');
        const data = [];

        setTimeout(()=>{
            if(name === ''){
                setAppointmentErrorSnackbarOpen(true);
                setErrorMessage("Name is not filled!");
            }else if(email === ''){
                setAppointmentErrorSnackbarOpen(true);
                setErrorMessage("Email is not filled!");
            }else if(message === ''){
                setAppointmentErrorSnackbarOpen(true);
                setErrorMessage("Date is not filled!");
            }else if(time === ''){
                setAppointmentErrorSnackbarOpen(true);
                setErrorMessage("Time is not filled!");
            }else if(optionDepartment === ''){
                setAppointmentErrorSnackbarOpen(true);
                setErrorMessage("Department is not chosen!");
            }else if(optionDepartment === 'no_option'){
                setAppointmentErrorSnackbarOpen(true);
                setErrorMessage("Department is not chosen!");
            }else if(optionSpecialists === 'no_doctor'){
                setAppointmentErrorSnackbarOpen(true);
                setErrorMessage("Specialist not chosen!");
            }else if(optionSpecialists === ''){
                setAppointmentErrorSnackbarOpen(true);
                setErrorMessage("Specialist not chosen!");
            }else if((name != "") && (email != "") && (message != "") && (time != "") && (optionDepartment != "") && (optionSpecialists != "")){

                const objAuthentication = {};
                const objSpecialistAuth = {};

                objAuthentication._id = email;
                objAuthentication.username = name;
                objAuthentication.handle = '@'+name.replace(/\s+/g, '-').toLowerCase();
                objAuthentication.email = email;
                objAuthentication.worker = 'patient';
                objAuthentication.date = new Date();
                objAuthentication.type = 'bot';
                objAuthentication.auth = 'sign_up';

                objSpecialistAuth._id = specialistEmail;
                objSpecialistAuth.username = optionSpecialists.replace(/-/gi," ");
                objSpecialistAuth.handle = '@'+optionSpecialists.replace(/\s+/g, '-').toLowerCase();
                objSpecialistAuth.email = specialistEmail;
                objSpecialistAuth.worker = 'specialist';
                objSpecialistAuth.date = new Date();
                objSpecialistAuth.type = 'bot';
                objSpecialistAuth.auth = 'sign_up';

                db.botSaveInfo(objAuthentication).then((res)=>{
                    if(res[0] === 'conflict'){                     
                        Swal.fire({
                            title:'Error',
                            text:'Try a different user, this one already exists!',
                            icon:'error',
                            confirmButtonText: `Continue&nbsp;<i class="fa fa-arrow-right"></i>`
                        });
                    }else if(res[0] === true){

                        const objMessage = {};
                        const objAppointment = {};

                        objAppointment.name = name;
                        objAppointment.type = 'appointment';
                        objAppointment.email = email;
                        objAppointment.message = message;
                        objAppointment.date = date;
                        objAppointment.time = time;
                        objAppointment.department = optionDepartment;
                        //objAppointment.specialist = optionSpecialists.replace(/\s+/g, '-').toLowerCase();
                        objAppointment.specialist = optionSpecialists;
                        objAppointment.specialistemail = specialistEmail;
                        objAppointment.createdAt = new Date().toString();
                        objAppointment.updatedAt = new Date().toString();

                        objMessage.username = optionSpecialists;
                        objMessage.image = "https://image.flaticon.com/icons/svg/327/327779.svg";
                        objMessage.side = 'left';
                        objMessage.type = 'bot';
                        //objMessage.from = 'search_result';
                        objMessage.from = specialistEmail;
                        objMessage.message = message;
                        //objMessage.latitude = null;
                        //objMessage.longitude = null;
                        objMessage.date = new Date().toString();
                        //objMessage.connection = 'search_result';

                        //sessionStorage.setItem('selected_chat_user',optionSpecialists.replace(/\s+/g, '-').toLowerCase());
                        sessionStorage.setItem('selected_chat_user','bot');

                        backendAppointment(objAppointment);
                        setAppointmentMessage('Appointment was successfully created!');
                        openingSnackBar(true);

                        backendSignup(objAuthentication);
                        appointmentUserAccount(objSpecialistAuth);

                        sendMessage(objMessage);

                        document.getElementById("creation_name").value = "";
                        document.getElementById("creation_email").value = "";
                        document.getElementById("creation_date").value = "";
                        document.getElementById("creation_time").value = "";
                        document.getElementById("creation_message").value = "";

                    }else if(res[0] === false){
                        Swal.fire({
                            title:'Error',
                            text:'Cannot sign up user!',
                            icon:'error',
                            confirmButtonText:"Cool"
                        });
                    }

                }).catch((err)=>{
                    console.log(err);
                });
            }
        },400);        
    }


    return(
        <div>

            <Helmet>
                <meta charSet="utf-8"/>
                {/*<meta name="description" content={("Make or follow an appointment in order to get a consultation from a health specialist by making an online appointment with health insurance allowed.").substring(0,150)}/>*/}
                <meta name="author" content="Sanjay Kumar"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://www.worldofhealthcare.co.za/appointment"/>
                <meta name="keywords" content="personal trainer,personal trainer near me,gym trainer,fitness trainer,trainers near me,planet fitness near me,fitness trainer near me,gym instructor,pregnancy insurance"/>
            </Helmet>
            <Topbar/>
            <br/><br/><br/><br/><br/>            
            <Box sx={{ flexGrow: 1}}>
                <Grid container spacing={3}>
                    <Grid item xs></Grid>
                    <Grid item xs={15}>
                        <div className="container-fluid py-5">
                            <div className="container">
                                <Grid container spacing={{ xs: 2, md: 3}} columns={{ xs: 4, sm: 8, md: 12}}>
                                    <Grid container item xs={2} sm={4} md={3}>
                                            {/*<AdsBody dataAdSlot="1086003152"/>*/}
                                    </Grid>
                                    <Grid container item xs={4} sm={4} md={6} variant="div">                                           
                                        <div className="text-center mx-auto mb-5" style={{ maxWidth:'500px'}}>
                                            <h2 className="d-inline-block text-primary text-uppercase border-bottom border-5">Medical Packages</h2>
                                            <h3 className="display-4">Awesome Medical Programs</h3>
                                        </div>
                                    </Grid>
                                    <Grid container item xs={6} sm={4} md={4}>
                                            {/*<AdsBody dataAdSlot="1086003152"/>*/}
                                    </Grid>
                                </Grid>
                                <div className="position-relative" style={{ padding: '0 45px 45px 45px',height: '276px' }}>
                                    <div className="bg-light rounded text-center">
                                        {/*<img className="img-fluid rounded-top" src="img/price-1.jpg" alt=""/>*/}
                                        <AdsAppointment dataAdSlot={"2789815528"}/>
                                        <div className="position-absolute w-100 h-100 top-50 start-50 translate-middle rounded-top d-flex flex-column align-items-center justify-content-center" style={{ background: 'rgba(29, 42, 77, .8)'}}>
                                            <h2 className="text-white">{'Pregnancy care, that allows any pregnancy insurance'}</h2>
                                            <h3 className="display-4 text-white mb-0">
                                                <small className="align-top fw-normal" style={{ fontSize: '22px', lineHeight: '45px'}}>R</small>10<small className='align-bottom fw-normal' style={{fontSize: '16px', lineHeight: '40px'}}>/ immediately</small>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid bg-primary my-5 py-5">
                            <div className="container py-5">
                                <div className="row gx-5">
                                    <div className="col-lg-6 mb-5 mb-lg-0">
                                        <div className="mb-4">
                                            <h2 className="d-inline-block text-white text-uppercase border-bottom border-5">{"African Family Hero"}</h2>
                                            <h3 className="display-4 text-white">{"Create, follow an appointment in order to get consultation."}</h3>
                                        </div>
                                        <p className="text-white mb-5 medium-font">{"Online doctor’s appointment are a nice way to have easy, convenient access to cheap health insurance, you can speak with your physician, schedule consultations, and get prescriptions without ever having to leave your home. When you need a doctor book online by using your best individual health insurance, we will then send a confirmation on email or sms to verify the appointment, location and details. You can also use hero chat to send direct messages to doctors and patients online."}</p>
                                        <a className="btn btn-dark rounded-pill py-3 px-5 me-3 medium-font" href="/chat">{"Go to HeroChat"}</a>
                                        <a className="btn btn-dark rounded-pill py-3 px-5 me-3 medium-font" href="/appointment/read-more">{"Read More"}</a>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="bg-white text-center rounded p-5">
                                            <h3 className="mb-4 large-font">{"Create an appointment"}</h3>
                                            <form>
                                                <div className="row g-3">
                                                    <div className="col-12 col-sm-6">
                                                        <select className="form-select bg-light border-0 medium-font" style={{ height: '55px'}} onChange={departmentOptionChange}>
                                                           <option name="no_department" value={"no_department"}>{"Choose department"}</option>
                                                            {department.map((item)=>{
                                                                return(
                                                                    <option value={item.department}>{item.department}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <select className="form-select bg-light border-0 medium-font" style={{ height: '55px'}} onChange={specialistOptionChange}>
                                                            <option name="no_doctor" value={"no_doctor"}>{"Choose doctor"}</option>
                                                            {specialists.map((item)=>{
                                                                return(
                                                                    <option value={item.name}>{item.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <input type="text" className="form-control bg-light border-0 medium-font" id="creation_name" placeholder="Your name" style={{ height: '55px'}} onChange={(e) => userName(e.target.value)}/>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <input type="email" className="form-control bg-light border-0 medium-font" id="creation_email" placeholder="Your email address" style={{ height: '55px'}} onChange={(e) => userEmail(e.target.value)}/>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="date medium-font" id="date" data-target-input="nearest">
                                                            <input type="date" id="creation_date"
                                                                className="form-control bg-light border-0 datetimepicker-input medium-font"
                                                                placeholder="Date" data-target="#date" data-toggle="datetimepicker" style={{ height: "55px"}} onChange={(e) => saffairDate(e.target.value)}/>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6">
                                                        <div className="time medium-font" id="time" data-target-input="nearest">
                                                            <input type="time" id="creation_time"
                                                                    className="form-control bg-light border-0 datetimepicker-input medium-font"
                                                                    placeholder="Time" data-target="#time" data-toggle="datetimepicker" style={{height:'55px'}} onChange={(e) => saffairTime(e.target.value)}/>  
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <textarea className="form-control bg-light border-0 medium-font" id="creation_message" placeholder="Type an appointment message" onChange={(e) => setMessage(e.target.value)}></textarea>
                                                    </div>
                                                    <div className="col-12">
                                                        <button className="btn btn-dark w-100 py-3 medium-font" onClick={(e) => createAppointment(e)} type="submit">{"Send an appointment"}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid py-5">
                            <div className="container">
                                <Grid container item spacing={{ xs: 2, md: 3}} columns={{ xs: 4, sm: 8, md: 12}}>
                                     <Grid container item xs={2} sm={4} md={3}>
                                            {/*<AdsBody dataAdSlot="1086003152"/>*/}
                                     </Grid>
                                     <Grid container item xs={4} sm={4} md={6} variant="div">
                                        <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px'}}>
                                            <h2 className="d-inline-block text-primary text-uppercase border-bottom border-5">{"Patient's messages"}</h2>
                                            <h3 className="display-4">{"Appointments made so far"}</h3>
                                        </div>
                                     </Grid>
                                     <Grid container item xs={6} sm={4} md={4}>
                                            {/*<AdsBody dataAdSlot="1086003152"/>*/}
                                     </Grid>
                                </Grid>
                                <div>
                                    {appointment.map((item)=>{
                                            return(
                                                 <div className="position-relative">
                                                    <div className="team-item">
                                                        {/*<div className="row g-0 bg-light rounded overflow-hidden">
                                                            <img className="img-fluid h-100" src={"img/team-1.jpg"} style={{ objectFit: 'cover'}}/>
                                                        </div>*/}
                                                        <a href={"/appointment-details/" + item._id + "/" + item.department.replace(/\s+/g, '-').toLowerCase() + "/" + item.specialist.replace(/\s+/g, '-').toLowerCase()} style={{textDecoration: 'none'}}>
                                                            <div className="col-12 col-sm-5 h-100 d-flex flex-column">
                                                                <div className="mt-auto p-4">
                                                                    <h3>{item.name}</h3>
                                                                    <h4 className="fw-normal fst-italic text-primary mb-4">{item.specialist}</h4>
                                                                    <p className="m-0 medium-font">{item.message}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                          
                                                     </div>
                                                     <div className="team-item">
                                                            <div className="col-12 col-sm-5 h-100 d-flex flex-column">
                                                                <div className="mt-auto p-4">
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control medium-font" id="commentPouchDB" onChange={(e) => setComment(e.target.value)} placeholder="Type a comment"/>
                                                                        <div className="input-group-prepend">
                                                                            <button className="btn btn-dark small-font" type="button" onClick={(e) => appointmentCommentPouchDB(e,item.id,item.department,item.specialist)}>Comment</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                     </div>
                                                 </div>

                                            );
                                        })}
                                  </div>
                                  <div>
                                    {appointments.map((item)=>{
                                            return(
                                                 <div className="position-relative">
                                                    <div className="team-item">
                                                        {/*<div className="row g-0 bg-light rounded overflow-hidden">
                                                            <img className="img-fluid h-100" src={"img/team-1.jpg"} style={{ objectFit: 'cover'}}/>
                                                        </div>*/}
                                                        <a href={"/appointment-details/" + item._id + "/" + item.department.replace(/\s+/g, '-').toLowerCase() + "/" + item.specialist.replace(/\s+/g, '-').toLowerCase()} style={{textDecoration: 'none'}}>
                                                            <div className="col-12 col-sm-5 h-100 d-flex flex-column">
                                                                <div className="mt-auto p-4">
                                                                    <h3>{item.name}</h3>
                                                                    <h4 className="fw-normal fst-italic text-primary mb-4">{item.specialist}</h4>
                                                                    <p className="m-0 medium-font">{item.message}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                          
                                                     </div>
                                                     <div className="team-item">
                                                            <div className="col-12 col-sm-5 h-100 d-flex flex-column">
                                                                <div className="mt-auto p-4">
                                                                    <div className="input-group mb-3">
                                                                            <input type="text" className="form-control" id="commentMongoDB" onChange={(e) => setComment(e.target.value)} placeholder="Type a comment"/>
                                                                            <div className="input-group-prepend">
                                                                                <button className="btn btn-dark" type="button" onClick={(e) => appointmentCommentMongoDB(e,item._id,item.department,item.specialist)}>Comment</button>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                     </div>
                                                 </div>

                                            );
                                        })}
                                  </div>                           
                                </div>
                        </div>
                        <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={snackBarClose}>
                            <Alert onClose={snackBarClose} severity="success" sx={{ width: '100%'}}>
                                {appointmentMessage}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={appointmentErrorSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                            <Alert onClose={handleSnackbarClose} severity="error" sx={{width:'100%'}}>
                                {errorMessage}
                            </Alert>
                        </Snackbar>
                    </Grid>
                    <Grid item xs>
                    </Grid>
                </Grid>
            </Box>
            <Footer/>
        </div>

    );
}

export default Pricing;