import React, { useEffect, useState } from 'react';
import { RiCloseLine } from "react-icons/ri";
import styles from '../main/css/user.module.css';
import UserList from '../main/UserList';
import { Observable } from 'rxjs';
import DB from '../../db.js';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref){
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props}/>;
});

const Users = ({ setIsOpenUser }) => {
    
      const [infoTime, setInfoTime] = useState("");
      const msgerChat = get(".msger-chat");
      const db = new DB('family_hero');
      const [openSnackbar, setOpenSnackbar] = useState(false);
      const [userIn, setUserIn] = useState(false);

      const chatObservable = new Observable((subscriber) =>{
          var username = sessionStorage.getItem('selected_chat_user');
          subscriber.next(username);
      });

      const dateObservable = new Observable((subscriber) => {
         subscriber.next(new Date());
      });

      const handleClose = () => {
          setOpenSnackbar(false);
      }

      function unappendMessage(){
            var divs = document.getElementsByClassName('msg');
            for(var i = divs.length - 1; 0 <= i; i--){
                if(divs[i] && divs[i].parentElement){
                    divs[i].parentElement.removeChild(divs[i]);
                }
            }
      }

      function appendMessage(username, img, side, message){

            const msgHTML = `
                <div class="msg ${side}-msg" id="chatMessage">
                <div class="msg-img" style="background-image: url(${img})"></div>

                <div class="msg-bubble">
                    <div class="msg-info">
                    <div class="msg-info-name">${username}</div>
                    <div class="msg-info-time">${infoTime}</div>
                    </div>

                    <div class="msg-text">${message}</div>
                </div>
                </div>
            `;

            msgerChat.insertAdjacentHTML("beforeend", msgHTML);
            msgerChat.scrollTop += 500;
      }

      function get(selector, root = document){
         return root.querySelector(selector);
      }

      const startMessaging = (event) => {
            event.preventDefault();
            var user = sessionStorage.getItem('email');
            const status = [];
            if(user !== null){

                var selectedUser = sessionStorage.getItem('selected_chat_user');

                (async () => {
                    const rawResponse = await fetch(`http://localhost:8080/handles?name=${selectedUser}`,{
                        headers:{
                            'Content-Type':'application/json'
                        }
                    });

                    const content = await rawResponse.json();
                    const message = {};
                    if((content.bot === 'bot')){
                        message.username = 'HeroChat';
                        message.image = 'https://image.flaticon.com/icons/svg/327/327779.svg';
                        message.side = 'left';
                        message.type = 'bot';
                        message.from = content.from;
                        message.message = 'Hi, welcome to HeroChat! Go ahead and send me a message';
                        message.date = new Date();
                    }else{
                        message.username = content.bot;
                        message.image = 'https://image.flaticon.com/icons/svg/327/327779.svg';
                        message.side = 'left';
                        message.type = 'bot';
                        message.from = content.from;
                        message.message = 'Hi, welcome to HeroChat! Go ahead and send me a message';
                        message.date = new Date();
                    }
                    const rawMessage = await fetch('http://localhost:8080/message',{
                        method:'POST',
                        headers:{
                            'Content-Type':'application/json'
                        },
                        body:JSON.stringify(message)
                    });

                    const messageResponse = await rawMessage.json();                    
                    if((messageResponse.message === 'Message Already Exists')){
                        console.log(messageResponse);
                        const data = {
                            date:messageResponse.content.date,
                            from:messageResponse.content.from,
                            image:messageResponse.content.image,
                            message:messageResponse.content.message,
                            side:messageResponse.content.side,
                            type:messageResponse.content.type,
                            username:messageResponse.content.username
                        };
                        unappendMessage();
                        appendMessage(data.username,data.image,data.side,data.message);
                        setIsOpenUser(false);
                    }else if((messageResponse.message === 'Message added')){
                        const data = {
                            date:messageResponse.content.date,
                            from:messageResponse.content.from,
                            image:messageResponse.content.image,
                            message:messageResponse.content.message,
                            side:messageResponse.content.side,
                            type:messageResponse.content.type,
                            username:messageResponse.content.username
                        };
                        unappendMessage();
                        appendMessage(data.username,data.image,data.side,data.message);
                        setIsOpenUser(false);
                    }
                })();

            }else{
                setOpenSnackbar(true);
            }
      }

      return(
        <>
          <div className={styles.darkBG} onClick={() => setIsOpenUser(false)} />
            <div className={styles.centered}>
                <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <h5 className={styles.heading}>Select a chat to start messaging</h5>
                </div>
                <button className={styles.closeBtn} onClick={() => setIsOpenUser(false)}>
                    <RiCloseLine style={{ marginBottom: "-3px" }} />
                </button>
                <div className={styles.modalContent}>
                    <UserList/>
                </div>
                <div className={styles.modalActions}>
                    <div className={styles.actionsContainer}>
                    <button className={styles.saveBtn} onClick={(event) => startMessaging(event)}>
                        Start
                    </button>
                    <button
                        className={styles.cancelBtn}
                        onClick={() => setIsOpenUser(false)}
                    >
                        Cancel
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%'}}>
                    {"Sign in or sign up to send and receive messages!"}
                </Alert>
            </Snackbar>
        </>
      );
}
export default Users;