import React, { useState, useEffect, Fragment } from 'react';
import Topbar from './Topbar';
import { Helmet } from 'react-helmet';
import DB from '../../db.js';
import Grid from '@mui/material/Grid';
import Footer from './Footer';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import '../../components/main/css/quiz.css';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Rating from '@mui/material/Rating';
import QuizModal from '../main/QuizModal';
import { useLocation } from 'react-router-dom';


const Alert = React.forwardRef(function Alert(props, ref){
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props}/>;
});
const Quiz = () => {


    const [questions, setQuestions] = useState([]);

    var [questionNumber, setQuestionNumber] = useState(1);
    var [playerScore, setPlayerScore] = useState(0);
    var [wrongAttempt, setWrongAttempt] = useState(4);

    const [displayQuestion, setDisplayQuestion] = useState("Where is world tallest building located ?");
    const [optionOne, setOptionOne] = useState("Africa");
    const [optionTwo, setOptionTwo] = useState("California");
    const [optionThree, setOptionThree] = useState("Dubai");
    const [optionFour, setOptionFour] = useState("Italy");

    const [answerErrorSnackbar, setAnswerErrorSnackbar] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [answer, setAnswer] = useState([]);
    const [courses, setCourses] = useState([]);
    const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    let [remark, setRemark] = useState(null);
    let [playerGrade, setPlayerGrade] = useState(null);


    const location = useLocation();
    const pathname = location.pathname;
    const indexie = pathname.lastIndexOf('/');
    const slug = pathname.substring(indexie + 1);
    const name = slug;
    const db = new DB('family_hero');


    useEffect(()=>{
        db.getAllQuestions().then((question)=>{
            setQuestions(question);           
        });
        db.getCourses().then((courses)=>{
            setCourses(courses);
        });
    },[]);

    const snackBarClose = (event, reason) => {
        if(reason == 'clickaway'){
            return;
        }
        setAnswerErrorSnackbar(false);
    }

    const selectAnswer = (e) => {
        e.preventDefault();

        var cl = document.getElementsByClassName('card-highlight');
        if((cl.length > 0)){
            cl[0].classList.remove('card-highlight');
        }

        var domTokenList = [];
        domTokenList.push(e.target.parentNode.classList);

        for(var i=0;i<domTokenList[0].length;i++){
            if(domTokenList[0][i] !== 'card-highlight'){
                e.target.parentNode.classList.add('card-highlight');
            }
        }

        var option = e.target.innerHTML;
        setAnswer(option);
    }

    let indexNumber = 0;
    let shuffledQuestions = [];

    /*const saveQuizToMongo = (objQuiz) => {
        fetch('http://localhost:8080/learning-quiz',{
            headers:{
                'Content-Type':'application/json'
            },
            method:'POST',
            body:JSON.stringify(objQuiz)
        }).then((res)=>{
            console.log(res);
        }).catch((err)=>{
            console.log(err);
        });
    }*/

    const handleNextQuestion = (event) => {
        event.preventDefault();
        while(shuffledQuestions.length <= 10){
            const random = questions[Math.floor(Math.random() * questions.length)];
            if(!shuffledQuestions.includes(random)){
                shuffledQuestions.push(random);
            }
        }


        db.getQuestion(answer).then((question)=>{
            if((question.length > 0)){
                for(var i=0;i < question.length;i++){
                    ///currentQuestionAnswer = question[i].correctOption;
                    if((answer === "")){
                        setAnswerErrorSnackbar(true);
                        setErrorMessage("Please choose an answer, before going forward!")
                    }else if((answer === question[i].correctOption)){

                        var currentQuestion = question[i].question;
                        playerScore++
                        //indexNumber++

                        setTimeout(()=>{

                            //console.log(indexNumber);
                            //console.log(questionNumber);

                            if((questionNumber <= 9)){

                                questionNumber++

                                const nextQuestion = shuffledQuestions[indexNumber];

                                setQuestionNumber(questionNumber);
                                setPlayerScore(playerScore);
                                setDisplayQuestion(nextQuestion.question);
                                setOptionOne(nextQuestion.optionA);
                                setOptionTwo(nextQuestion.optionB);
                                setOptionThree(nextQuestion.optionC);
                                setOptionFour(nextQuestion.optionD);

                                /*const objQuiz = {
                                    "course":name,
                                    "question":currentQuestion,
                                    "answer":answer,
                                    "score":"1",
                                    "total":playerScore
                                }*/

                                if(playerScore <= 3){
                                    setRemark("Bad Grades, Keep Practising.");
                                }else if(playerScore >= 4 && playerScore < 7){
                                    setRemark("Average Grades, You can do better.");
                                }else if(playerScore >= 7){
                                    setRemark("Excellent, Keep the good work going.");
                                }

                                var grade = (playerScore / 10) * 100;
                                setPlayerGrade(grade);

                                var cl = document.getElementsByClassName('card-highlight');
                                if((cl.length > 0)){
                                    cl[0].classList.remove('card-highlight');
                                }

                                //console.log(objQuiz);


                                //saveQuizToMongo(objQuiz);
                            }


                        },1000);

                    }else if((answer !== question[i].correctOption)){

                        var currentQuestion = question[i].question;
                        wrongAttempt++
                        //indexNumber++
                        
                        setTimeout(()=>{

                            //console.log(wrongAttempt);
                            //console.log(questionNumber);

                            if((questionNumber <= 9)){

                                questionNumber++

                                const nextQuestion = shuffledQuestions[indexNumber];

                                setQuestionNumber(questionNumber);
                                setPlayerScore(playerScore);
                                setDisplayQuestion(nextQuestion.question);
                                setOptionOne(nextQuestion.optionA);
                                setOptionTwo(nextQuestion.optionB);
                                setOptionThree(nextQuestion.optionC);
                                setOptionFour(nextQuestion.optionD);

                                if(playerScore <= 3){
                                    setRemark("Bad Grades, Keep Practising.");
                                }else if(playerScore >= 4 && playerScore < 7){
                                    setRemark("Average Grades, You can do better.");
                                }else if(playerScore >=7){
                                    setRemark("Excellent, Keep the good work going.");
                                }

                                var grade = (playerScore / 10) * 100;
                                setPlayerGrade(grade);

                                /*const objQuiz = {
                                    "course":name,
                                    "question":currentQuestion,
                                    "answer":answer,
                                    "score":"0",
                                    "total":playerScore
                                }*/


                                var cl = document.getElementsByClassName('card-highlight');
                                if((cl.length > 0)){
                                    cl[0].classList.remove('card-highlight');
                                }
                                
                                //console.log(objQuiz);
                                //saveQuizToMongo(objQuiz);
                            }

                        },1000);
                    }
                }
            }

            if((questionNumber === 10)){
                setIsOpen(true);
            }
        }).catch((err)=>{
            console.log(err);
        });    
    }


    const questionCard = (
        <Fragment>
            <CardContent>
                <Typography variant="h5" component="div">
                    {displayQuestion}
                </Typography>
            </CardContent>
        </Fragment>
    );

    const answerCardOne = (
        <Fragment>
            <CardContent className="highlight">
                <Typography variant="h5" component="div" onClick={selectAnswer} >
                    {optionOne}
                </Typography>
            </CardContent>
        </Fragment>
    );

    const answerCardTwo = (
        <Fragment>
            <CardContent className="highlight">
                <Typography variant="h5" component="div" onClick={selectAnswer}>
                    {optionTwo}
                </Typography>
            </CardContent>
        </Fragment>
    );

    const answerCardThree = (
        <Fragment>
            <CardContent className="highlight">
                <Typography variant="h5" component="div" onClick={selectAnswer}>
                    {optionThree}
                </Typography>
            </CardContent>
        </Fragment>
    );

    const answerCardFour = (
        <Fragment>
            <CardContent className="highlight">
                <Typography variant="h5" component="div" onClick={selectAnswer}>
                    {optionFour}
                </Typography>
            </CardContent>
        </Fragment>
    );
    
    return(
        <>
            <Helmet>
                <meta charSet="uft-8"/>
                <title>{"Quiz"}</title>
                <meta name="description" content={""}/>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="canonical" href="http://www.africanfamilyhero.co.za"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
            <Topbar/>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs></Grid>
                <Grid item xs={20}>
                    
                    <div className="container-xxl py-5">
                            <div className="container">
                            <div className="row justify-content-center position-relative" style={{marginTop:'-30px',marginRight:'-120px',zIndex:1,width:'100%'}}>
                                 <div className="col-lg-8">
                                    <div className="bg-white rounded p-5 m-5 mb-0">
                                        <div className="row g-3">
                                            <div className="col-12 col-sm-6">
                                                <h1>Score: {playerScore}/10</h1>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <h1>Question: {questionNumber}/10</h1>
                                            </div>
                                            <div className="col-20">
                                                <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center game-quiz-container">
                                                    <Box>
                                                        <Card variant="outlined">{questionCard}</Card>
                                                    </Box>
                                                </div>
                                                <br/>
                                                <div className="bg-light rounded d-flex flex-column aligh-items-center justify-content-center text-center game-question-container">
                                                    <Box>
                                                        <div className="row g-3" style={{marginLeft: '14%'}}>
                                                            <div className="col-5">
                                                                <Box>
                                                                    <Card variant="outlined">
                                                                        {answerCardOne}
                                                                    </Card>
                                                                </Box>
                                                            </div>
                                                            <div className="col-5">
                                                                <Box>
                                                                    <Card variant="outlined">
                                                                        {answerCardTwo}
                                                                    </Card>
                                                                </Box>
                                                            </div>
                                                            <div className="col-5">
                                                                <Box>
                                                                    <Card variant="outlined">
                                                                        {answerCardThree}
                                                                    </Card>
                                                                </Box>
                                                            </div>
                                                            <div className="col-5">
                                                                <Box>
                                                                    <Card variant="outlined">
                                                                        {answerCardFour}
                                                                    </Card>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop:'30px'}}>
                                                            <button className="btn btn-dark rounded-pill py-md-3 px-md-5 mx-2" onClick={handleNextQuestion}>{"Next Question"}</button>
                                                        </div>
                                                        <div>
                                                            {isOpen && 
                                                                <QuizModal 
                                                                        setIsOpen={setIsOpen}
                                                                        course={name} 
                                                                        remarks={remark} 
                                                                        grade={playerGrade} 
                                                                        score={playerScore} 
                                                                        wrong={wrongAttempt}
                                                                />}
                                                        </div>
                                                    </Box>
                                                    <Snackbar open={answerErrorSnackbar} autoHideDuration={6000} onClose={snackBarClose}>
                                                        <Alert onClose={snackBarClose} severity="error" sx={{width:'100%'}}>
                                                            {errorMessage}
                                                        </Alert>
                                                    </Snackbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Footer/>
        </>
    )
}

export default Quiz;