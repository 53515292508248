import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const DoctorFinderMap = (props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyD-HoLdt_x9ww_S-zg0GhAU--LuM8wHlU0",
  });

  const center = useMemo(() => ({ lat: 40.7128, lng: -74.006 }), []);
  const favoritePlace = { lat: 40.748817, lng: -73.985428 };

  useEffect(()=>{
  	if((props.lat === 0) && (props.lng === 0)){
  		console.log('props still has zero values.');
  	}else{
  		var latitude = new Number(props.lat).valueOf();
  		var longitude = new Number(props.lng).valueOf();

  		console.log(latitude);
  		console.log(longitude);
  	}
  });

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  return (
    <div className="App">
      {isLoaded ? (
        <GoogleMap
          center={center}
          mapContainerStyle={{
            width: "100%",
            height: "400px",
          }}
          zoom={15}
        >
          <Marker position={favoritePlace} />
        </GoogleMap>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default DoctorFinderMap;