import React, { useEffect } from 'react';
import Topbar from './Topbar';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { Helmet } from 'react-helmet';
import Footer from './Footer';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import user from '../main/images/user.jpg';
import DB from '../../db.js';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const LatestNews = () => {
    
    const [latestNews, setLatestNews] = React.useState([]);

    useEffect(()=>{
        const db = new DB('family_hero');
        db.getLatestPosts().then((latestPosts)=>{
            setLatestNews(latestPosts);
        });
    },[]);

    return(
        <div>
            
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{"Latest news"}</title>
                <meta name="description" content={"Latest african health news, browse through for more african health related articles, written by our author."}/>
                <meta name="author" content={"Sanjay Kumar"}/>
                <link rel="canonical" href="/latest-news"/>
                <meta name="viewport" content={"width=device-width, initial-scale=1.0"}/>
                <meta name="keywords" content={""}/>
            </Helmet>

            <Topbar/>
            <br/>
                <Grid container spacing={6}>               
                <div className="container-fluid my-5 py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px',marginTop:'65px'}}>
                            <h5 className="d-inline-block text-black text-uppercase border-bottom border-5">{"Latest news"}</h5>
                            <br/>
                            <h1 className="display-4 mb-4">{"Latest african health news"}</h1>
                            <h5 className="text-black fw-normal">{"Browse through for more african health related articles, written by our author."}</h5>
                            </div>
                        </div>
                    </div>
                    <Grid container spacing={{ xs:2, md: 3}} columns={{ xs: 4, sm: 8, md:12}} style={{marginLeft:'38px',width:'97%'}}>
                    {

                        latestNews.map((post)=>{
                            return(<Grid item xs={2} sm={4} md={4}>
                                <Card sx={{ minWidth: 275 }} variant="outlined" className="bg-light rounded overflow-hidden">
                                    <CardContent>

                                        <img
                                            className="img-fluid w-100"
                                            src={post.blogImage}
                                            style={{
                                                height: 255,
                                                width: "100%",
                                                maxWidth: 400,
                                                display: "block",
                                                overflow: "hidden",
                                            }}
                                        />
                                        <div className="p-4">
                                            <a href={"/latest-news/" + post.category.replace(/\s+/g, '-').toLowerCase() + "/"+ post.slug} className="h3 d-block mb-3">{post.blogTitle}</a>
                                            <p className="m-0">{post.blogBody1}</p>
                                        </div>
                                        <div className="d-flex justify-content-between border-top p-4">
                                            <div className="d-flex align-items-center">
                                                <img className="rounded-circle me-2" src={user} width="25" height="25"/>
                                                <small>{post.category}</small>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>                            
                            </Grid>);
                        })
                    }
            </Grid>
            </div>
            </Grid>
            <Footer/>
        </div>
        

    );
}

export default LatestNews; 