import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCjTn_xZNlbZVJYGJS9m8kRVTHCIEQdDQU",
    authDomain: "kidinchealth.firebaseapp.com",
    projectId: "kidinchealth",
    storageBucket: "kidinchealth.appspot.com",
    messagingSenderId: "802210410004",
    appId: "1:802210410004:web:0bc965538b98d78f3b0101",
    measurementId: "G-9WZDZ0QFZ0"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);