import React from 'react';
import Footer from './Footer';
import Topbar from './Topbar';
import { Helmet } from 'react-helmet';

const DoctorFinderApp = () => {

    return(
        <>
            <Helmet>
                <meta charSet='utf-8'/>
                <title>{"Mobile app"}</title>
                <meta name="description" content={""}/>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <meta name="canonical" href="/doctor-finder-app"/>
            </Helmet>
            <Topbar/>
            <br/><br/>
            <br/><br/>
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5" style={{maxWidth:'500px'}}>
                        <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">{"Doctor Finder App"}</h5>
                        <h1 className="display-4">{"View doctor and make appointments on your mobile device."}</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-xl-4 col-lg-6">

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default DoctorFinderApp;