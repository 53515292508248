import React, { useEffect, useState, useRef } from 'react';
import Topbar from '../main/Topbar';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import DB from '../../db.js';
import eInstructor from '../main/elearning/img/team-1.jpg';
import fbIcon from './images/social/instagram-icon.png';
import instagramIcon from './images/social/instagram-icon.png';
import twitterIcon from './images/social/twitter-icon.png';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import user from './images/user.jpg';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import './css/video.css';
import Container from '@mui/material/Container';
import CourseVideo from './videos/courses/Lets Build a Notes App with React + PouchDB - 1.mp4';
import ControlIcons from './videos/ControlIcons';
import Button from '@mui/material/Button';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AdsBody from './ads/AdsBody';
import "./css/slidingtext.css";
import useGeolocation from './useGeolocation';
import useGeolocationIPData from './useGeolocationIPData';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
}));
  
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const format = (seconds) => {
    if (isNaN(seconds)) {
      return '00:00'
    }
  
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
  
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`
    } else {
        return `${mm}:${ss}`
    }
};

const CourseDetails = () => {

    const [playerstate, setPlayerState] = useState({
        playing: true,
        muted: true,
        volume: 0.5,
        playerbackRate:1.0,
        played:0,
        seeking: false,
    });
    
    
    //Destructure State in other to get the values in it
    const { playing, muted, volume, playerbackRate, played, seeking } = playerstate;
    const playerRef = useRef(null);
    const playerDivRef = useRef(null);
    
    //This function handles play and pause onchange button
    const handlePlayAndPause = () => {
        setPlayerState({...playerstate, playing: !playerstate.playing})
    }
    
    const handleMuting = () => {
        setPlayerState({...playerstate, muted: !playerstate.muted})
    }
    
    const handleRewind = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
    }
    
    const handleFastForward = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30)
    }
    
    const handleVolumeChange = (e, newValue) => {
        setPlayerState({...playerstate, volume:parseFloat(newValue/100), muted:newValue === 0 ? true : false, });
    }
    
    const handleVolumeSeek = (e, newValue) => {
        setPlayerState({...playerstate, volume:parseFloat(newValue/100), muted:newValue === 0 ? true : false, });
    }
    
    const handlePlayerRate = (rate) => {
        setPlayerState({...playerstate, playerbackRate: rate});
    }
    
    const handleFullScreenMode = () => {
        screenfull.toggle(playerDivRef.current)
    }
    
    const handlePlayerProgress = (state) => {
        //console.log('onProgress', state);
        if (!playerstate.seeking) {
          setPlayerState({...playerstate, ...state});
        }
        //console.log('afterProgress', state);
    }
    
    const handlePlayerSeek = (e, newValue) => {
        setPlayerState({...playerstate, played: parseFloat(newValue / 100)});
        playerRef.current.seekTo(parseFloat(newValue / 100));
        // console.log(played)
    }
    
    const handlePlayerMouseSeekDown = (e) => {
        setPlayerState({...playerstate, seeking: true});
    }
    
    const handlePlayerMouseSeekUp = (e, newValue) => {
        setPlayerState({...playerstate, seeking: false});
        playerRef.current.seekTo(newValue / 100);
    }
    
    const currentPlayerTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
    const movieDuration =  playerRef.current ? playerRef.current.getDuration() : '00:00';
    const playedTime = format(currentPlayerTime);
    const fullMovieTime = format(movieDuration);

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const location = useLocation();
    const pathname = location.pathname;

    const indexie = pathname.lastIndexOf('/');
    const slug = pathname.substring(indexie + 1);
    const [course, setCourse] = useState([]);
    const [courses, setCourses] = useState([]);
    const [category, setCategory] = useState([]);
    const [instructor, setInstructor] = useState([]);
    const [scorm, setScorm] = useState([]);
    const [overview, setOverview] = useState([]);
    const [scormHeight, setScormHeight] = useState("");

    const [courseTitle, setCourseTitle] = useState("");
    const [canonicalSlug, setCanonicalSlug] = useState("");
    const { city, latitude, longitude, timezone, country_name } = useGeolocation();
    const { city_ipdata, latitude_ipdata, time_zone_ipdata, country_name_ipdata } = useGeolocationIPData();

    const [continent, setContinent] = useState('');

    const baseURL = "https://worldofhealthcare.co.za";


    const viewDetails = (event) => {
        event.preventDefault();
        window.location = baseURL+"/full-resource/"+slug;
    }

    const practiseQuiz = (event) => {
        event.preventDefault();
        window.location = baseURL + "/take-a-practise-quiz/"+slug;
    }

    useEffect(()=>{
        const db = new DB('family_hero');

        db.getCourseDetails().then((course)=>{
            const filteredData = course.filter((item)=>{
                return Object.values(item).join('').toLowerCase().includes(slug.toLowerCase());
            });
            setCourse(filteredData);
        });
        db.getCourseCategory().then((category)=>{
            setCategory(category);
        });
        db.getCourses().then((courses)=>{
            setCourses(courses);
        });
        db.getInstructors().then((instructor)=>{
            setInstructor(instructor);
        });
        db.getScorm(slug).then((scorm)=>{
            setScorm(scorm);
            scorm.map((x)=>{
                if(x.height !== undefined){
                    setScormHeight(x.height);
                }
            });
        });
        db.getCourseOverview(slug).then((overview)=>{
            setOverview(overview);
        });

        course.map((i)=>{         
            
            //setCourseTitle(i.title);
            //setCanonicalSlug(i.title.replace(/\s+/g, '-'));

            const metaDescription = document.querySelector('meta[name="description"]');

            const descriptionContent = i.title;

            if (metaDescription) {
                // Update existing meta description content
                metaDescription.setAttribute('content', descriptionContent);
            } else {
                // Create new meta description if it doesn't exist
                const meta = document.createElement('meta');
                meta.name = 'description';
                meta.description = metaDescription;
                document.getElementsByTagName('head')[0].appendChild(meta);
            }
 
            document.title = i.title;

            // Check if a canonical link already exists
            let canonicalLink = document.querySelector('link[rel="canonical"]');

            if (canonicalLink) {
                // Update the href if the canonical link exists
                canonicalLink.href = "https://worldofhealthcare.co.za/course-details"+i.title.replace(/\s+/g, '-');
            } else {
                // Create a new canonical link if it doesn't exist
                canonicalLink = document.createElement('link');
                canonicalLink.setAttribute('rel', 'canonical');
                canonicalLink.setAttribute('href', "https://worldofhealthcare.co.za/course-details/"+i.title.replace(/\s+/g, '-'));
                document.head.appendChild(canonicalLink);
            }
         });

    },[]); // Runs whenever continent or city changes


    return(
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                {/*<title>{courseTitle}</title>*/}
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                {/*<link rel="canonical" href={"http://worldofhealthcare.co.za/course-details/"+canonicalSlug}/>*/}
            </Helmet>
            <Topbar/>
            <br/><br/>
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-8">
                        <div className="mb-5" style={{ marginTop: '16px'}}>
                            {
                                course.map((i)=>{
                                    return(
                                        <>
                                            <img className="img-fluid w-100 rounded mb-5" src={""} alt={""}/>
                                            <h1 className="mb-4">{i.title}</h1>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
                                                <Container maxWidth="md">
                                                    <div className="playerDiv" ref={playerDivRef}>
                                                        <ReactPlayer width={'100%'} height={'100%'}
                                                            url={CourseVideo}
                                                            playing={playing}
                                                            volume={volume} 
                                                            playbackRate={playerbackRate}
                                                            onProgress={handlePlayerProgress}
                                                            muted={muted}
                                                            ref={playerRef}
                                                        />
                                                        <ControlIcons
                                                            key={volume.toString()}
                                                            playandpause={handlePlayAndPause}
                                                            playing={playing}
                                                            rewind={handleRewind}
                                                            fastForward={handleFastForward}
                                                            muting={handleMuting}
                                                            muted={muted}
                                                            volumeChange={handleVolumeChange}
                                                            volumeSeek={handleVolumeSeek}
                                                            volume={volume}
                                                            playerbackRate={playerbackRate}
                                                            playRate={handlePlayerRate}
                                                            fullScreenMode={handleFullScreenMode}
                                                            played={played}
                                                            onSeek={handlePlayerSeek}  
                                                            onSeekMouseUp={handlePlayerMouseSeekUp}
                                                            onSeekMouseDown={handlePlayerMouseSeekDown}
                                                            playedTime={playedTime}
                                                            courseTitle={i.title}
                                                            fullMovieTime={fullMovieTime}
                                                            seeking={seeking}
                                                            />
                                                    </div>
                                                </Container>
                                            </Box>
                                            <div className="d-flex justify-content-between bg-light p-4 mt-4 mb-4">
                                                <div className="d-flex align-items-center medium-font">
                                                    <img className="rounded-circle me-2" src={user} width="40" height="40" alt={""}/>
                                                    <span>{i.category}</span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                {
                                                    overview.map((i)=>{
                                                        return(
                                                            <p className={"medium-font"}>{i.text}</p>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="container py-5">
                                               {
                                                  scorm.map((i)=>{
                                                      return(
                                                         <>
                                                            <Accordion expanded={expanded === 'panel'+i.panel} onChange={handleChange('panel'+i.panel)}>
                                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                            <Typography>{i.title}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                            <Typography>
                                                                {i.body}
                                                                <hr/>
                                                                <Button variant="outlined" startIcon={<SummarizeIcon />} onClick={viewDetails}>
                                                                    {"Full resource"}
                                                                </Button>
                                                                <Button variant="outlined" startIcon={<SummarizeIcon/>} onClick={practiseQuiz} style={{margin:'10px'}}>
                                                                    {"Practice test"}
                                                                </Button>
                                                            </Typography>
                                                            </AccordionDetails>
                                                            </Accordion>
                                                         </>
                                                      )
                                                  })
                                               }
                                            </div>
                                        </>
                                    );
                                })
                            }
                        </div>
                        <div className="mb-5">
                            <div className="col-lg-4"
                                style={{
                                    marginLeft: '112%',
                                    marginTop: scormHeight
                                }}
                            >
                                <div className="mb-5">
                                    {
                                        <div className="input-group">
                                            {course.map((i)=>{
                                                    return(
                                                        <a href={"/take-a-quiz/"+ i.slug} className="btn btn-primary px-3 small-font"><i className="fa fa-search">{"Take a test"}</i></a>   
                                                    )
                                            })}
                                        </div>
                                    }                                        
                                </div>
                                <div className="mb-5">
                                        <AdsBody dataAdSlot="1086003152"/>
                                </div>
                                <div className="mb-5">
                                    <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 mb-4">{"Categories"}</h4>
                                    <div className="d-flex flex-column justify-content-start">
                                        {
                                            category.map((i)=>{
                                                return(
                                                    <a href={"/course-details/"+i.name.replace(/\s+/g, '-').toLowerCase()} className="h5 bg-light rounded py-2 px-2 mb-2" style={{textDecoration:'none'}}>{i.name}</a>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 mb-4">{"Recent courses"}</h4>
                                    {
                                        courses.slice(0,4).map((i)=>{
                                            return(
                                                <div className="d-flex rounded overflow-hidden mb-3">
                                                    <a href={"/course-details/" + i.title.replace(/\s+/g, '-').toLowerCase()} className="h5 d-flex align-items-center bg-light px-3 mb-0" style={{textDecoration:'none'}}>{i.title}</a>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div className="mb-5">
                                    <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 mb-4">{"Tag Cloud"}</h4>
                                    <div className="d-flex flex-wrap m-n1">
                                        <a href={""} className="btn btn-primary m-1">{"keyword"}</a>
                                        <a href={""} className="btn btn-primary m-1">{"keyword"}</a>
                                        <a href={""} className="btn btn-primary m-1">{"keyword"}</a>
                                        <a href={""} className="btn btn-primary m-1">{"keyword"}</a>
                                        <a href={""} className="btn btn-primary m-1">{"keyword"}</a>
                                    </div>
                                </div>
                                <div>
                                    <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 mb-4">{"Instructor"}</h4>
                                    <div className="bg-light rounded text-center">
                                        <div className="overflow-hidden">
                                            <img className="img-fluid" src={eInstructor} alt={""}/>
                                        </div>
                                        <div className="position-relative d-flex justify-content-center" style={{marginTop: '-23px'}}>
                                            <div className="bg-light d-flex justify-content-center pt-2 px-1">
                                                <a className="btn btn-sm-square btn-primary mx-1" href="">
                                                    <i className="fab fa-facebook-f" style={{marginLeft: '-19px',marginTop: '-5px'}}>
                                                        <img src={fbIcon} style={{marginTop: '-13px'}}/>
                                                    </i>
                                                </a>
                                                <a className="btn btn-sm-square btn-primary mx-1" href="">
                                                    <i className="fab fa-twitter" style={{marginLeft: '-19px',marginTop: '-5px'}}>
                                                        <img src={twitterIcon} style={{marginTop: '-13px'}}/>
                                                    </i>
                                                </a>
                                                <a className="btn btn-sm-square btn-primary mx-1" href="">
                                                    <i className="fab fa-instagram" style={{marginLeft: '-19px',marginTop: '-5px'}}>
                                                        <img src={instagramIcon} style={{marginTop: '-13px'}}/>
                                                    </i>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="text-center p-4">
                                            <h5 className="mb-0">{"Instructor name"}</h5>
                                            <small>{"Designation"}</small>
                                        </div>
                                    </div>
                                </div>
                        </div>
                      </div>
                    </div>    
                </div>    
            </div>
            <Footer/>
        </div>
    )
}

export default CourseDetails;