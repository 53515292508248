import * as React from 'react';
import fbIcon from './images/social/fb-icon.png';
import linkedIcon from './images/social/linkedin-icon.png';
import twitterIcon from './images/social/twitter-icon.png';
import instagramIcon from './images/social/instagram-icon.png';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import DB from '../../db.js';
import "./css/slidingtext.css";

const Alert = React.forwardRef(function Alert(props, ref){
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Footer = () => {

    const [email, setEmail] = React.useState('');
    const [openMembership, setOpenMembership] = React.useState(false);
    const [openMembershipError, setOpenMembershipError] = React.useState(false);

    const handleClose = (event) => {
        event.preventDefault();
        setOpenMembership(false);
    }

    const handleCloseError = (event) => {
        event.preventDefault();
        setOpenMembershipError(false)
    }


    function backendSubscribeUser(objUser){
        fetch('http://localhost:8080/newsletter',{
            headers:{
                'Content-Type':'application/json'
            },
            method:'POST',
            body:JSON.stringify(objUser)
        }).then((res)=>{
            if(res.ok){
                console.log('newsletter creation a success.');
            }else if(!res.ok){
                console.log('newsletter creation not a success');
            }
        }).catch((err)=>{
            console.log(err);
        });
    }

    function signUp(e){
            e.preventDefault();
            if(email === ""){
                setOpenMembershipError(true);
            }else if(email !== ""){
                const arrNewsletter = [];
                const objNewsletter = {
                    "_id": email,
                    "email": email,
                    "type":"newsletter"
                } 
                arrNewsletter.push(objNewsletter);
                const db = new DB('family_hero');   
                db.createNewsletter(arrNewsletter);
                backendSubscribeUser(objNewsletter);
                setOpenMembership(true);

                document.getElementById('newsletterAddress').value = '';
            }
    }

    return(
        <>
            <div className="container-fluid bg-dark text-light mt-5 py-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h2 className="d-inline-block text-primary text-uppercase border-bottom border-secondary mb-4">{"Get In Touch"}</h2>
                            <p className="mb-4 large-font">{"Information about how to contact us, our location, getting to us and where to park once you're here."}</p>
                            <h2 className="mb-2 text-white"><i className="fa fa-map-marker-alt text-primary me-3"></i>{"Granada Centre, 3 Corlett Dr, Illovo, 2196, Andiccio24"}</h2>
                            <p className="mb-2 medium-font"><i className="fa fa-envolepe text-primary me-3"></i>{"lesiba.less@gmail.com"}</p>
                            <p className="mb-0 medium-font"><i className="fa fa-phone-alt text-primary me-3"></i>{"+27 78 834 6604"}</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h3 className="d-inline-block text-primary text-uppercase border-bottom border-5 border-secondary mb-4">{"Quick Links"}</h3>
                            <div className="d-flex flex-column justify-content-start">
                                <a className="text-light mb-2 medium-font" href="/" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"Home"}</a>
                                <a className="text-light mb-2 medium-font" href="/about" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"About Us"}</a>
                                <a className="text-light mb-2 medium-font" href="/find-a-doctor" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"Find A Doctor"}</a>
                                {/*<a className="text-light mb-2 medium-font" href="/pricing"><i className="fa fa-angle-right me-2"></i>{"Pricing"}</a>*/}
                                <a className="text-light mb-2 medium-font" href="/view-courses" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"Learn"}</a>
                                <a className="text-light medium-font" href="/contact" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"Contact Us"}</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 border-secondary mb-4">{"Popular Links"}</h4>
                            <div className="d-flex flex-column justify-content-start">
                                <a className="text-light mb-2 medium-font" href="/doctor-finder-app" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"Doctor Finder App"}</a>
                                {/*<a className="text-light mb-2 medium-font" href="/meet-the-team"><i className="fa fa-angle-right me-2"></i>{"Meet The Team"}</a>*/}
                                <a className="text-light mb-2 medium-font" href="/sitemap" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"Sitemap"}</a>
                                <a className="text-light mb-2 medium-font" href="/appointment" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"Appointment"}</a>
                                {/*<a className="text-light mb-2 medium-font" href="/join-now" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"Join Now"}</a>*/}
                                <a className="text-light mb-2 medium-font" href="/scorecard" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"Scorecard"}</a>
                                <a className="text-light mb-2 medium-font" href="/chat" style={{textDecoration:'none'}}><i className="fa fa-angle-right me-2"></i>{"HeroChat"}</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 border-secondary mb-4">{"Newsletter"}</h4>
                            <form action="">
                                <div className="input-group">
                                    <input type="text" className="form-control p-3 border-0 medium-font" placeholder="Your Email Address" id="newsletterAddress" onChange={(e) => setEmail(e.target.value)}/>
                                    <button className="btn btn-primary small-font" onClick={(e) => signUp(e)}>{"Sign Up"}</button>
                                </div>
                            </form>
                            <h5 className="text-primary text-uppercase mt-4 mb-3">{"Follow Us"}</h5>
                            <div className="d-flex">
                                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="#">
                                    <i className="fab fa-twitter" style={{marginLeft: '-13px',marginTop: '-5px'}}>
                                        <img src={twitterIcon} style={{marginTop: '-5px'}}/>
                                    </i>
                                </a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="#">
                                    <i className="fab fa-facebook-f" style={{marginLeft: '-13px'}}>
                                        <img src={fbIcon} style={{marginTop: '-5px'}}/>
                                    </i>
                                </a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="#">
                                    <i className="fab fa-linkedin-in" style={{marginLeft: '-13px'}}>
                                        <img src={linkedIcon} style={{marginTop: '-5px'}}/>
                                    </i>
                                </a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle" href="#">
                                    <i className="fab fa-instagram" style={{marginLeft: '-13px'}}>
                                        <img src={instagramIcon} style={{marginTop: '-5px'}}/>
                                    </i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-dark text-light border-top border-secondary py-4">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-md-6 text-center text-md-start">
                            <p className="mb-md-0 small-font">&copy; <a href={"https://worldofhealthcare.co.za"} className="text-primary" style={{textDecoration:'none'}}>{"Kid, Inc"}</a> {"All Rights Reserved."}</p>
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <p className="mb-0 small-font">Developed by <a className="text-primary" href="https://www.facebook.com/lesibaless/" style={{textDecoration:'none'}}>{"Lesiba"}</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={openMembership} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%'}}>
                    {"Your newsletter subscription was successful!"}
                </Alert>
            </Snackbar>
            <Snackbar open={openMembershipError} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%'}}>
                    {"Please put your email address in order to receive a newsletter!"}
                </Alert>
            </Snackbar>
        </>
    );

}

export default Footer;