import React from 'react';
import {Helmet} from 'react-helmet';
import Topbar from './Topbar';
import Footer from './Footer';

const ReadMore = () => {

    return(
        <>
            <Helmet>
                <meta charSet="uft-8"/>
                <title>{"How do appointments work"}</title>
                <meta name="author" content={"Sanjay Kumar"}/>
                <link rel="canonical" href="http://www.africanfamilyhero.co.za/appointment/read-more"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
            <Topbar/>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h2 className="section-title bg-white text-center text-primary px-3" style={{marginTop: '100px'}}>{"Appointments"}</h2>
                        <h2 className="mb-5" style={{marginTop: '15px'}}>{"How do appointments work"}</h2>
                        <p>{"Tempor erat elitr at rebum at at clita aliquyam consetetur. Diam dolor diam ipsum et, tempor voluptua sit consetetur sit. Aliquyam diam amet diam et eos sadipscing labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor consetetur takimata eirmod, dolores takimata consetetur invidunt magna dolores aliquyam dolores dolore. Amet erat amet et magna"}</p>
                        <p>{"Tempor erat elitr at rebum at at clita aliquyam consetetur. Diam dolor diam ipsum et, tempor voluptua sit consetetur sit. Aliquyam diam amet diam et eos sadipscing labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor consetetur takimata eirmod, dolores takimata consetetur invidunt magna dolores aliquyam dolores dolore. Amet erat amet et magna"}</p>
                        <p>{"Tempor erat elitr at rebum at at clita aliquyam consetetur. Diam dolor diam ipsum et, tempor voluptua sit consetetur sit. Aliquyam diam amet diam et eos sadipscing labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor consetetur takimata eirmod, dolores takimata consetetur invidunt magna dolores aliquyam dolores dolore. Amet erat amet et magna"}</p>
                        <p>{"Tempor erat elitr at rebum at at clita aliquyam consetetur. Diam dolor diam ipsum et, tempor voluptua sit consetetur sit. Aliquyam diam amet diam et eos sadipscing labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor consetetur takimata eirmod, dolores takimata consetetur invidunt magna dolores aliquyam dolores dolore. Amet erat amet et magna"}</p>

                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );

}

export default ReadMore;