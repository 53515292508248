import React,{ useEffect, useState } from 'react';
import Topbar from '../main/Topbar';
import Footer from './Footer';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import './css/details.css';
import Hero from './images/doctor/hero.png';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import {Helmet} from 'react-helmet';
import DB from '../../db.js';
import AdsFinder from './ads/AdsFinder';
import "./css/slidingtext.css";
import useGeolocation from './useGeolocation';
import useGeolocationIPData from './useGeolocationIPData';

const Details = () => {

    const [specialists, setSpecialists] = useState([]);

    const location = useLocation();
    const pathname = location.pathname;

    const indexie = pathname.lastIndexOf("/");
    const category = pathname.substring(9 , indexie);
    const doctor = pathname.substring(indexie + 1);

    const { city, latitude, longitude, timezone, country_name } = useGeolocation();
    const { city_ipdata, latitude_ipdata, time_zone_ipdata, country_name_ipdata } = useGeolocationIPData();

    const [continent, setContinent] = useState('');

    // title & description metadata
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (typeof timezone !== 'undefined') {
          const extractedContinent = timezone.split('/')[0];
          setContinent(extractedContinent);
        } else {
            if (typeof time_zone_ipdata !== 'undefined') {
                const extractedContinent = time_zone_ipdata.name.split('/')[0];
                setContinent(extractedContinent);
            }
        }
    }, [timezone, time_zone_ipdata]); // This ensures the effect runs only when 'timezone' changes


    useEffect(()=>{
        const db = new DB('family_hero');
        db.getBiographies().then((biographies)=>{
            const filteredData = biographies.filter((item)=>{
                return Object.values(item).join('').toLowerCase().includes(doctor.toString().toLowerCase());
            });
            setSpecialists(filteredData);
            filteredData.map((doctor)=>{

                document.title = `${continent} family hero ` + doctor.specialists + ' - ' + doctor.department;
                
                const metaDescription = document.querySelector('meta[name="description"]');

                const descriptionContent = doctor.bio;

                if (metaDescription) {
                    // Update existing meta description content
                    metaDescription.setAttribute('content', descriptionContent);
                } else {
                    // Create new meta description if it doesn't exist
                    const meta = document.createElement('meta');
                    meta.name = 'description';
                    meta.description = metaDescription;
                    document.getElementsByTagName('head')[0].appendChild(meta);
                }

                // Check if canonical link already exists
                let canonicalLink = document.querySelector('link[rel="canonical"]');

                if (canonicalLink) {
                    // Update the href if the canonical link exists
                    canonicalLink = "https://worldofhealthcare.co.za/" + pathname;
                } else {
                    // Create a new canonical link if it doesn't exist
                    canonicalLink = document.createElement("link");
                    canonicalLink.setAttribute('rel', 'canonical');
                    canonicalLink.setAttribute('href', 'https://worldofhealthcare.co.za/' + pathname);
                    document.head.appendChild(canonicalLink);
                }

            });
        });
    },[continent]);




    return(
        <div>

            <Helmet>
                <meta charSet="utf-8"/>
                {/*<title>{title}</title>*/}
                {/*<meta name="description" content={description.substring(0, 150)}/>*/}
                <meta name="author" content="Sanjay Kumar"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                {/*<link rel="canonical" href="http://www.africanfamilyhero.co.za/details/:department/:specialist"/>*/}
            </Helmet>
            <Topbar/>
            <br/><br/><br/><br/><br/>            
            <Box sx={{ flexGrow: 1}}>
                <section id="about-section" className="pt-5 pb-5">
                    {specialists.map((doc)=>{
                        return(
                            <div className="container wrapabout">
                                <div className="red"></div>
                                    <div className="row">
                                    <div className="col-lg-6 align-items-center justify-content-left d-flex mb-5 mb-lg-0">
                                        <div className="blockabout">
                                            <div className="blockabout-inner text-center text-sm-start">
                                                <div className="title-big pb-3 mb-3">
                                                    <h2 className="large-font">{doc.specialists}</h2>
                                                    <h2 className="small-font">{doc.department}</h2>
                                                </div>
                                                <p className="description-p text-muted pe-0 pe-lg-0 medium-font">
                                                    {doc.bio}
                                                </p>
                                                <p className="description-p text-muted pe-0 pe-lg-0 medium-font">
                                                    {doc.certificates}
                                                </p>
                                                <Typography className="m-0 small-font">{"Availability:"}</Typography>
                                                <Rating name="customized-10" defaultValue={doc.learning_score} max={10} readOnly/>
                                                <hr/>
                                                <div className="row">
                                                    <a href={"/appointment/" + doc.department.replace(/\s+/g, '-').toLowerCase() + "/" + doc.specialists.replace(/\s+/g, '-').toLowerCase()} className="btn btn-primary medium-font">{"Make an appointment"}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-5 mt-lg-0">
                                        <figure className="potoaboutwrap">
                                            <AdsFinder dataAdSlot="1086003152"/>
                                        </figure>
                                    </div>                                   
                                </div>
                            </div>                           
                        )
                    })}
                </section>
            </Box>
            <br/><br/><br/><br/><br/>  
            <Footer/>
        </div>
    )
}

export default Details;