import React, { useEffect } from 'react';

const AdsPost = (props) => {
    const { adSlot } = props;
    const { image } = props;

    useEffect(()=>{
        try{
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }catch (e){
            console.log(e);
        }

    },[]);

    return (
        <>
                <ins className="adsbygoogle"
                    style={{display: "block"}}
                    data-ad-client="ca-pub-2340613167692358"
                    data-ad-slot={adSlot}
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>        
        </>
    )
}

export default AdsPost;