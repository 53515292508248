import React from 'react';
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import Home from '../../pages/home/Home';
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";

const Dashboard = () => {
    return(
        <div>
            <Topbar/>            
            <Sidebar/>
        </div>
    );
}

export default Dashboard;