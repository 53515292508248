import React from 'react';
import Topbar from './../main/Topbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {Helmet} from 'react-helmet';
import Footer from  './Footer';
import { useLocation } from 'react-router-dom';

const Instructor = () => {

    const location = useLocation();
    const pathname = location.pathname;
    const indexie = pathname.lastIndexOf('/');
    const slug = pathname.substring(indexie + 1);
    const instructor = slug.replace("-"," ");

    return(
        <div>
            <Helmet>
                <meta charSet="uft-8"/>
                <title>{"Instructor"}</title>
                <meta name="author" content="Sanjay Kumar"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://www.africanfamilyhero.co.za/instructor"/>
            </Helmet>
            <Topbar/>
            <div>
            <br/>
            <Box sx={{ flexGrow: 1}}>
                <Grid container spacing={3}>
                </Grid>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h2 className="text-center wow fadeInUp" data-wow-delay="0.1s">{instructor}</h2>
                        </div>
                    </div>
                </div>
            </Box>
            <Footer/>
            </div>
        </div>
    );
}

export default Instructor;