import React, { useEffect } from 'react';
import Topbar from './Topbar';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { styled, useTheme, alpha } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import {Helmet} from "react-helmet";
import user from './images/user.jpg';
import { render } from '@testing-library/react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
};
  
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const LatestNewsDetails = () => {

  
    const [department, setDepartment] = React.useState([]);
    const [recentPosts, setRecentPosts] = React.useState([]);
    const [tagPosts, setTagPosts] = React.useState([]);
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [comments, setComments ] = React.useState([]);
    const [filteredResults, setFilteredResults] = React.useState([]);
    const [filteredResultsComments, setFilteredResultsComments] = React.useState([]);
    const [commentsLength, setCommentsLength] = React.useState('');
    const [postKeyword, setPostKeyword] = React.useState([]);
    const [specialist, setSpecialist] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [dpt, setDpt] = React.useState([]);
    const keywordY = []; 

    const location = useLocation();
    const pathname = location.pathname;

    const indexie = pathname.lastIndexOf("/");
    const slug = pathname.substring(indexie + 1);

    const category = pathname.slice(7, indexie);

    // seo details
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    }

    const snackBarClose = (event, reason) => {
        if(reason == 'clickaway'){
            return;
        }
        setSnackBarOpen(false);
    }

    const openSnackBar = () => {
        setSnackBarOpen(true);
    }



    const submit = (event) => {
        event.preventDefault();

        const baseURL = "http://localhost:5000/view/medical-student/create";

        const objStudent = {
            "name":username,
            "email":email,
            "department":dpt,
            "message":message,
            "slug":slug
        };

        try{
            fetch(baseURL,{
                headers:{
                    "Content-Type":"application/json"
                },
                method:"POST",
                body: JSON.stringify(objStudent)
            }).then((response)=>{
                if(response.status === 200){
                    openSnackBar();
                    handleClose();
                }                
            })
        }catch(err){
            console.log(err);
        }

        //alert(username + ' ' + email + ' ' + dpt + ' ' + message);
    }




    React.useEffect(() => {
        
        
        fetch('http://localhost:5000/view/latest-news')
        .then((res)=>res.json())
        .then((data)=>{
            const filteredData = data.filter((item)=>{
                return Object.values(item).join('').toLowerCase().includes(slug.toLowerCase());
            });

            filteredData.map((post)=>{
                setTitle(post.blogTitle);
                setDescription(post.blogBody1.substring(0,150));
            })
            //setFilteredResults(filteredData);

        }).catch((err)=>{
            console.log(err);
        });


        fetch('http://localhost:5000/view/all-students')
        .then((res) => res.json())
        .then((data)=>{
            if(data.length > 0){
                const filteredData = data.filter((item)=>{
                    return Object.values(item).join('').toLowerCase().includes(slug.toLowerCase());
                });
                setSpecialist(filteredData);
                //console.log(specialist);
            }
        })
        .catch((err)=>{
            console.log(err);
        });

        fetch('http://localhost:5000/view/department')
            .then((res)=> res.json())
            .then((data) =>{
                //console.log(data)
                setDepartment(data);
            })
            .catch((err)=>{
                console.log(err.message);
        });
        fetch('http://localhost:5000/view/latest-news')
            .then((res)=>res.json())
            .then((data)=>{
                if(data.length > 0){
           
                    const filteredData = data.filter((item)=>{
                         return Object.values(item).join('').toLowerCase().includes(slug.toLowerCase());
                    });

                    setFilteredResults(filteredData);

                    var fullText = "";
                    var sidebarMaxHeight = "";

                    filteredData.map((post)=>{

                        fullText = post.blogBody1 + post.blogBody2 + post.blogBody3;
                        sidebarMaxHeight = post.sidebarHeight;

                        setCommentsLength(sidebarMaxHeight);

                    });
                   
                    
                }
            }).catch((err) => {
                console.log(err.message);
        });
        fetch('http://localhost:5000/view/post/recent-posts')
            .then((res)=> res.json())
            .then((data) => {
                setRecentPosts(data);
            })
            .catch((err)=>{
                console.log(err.message)
        });
        fetch('http://localhost:5000/view/post/tag-post')
            .then((res) => res.json())
            .then((data)=> {
                setTagPosts(data)
            })
            .catch((err)=>{
                console.log(err.message);
        });
        fetch('http://localhost:5000/view/post/comments')
            .then((res)=> res.json())
            .then((data) =>{
                if(data.length > 0){
                    const filteredComments = data.filter((item)=>{
                        return Object.values(item).join('').toLowerCase().includes(slug);
                    });
                    setFilteredResultsComments(filteredComments)
                }
                setComments(data);
            })
            .catch((err) =>{
                console.log(err.message);
        });
        fetch('http://localhost:5000/view/tag-cloud/post-keyword')
            .then((res) => res.json())
            .then((data) =>{
                setPostKeyword(data);
                if(data.length > 0){

                    const filteredData = data.filter((item)=>{
                        return Object.values(item).join('').toLowerCase().includes(slug.toLowerCase());
                    });
                    filteredData.map((item)=>{
                        keywordY.push(item.keyword);
                    });
                }
            })
            .catch((err) =>{
                console.log(err)
        });     

    },[]);


    return(
            <>     
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{title}</title>
                    <meta name="description" content={description}/>
                    <meta name="author" content="Sanjay Kumar"/>
                    <link rel="canonical" href="/latest-news/:category/:slug"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                    <meta name="keywords" content={""}/>
                </Helmet>     
                {/*filteredResults.map((data)=>{
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{data.blogTitle}</title>
                        <meta name="description" content={data.blogBody1.substring(0,150)}/>
                        <meta name="author" content="Sanjay Kumar"/>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                        <meta name="keywords" content={keywordY}/>
                    </Helmet>
                })*/}

                <Topbar/>
                <br/><br/>
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-8">
                            <div className="mb-5">
                                {
                                    filteredResults.map((data)=>{
                                        return(
                                            <div className="mb-5">
                                                <img className="img-fluid w-100 rounded mb-5" src={data.blogImage} alt={data.blogTitle} style={{paddingTop: '10%'}}/>
                                                <h1 className="mb-4">{data.blogTitle}</h1>
                                                <p>
                                                    {data.blogBody1}
                                                </p>
                                                <p>
                                                    {data.blogBody2}
                                                </p>
                                                <p>
                                                    {data.blogBody3}
                                                </p>
                                                <div className="d-flex justify-content-between bg-light p-4 mt-4 mb-4">
                                                    <div className="d-flex align-items-center">
                                                        <img className="rounded-circle me-2" src={user} width="40" height="40" alt={""}/>
                                                        <span>{data.category}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        {/*<span className="ms-3"><a><i className="far fa-eye text-primary me-1"></i>{ specialist.length + " comments"}</a></span>
                                                        <span className="ms-3"><a onClick={handleOpen}><i className="far fa-comment text-primary me-1"></i>{"leave a comment"}</a></span>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="mb-5">
                        
                                <div className="col-lg-4" style={{
                                    marginLeft: '112%',
                                    marginTop: commentsLength
                                }}>
                                    <div className="mb-5">
                                        <div className="input-group">
                                            <input type="text" className="form-control p-3" placeholder={"Keyword"}/>
                                            <button className="btn btn-primary px-3"><i className="fa fa-search"></i>{"Go"}</button>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 mb-4">{"Categories"}</h4>
                                        <div className="d-flex flex-column justify-content-start">
                                            {filteredResults.map((data)=>{
                                                return(
                                                    <a href="#" className="h5 bg-light rounded py-2 px-2 mb-2">{data.category}</a>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 mb-4">{"Recent Courses"}</h4>
                                        
                                        {
                                            recentPosts.map((post)=>{
                                                return(
                                                    <div className="d-flex rounded overflow-hidden mb-3">
                                                        <a href={"/learning/" + post.category.toLowerCase() + "/" + post.slug} className="h5 d-flex align-items-center bg-light px-3 mb-0">{post.postTitle}</a>
                                                    </div>
                                                );
                                            })
                                        }
                                    
                                    </div>
                                    <div className="mb-5">
                                        <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 mb-4">{"Tag Cloud"}</h4>
                                        <div className="d-flex flex-wrap m-n1">
                                            {
                                                postKeyword.map((tag)=>{
                                                    if(tag.slug === slug){
                                                        return(
                                                            <a href={""} className="btn btn-primary m-1">{tag.keyword}</a>
                                                        )
                                                    }   
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 mb-4">{"Author details"}</h4>
                                        <div className="bg-light rounded text-center" style={{padding:'30px'}}>
                                            <p>{"Sanjay Kumar has been a writer at the Family Hero since 2022. He trainied at the University of the Witwatersrand in Johannesburg, South Africa, qualifying with an Bsc in 2019."}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogContent dividers>
                                <div className="bg-light rounded p-5">
                                    <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5 border-white mb-4">{"Comment on our hero research"}</h4>
                                    <form>
                                        <div className="row g-3">
                                            <div className="col-12 col-sm-6">
                                                <input type="text" className="form-control bg-white border-0" placeholder="Your name" style={{height:'55px'}} onChange={(e) => setUsername(e.target.value)}/>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="email" className="form-control bg-white border-0" placeholder="Your email" style={{height:'55px'}} onChange={(e) => setEmail(e.target.value)}/>
                                            </div>
                                            <div className="col-12">
                                                    <select className="form-select bg-white border-0" style={{ height: '55px'}} onChange={(e) => setDpt(e.target.value)}>
                                                        <option name="no_department">{"Choose department"}</option>
                                                                {department.map((item)=>{
                                                                    return(
                                                                        <option value={item}>{item}</option>
                                                                    )
                                                                })}
                                                    </select>                                        
                                            </div>
                                            <div className="col-12">
                                                <textarea className="form-control bg-white border-0" rows="5" placeholder="Message" onChange={(e) => setMessage(e.target.value)}></textarea>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary w-100 py-3" type="submit" onClick={submit}>{"Submit"}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                    </DialogContent>
                </BootstrapDialog>
                <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={snackBarClose}>
                    <Alert onClose={snackBarClose} severity="success" sx={{ width: '100%' }}>
                        {"Your account has been created please verify on email!"}
                    </Alert>
                </Snackbar>
            <Footer/>
            </>
    )
}

export default LatestNewsDetails;