import React, { useEffect, useState } from "react";
import {Helmet} from 'react-helmet';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import { ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CardActions from '@mui/material/CardActions';
import Topbar from '../main/Topbar';
import nft1 from './images/nfts/Nft1.png';
import nft6 from './images/nfts/Nft6.png';
import user from '../main/images/user.jpg';
import hospital from '../main/images/home/drawing_hospital.png';
import Footer from './Footer';
import AdsBody from './ads/AdsBody';
import AdsFeatures from './ads/AdsFeatures';
import AdsFacility from './ads/AdsFacility';
import AdsPost from './ads/AdsPost';
import DB from '../../db';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import useGeolocation from './useGeolocation';
import useGeolocationIPData from './useGeolocationIPData';
import useNetworkStatus from './useNetworkStatus'; // Import your custom hook
import { red } from '@mui/material/colors';
import "./css/slidingtext.css";

const Body = () => {

    const [allPost, setAllPost] = React.useState([]);
    const [department, setDepartment] = React.useState([]);
    const [cardHeight, setCardHeight] = useState('');
    const [heartAndScience, setHeartAndScience] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const { city, latitude, longitude, timezone, country_name } = useGeolocation();
    const { city_ipdata, latitude_ipdata, time_zone_ipdata, country_name_ipdata } = useGeolocationIPData();

    const [ continent, setContinent ] = useState('');

    const { isOnline } = useNetworkStatus(); // Use the hook to and get the network status

    useEffect(()=>{
        // Wait until the DOM is ready
        setTimeout(()=>{
            // Use querySelector to select the online and offline elements
            const onlineElement = document.querySelector('.card-height-online');
            const offlineElement = document.querySelector('.card-height-offline');

            if (onlineElement) {
                console.log("You are online.");
                console.log(onlineElement);
            } 

            if (offlineElement) {
                console.log("You are offline");
                console.log(offlineElement);
            }


        },100); // Delay to ensure elements are rendered in the DOM

    },[isOnline]); // Rerun the effect when the isOnline state changes

    const specialists = [
        "Cardiologist",
        "Dermatologist",
        "Obstetrician-gynecologist",
        "Family medicine",
        "Neurologist",
        "Oncologist",
        "Psychiatrist",
        "Anesthesiologist",
        "Gastroenterologist",
        "Internal medicine",
        "Endocrinologist",
        "Geriatrician",
        "Ophthalmology",
        "Pediatrician",
        "Hematologist",
        "Radiologist",
        "Emergency medicine",
        "Infectious disease specialist",
        "General surgeon",
        "Nephrologist",
        "Intensive care medicine",
        "Immunologist",
        "Organ transplantation",
        "Forensic pathology"
    ];

    const [currentText, setCurrentText] = useState(0);

    useEffect(()=>{
        const intervalId = setInterval(() => {
            setCurrentText((prevText) => (prevText + 1) % specialists.length); // Cycle through texts
        }, 3000);
        
        return () => clearInterval(intervalId); // Clean up interval on component unmount
    },[specialists.length]);

    useEffect(() => {
        if (typeof timezone !== 'undefined') {
          const extractedContinent = timezone.split('/')[0];
          setContinent(extractedContinent);
        } else if (typeof time_zone_ipdata !== 'undefined') {
          const extractedContinent = time_zone_ipdata.name.split('/')[0];
          setContinent(extractedContinent);
        }

    }, [timezone, time_zone_ipdata]); // This ensures the effect runs only when 'timezone' changes

    useEffect(() => {
        // Update the document's title when the continent changes
        document.title = `${continent} family hero.`;

    },[continent]);

    useEffect(()=>{

        const metaDescription = document.querySelector('meta[name="description"]');

        if (typeof city !== 'undefined') {
            const descriptionContent = `${continent} leading healthcare solution in ${city}. Get the best medical services near you.`;

            if (metaDescription) {
                // Update existing meta description content
                metaDescription.setAttribute('content', descriptionContent);
            } else {
                // Create new meta description if it doesn't exist
                const meta = document.createElement('meta');
                meta.name = 'description';
                meta.description = metaDescription;
                document.getElementsByTagName('head')[0].appendChild(meta);
            }
        } else if (typeof city === 'undefined') {
            if (typeof city_ipdata !== 'undefined') {
                const descriptionContent = `${continent} leading healthcare solution in ${city_ipdata}. Get the best medical services near you`;

                if (metaDescription) {
                    // Update existing meta description content
                    metaDescription.setAttribute('content', descriptionContent);
                } else {
                    // Create new meta description if it doesn't exist
                    const meta = document.createElement('meta');
                    meta.name = 'description';
                    meta.description = metaDescription;
                    document.getElementsByTagName('head')[0].appendChild(meta);
                }
            }
        }
        
    },[continent, city, city_ipdata]); // Runs whenever continent or city changes

    useEffect(()=> {
        const element = document.querySelector('.card-height');
        
        if (element) {
            // Remove any infinite 'height' style
            element.style.removeProperty('height');

            // Set the height to 'fit-content' with !important
            element.style.setProperty('height', 'fit-content', 'important');
        }
        
    } ,[]); // The empty dependency array ensures this runs only once after the components mounts 


    useEffect(()=>{
        const db = new DB('family_hero');
        db.getAllPosts().then((posts)=>{
            posts.map((post)=>{
                if(post.blogBody1.length < 100){
                    setCardHeight('84%');
                }
            });
            setAllPost(posts);
        });
        db.getDepartments().then((departments)=>{
            setDepartment(departments);
        });
        db.getHeartAndScience().then((heartAndScience)=>{
            setHeartAndScience(heartAndScience);
            //console.log(heartAndScience);
        });

        db.getPostKeyword().then((postKeywords)=>{
            setKeywords(postKeywords);
        })
    },[]);

    const blogPostFilter = (event) =>{
        event.preventDefault();
        var blogPostCategory = null;
        var blogPostCategory = event.target.value;

        const filteredData = allPost.filter((item)=>{
            return Object.values(item).join('').toLowerCase().includes(blogPostCategory.toString().toLowerCase());
        });
        setAllPost(filteredData);
    }

    var splitterStyle = {
          height: 'fit-content !important'
    }

    return(
        <>
        <Helmet>
                <meta charSet="utf-8"/>
                {/*<title>{"{continent} family hero - best healthcare solution in your city."}</title>*/}
                <meta name="author" content="Sanjay Kumar"/>
                <meta name="keywords" content={"healthcare,public health nursing,angioplasty,dental healthcare,cardiologists,public health center,neurology,world health day,heart disease,personal hygiene,health,world health organization"}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                {/*<meta name="description" content={"{continent} family hero is a best healthcare solution in your city, that allows you to find near by specialists, make appointments for a specialist visit and learning courses."}/>*/}
                <link rel="canonical" href="https://www.worldofhealthcare.co.za"/>
        </Helmet>
        <Topbar/>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} style={{'marginTop': '45px'}}>
                <Grid container item xs={12} variant="outlined">
                    <div className="container-fluid py-5 mb-5 hero-header">
                        <div className="container py-5">
                            <div className="row justify-content-start">
                                <div className="row">
                                    <div className="col-sm-8">
                                            {/*<h2 className="d-inline-block text-primary text-uppercase border-bottom border-5" style={{ borderColor: 'rgba(256,256,256, .3) !important'}}>We help african</h2>*/}
                                            <h3 className="display-1 text-black mb-md-4">We help {continent}</h3>
                                            <h3 className="display-1 text-black mb-md-4 sliding-text">{specialists[currentText]}</h3>
                                            <h3 className="display-1 text-black mb-md-4">grow their sales</h3>
                                            <p className="large-font">We design tailored healthcare appointment solutions <br/>in multiple countries</p>
                                            <div className="pt-2">
                                                <a href="/find-a-doctor" className="btn btn-dark rounded-pill py-md-3 px-md-5 mx-2">Find Doctor</a>
                                                <a href="/appointment" className="btn btn-dark rounded-pill py-md-3 px-md-5 mx-2">Appointment</a>
                                            </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <AdsBody dataAdSlot="1086003152"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid container item xs={12} variant="outlined">
                    <div className="container-fluid py-5 mb-5">
                        <div className="container py-5">
                            <div className="row justify-content-start">
                                <div className="row">
                                    <div className="col-sm-7" style={{ height: 'auto !important', marginTop:'106px'}}>
                                        <AdsFeatures dataAdSlot="4953974287"/>
                                        <br/>
                                        <AdsFeatures dataAdSlot="5476718468"/>
                                        <br/>
                                        <AdsFeatures dataAdSlot="6419481035"/>
                                        <br/>
                                        <AdsFeatures dataAdSlot="8642744720"/>
                                    </div>
                                    <div className="col-sm-5">
                                        <Box sx={{ width: '100%', maxWidth: 500}}>
                                            <Typography variant="h2" className="d-inline-block text-primary text-uppercase border-bottom border-5">
                                                {"Key features"}
                                            </Typography>
                                            <br/><br/>
                                            {/*<Typography variant="h3" className="display-4">
                                                {"The Heart And Science of Family Hero"}
                                            </Typography>*/}
                                            <br/>
                                            <Typography variant="p" className="large-font">
                                                {`It is a long established fact that patients are looking for easier and faster ways to get helped by hospitals from different conditions and diseases, our online ${continent} health services include:`}
                                            </Typography>
                                            <List
                                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                                aria-label="contacts"

                                            >
                                               <Box sx={{ maxWidth: 400, margin: '20px auto' }}>
                                                    <ListItem 
                                                        disablePadding
                                                                sx={{
                                                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                  borderRadius: '8px',
                                                                  backgroundColor: '#fff',
                                                                  margin: '10px 0', 
                                                                  overflow: 'hidden', 
                                                                }}
                                                    > 
                                                        <ListItemButton>
                                                        <ListItemText primary="Comprehensive inpatient care provided in a hospital or other type of inpatient facility, where you are admitted, and spend at least one night - sometimes more - depending on your conditions." 
                                                                      primaryTypographyProps={{ style: { fontSize: '18px' } }}
                                                        />
                                                        </ListItemButton>
                                                    </ListItem>
                                                </Box>
                                                <Box sx={{ maxWidth: 400, margin: '20px auto'}}>
                                                    <ListItem 
                                                            disablePadding
                                                                sx={{
                                                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                  borderRadius: '8px',
                                                                  backgroundColor: '#fff',
                                                                  margin: '10px 0', 
                                                                  overflow: 'hidden', 
                                                                }}
                                                    >
                                                        <ListItemButton>
                                                        <ListItemText primary="Medical and surgical services means any services for medical conditions or surgical procedures, as defined under the terms of health benefit plans, and in accordance with applicable federal and state law, but does not include mental health or substance use disorder services." 
                                                                      primaryTypographyProps={{ style: { fontSize: '18px' } }}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </Box>
                                                <Box sx={{ maxWidth: 400, margin: '20px auto'}}>
                                                    <ListItem 
                                                          disablePadding
                                                                sx={{
                                                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                  borderRadius: '8px',
                                                                  backgroundColor: '#fff',
                                                                  margin: '10px 0', 
                                                                  overflow: 'hidden', 
                                                                }}
                                                    >
                                                        <ListItemButton>
                                                        <ListItemText primary="Outpatient care refers to any healthcare consultation, procedure, treatment, or other service that is administered without an overnight stay at a hospital or medical facility. Unlike inpatient care, patients receiving outpatient care are free to leave the medical facility once the service or procedure is complete." 
                                                                      primaryTypographyProps={{ style: { fontSize: '18px' } }}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </Box>
                                                <Box sx={{ maxWidth: 400, margin: '20px auto'}}>
                                                    <ListItem 
                                                        disablePadding
                                                                sx={{
                                                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                  borderRadius: '8px',
                                                                  backgroundColor: '#fff',
                                                                  margin: '10px 0', 
                                                                  overflow: 'hidden', 
                                                                }}
                                                    >
                                                        <ListItemButton>
                                                        <ListItemText 
                                                                      primary={`${country_name}'s leading surgical instrument supplier. Find surgical equipment at the right price and in record time. Shop surgical instruments.`}
                                                                      primaryTypographyProps={{ style: { fontSize: '18px' } }}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </Box>
                                                <Box sx={{ maxWidth: 400, margin: '20px auto'}}>
                                                    <ListItem 
                                                        disablePadding
                                                                sx={{
                                                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                  borderRadius: '8px',
                                                                  backgroundColor: '#fff',
                                                                  margin: '10px 0', 
                                                                  overflow: 'hidden', 
                                                                }}
                                                    >
                                                        <ListItemButton>
                                                        <ListItemText primary="Specialised services support people with a wide range of rare or complex health conditions, for example rare cancers, genetic disorders or complex medical or surgical conditions." 
                                                                      primaryTypographyProps={{ style: { fontSize: '18px' } }}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </Box>
                                                <Box sx={{ maxWidth: 400, margin: '20px auto'}}>
                                                    <ListItem 
                                                        disablePadding
                                                                sx={{
                                                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                  borderRadius: '8px',
                                                                  backgroundColor: '#fff',
                                                                  margin: '10px 0', 
                                                                  overflow: 'hidden', 
                                                                }}
                                                    >
                                                        <ListItemButton>
                                                        <ListItemText primary="Global public health awareness days help in raising the importance of healthy lifestyle and well-being among general population." 
                                                                      primaryTypographyProps={{ style: { fontSize: '18px' } }}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </Box>
                                                
                                            </List>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={{ xs: 2, md: 3}} columns={{ xs: 4, sm: 8, md: 12}}>
                <Grid container item xs={2} sm={4} md={4}>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                    <div className="container">
                        <div className="text-center mx-auto mb-5">
                            <h4 className="d-inline-block text-primary text-uppercase border-bottom border-5">
                                {"Facilities"}
                            </h4>
                            <h3 className="display-4">{"What facilities we provided"}</h3>
                        </div>
                    </div>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                </Grid>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid container item xs={2} sm={4} md={4}>
                        <Card sx={{ minWidth: 275 }} variant="outlined">
                        <div className="bg-light rounded overflow-hidden">
                                <CardContent>
                                    <AdsFacility dataAdSlot="6501312488"/>
                                    <div className="p-4">
                                        <p className="m-0 medium-font">
                                            {`${continent} family hero offers healthcare that does breast augmentation surgery for reconstructive purposes after breast cancer surgery (for example), to correct a breast defect, to increase breast size, achieve a younger, natural appearance. Anyone who has questions about surgery should speak to a epidemiology to ensure the health is distibuted across the medicine community in order to prevent them.`}
                                        </p>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <a href="/facility/mph-online" size="small" className="btn btn-dark rounded-pill py-3 px-5 me-3">{"Mph online"}</a>
                                </CardActions>
                        </div>
                        </Card>                   
                    </Grid>
                    <Grid container item xs={2} sm={4} md={4}>
                        <Card sx={{ minWidth: 275 }} variant="outlined">
                            <div className="bg-light rounded overflow-hidden" style={{ height:'100%'}}>
                                <CardContent>
                                    <AdsFacility dataAdSlot="2812942390"/>
                                    <div className="p-4">
                                        <p className="m-0 medium-font">
                                            {"Echocardiogram healthcare provides an ultrasound picture that shows the structure of the heart chambers and surrounding areas, and it can show how well the heart is working. Echocardiography can measure how well the heart is pumping blood, known as cardiac output, x-ray scans are usually operated by heart specialist and cardiothoracic surgeon."}
                                        </p>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <a href="/facility/public-health-nursing" size="small" className="btn btn-dark rounded-pill py-3 px-5">{"Public health nursing"}</a>
                                </CardActions>
                            </div>
                            
                        </Card>                       
                    </Grid>
                    <Grid container item xs={2} sm={4} md={4}>
                        <Card sx={{ minWidth: 275 }} variant="outlined">
                            <div className="bg-light rounded overflow-hidden" style={{height:'100%'}}>
                                <CardContent>
                                        <AdsFacility dataAdSlot="9987234148"/>
                                        <div className="p-4 medium-font">
                                            <p className="m-0">{"A pediatriacian is medical doctor who specializes in treating infacts, children, adolescents and young adults. Pediatric care can begin before conception and continue through pregnancy. An endocrinologist may treat a variety of conditions in children, including diabetes. Accompanied by thoracic surgeon specialises in operating on organs the chest, including the heart and lungs."}</p>
                                        </div>
                                </CardContent>
                            <CardActions>
                                <a href="/facility/public-health-nutrition" size="small" className="btn btn-dark rounded-pill py-3 px-5 me-3">{"Public health nutrition"}</a>
                            </CardActions>
                            </div>
                        </Card>                       
                    </Grid>
            </Grid>
            <br/>                                   
            <Grid container spacing={{ xs: 2, md: 3}} columns={{ xs: 4, sm: 8, md: 12}}>
                <Grid container item xs={2} sm={4} md={4}>
                </Grid>
                <Grid container item xs={2} sm={4} md={4} className="text-center mx-auto mb-5" variant="div">
                    <div className="text-center mx-auto mb-5">
                        <h2 className="d-inline-block text-primary text-uppercase border-bottom border-5">
                            {"Services"}
                        </h2>
                        <h3 className="display-4">{"Excellent Medical Services"}</h3>
                    </div>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                </Grid>
            </Grid>

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid container item xs={2} sm={4} md={4}>
                <Card sx={{ minWidth: 275 }} variant="outlined">
                    <CardContent>
                        <a href={"/medical-services/angioplasty"} style={{textDecoration: 'none', color: 'black'}}>
                          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-user-md text-white"></i>
                            </div>
                            <h2 className="mb-4">{"Angioplasty"}</h2>
                            <p className="m-0 small-font">{"Angioplasty is a procedure used to open blocked coronary arteries caused by coronary artery disease. It restores blood flow to the heart muscle without open-heart surgery. Angioplasty can be done in an emergency setting such as a heart attack. Or it can be done as elective surgery if your healthcare provider strongly suspects you have heart disease. Angioplasty is also called percutaneous coronary intervention (PCI)."}</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                       </a>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                <Card sx={{ minWidth: 275 }} variant="outlined">
                    <CardContent>
                        <a href="/medical-services/dental-healthcare" style={{textDecoration: 'none', color: 'black'}}>
                          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-procedures text-white"></i>
                            </div>
                            <h3 className="mb-3">{"Dental Healthcare"}</h3>
                            <p className="m-0 small-font">{"Dental healthcare centres feature fully fledged dental departments staffed by experienced dentists and oral hygienists qualified to provide a full range of dental services ranging from routine dental care to specialized dental services and oral hygiene. You've made a dentist appointment, and it can't come soon enough. Meanwhile, it can help to rinse your mouth with warm water, you may need antibiotics as well as other treatments."}</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                           </div>
                        </a>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                <Card sx={{ minWidth: 275 }} variant="outlined">
                    <CardContent>
                        <a href="/medical-services/cardiologists" style={{textDecoration: 'none', color: 'black'}}>
                          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-procedures text-white"></i>
                            </div>
                            <h4 className="mb-3">{"Cardiologists"}</h4>
                            <p className="m-0 small-font">{"Cardiologists is a medical specialist treating disorders of the heart and the blood vessels. A person with heart disease or cardiovascular disease may be referred to a cardiologists, heart specialist branch of internal medicine, a cardiologists is not the same as a cardiac surgeon. Cardiothoracic surgeon opens the chest and performs heart surgery, cardiologists specializes in diagnosing and treating diseases of the cardiovascular system."}</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                          </div>
                        </a>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                <Card sx={{ minWidth: 275 }} variant="outlined">
                    <CardContent>
                        <a href="/medical-services/public-health-center" style={{textDecoration: 'none', color: 'black'}}>
                         <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-stethoscope text-white"></i>
                            </div>
                            <h3 className="mb-3">{"Public health center"}</h3>
                            <p className="m-0 small-font">{"Surgery can be an effective treatment option for a wide range of chronic disease and disorders. Whether you are facing a complex, innovative surgical procedure or a technique that has been used successfully thousands of times, rest assured that the board-certified surgeons at public health center are among the region's most experienced physicians using state-of-the-art medical technologies."}</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div></a>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                <Card sx={{ minWidth: 275 }} variant="outlined">
                    <CardContent>
                        <a href="/medical-services/eye-care" style={{textDecoration: 'none', color: 'black'}}>
                          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-stethoscope text-white"></i>
                            </div>
                            <h4 className="mb-3">{"Eye Care"}</h4>
                            <p className="m-0 small-font">{"Everyone needs a regular eye exam, even young children. It helps protect your sight and lets you see your best. Eye exams can also find diseases, like glaucoma, that have no symptoms. It's important to spot them early on, when they're easier to treat. Depending on your eye health needs, you can see one of the two types of doctors."}</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                       </a>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                <Card sx={{ minWidth: 275 }} variant="outlined">
                    <CardContent>
                        <a href="/medical-services/neurology" style={{textDecoration: 'none', color: 'black'}}>
                          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-stethoscope text-white"></i>
                            </div>
                            <h4 className="mb-3">{"Neurology"}</h4>
                            <p className="m-0 small-font">{"If you or a loved one has unexplained symptoms that could be related to the brain or nervous system, your doctor may recommend a neurological exam with a specialist. Neurologists are specialists who treat diseases of the brain and spinal cord, peripheral nerves and muscles. Neurological conditions include epilepsy, stroke, multiple sclerosis and parkinsons's disease."}</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                        </a>
                    </CardContent>
                </Card>
                </Grid>
                
                <Grid container item xs={2} sm={4} md={4}>
                <Card sx={{ minWidth: 275 }} variant="outlined">
                    <CardContent>
                        <a href="/medical-services/world-health-day" style={{textDecoration: 'none', color: 'black'}}>
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon mb-4">
                                <i className="fa fa-2x fa-stethoscope text-white"></i>
                            </div>
                            <h4 className="mb-3">{"World health day"}</h4>
                            <p className="m-0 small-font">{"World health day, is celebrated and each year draws attention to a specific health topic of concern to people all over the world. They started having world health day events in the early 2010s, and the last one was on the 8th of April 2023 all centered around public health. There's also mental health day designed for doctor, nurses and patients who are experiencing mental health issues in South Africa."}</p>
                            <a className="btn btn-lg btn-primary rounded-pill" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                        </a>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                    <Card sx={{ minWidth: 275 }} variant="outlined">
                        <CardContent>
                          <a href="/medical-services/heart-disease" style={{textDecoration: 'none', color: 'black'}}>
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon mb-4">
                                    <i className="fa fa-2x fa-stethoscope text-white"></i>
                                </div>
                                <h4 className="mb-3">{"Heart disease"}</h4>
                                <p className="m-0 small-font">{"A chronic disease is defined as condition that last 1 year or more and may require an ongoing medication attention because it limits physical activity. Chronic disease such as heart disease, cancer and diabetes are leading causes of death and disability in most African countries. Many chronic dieases are linked to lifestyle choices that are within our hands to change."}</p>
                                <a className="btn btn-lg btn-primary rounded-pill" href="">
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                         </a>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={2} sm={4} md={4}>
                    <Card sx={{ minWidth: 275 }} variant="outlined">
                        <CardContent>
                            <a href="/medical-services/personal-hygiene" style={{textDecoration: 'none', color: 'black'}}>
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon mb-4">
                                    <i className="fa fa-2x fa-stethoscope text-white"></i>
                                </div>
                                <h4 className="mb-3">{"Personal Hygiene"}</h4>
                                <p className="m-0 small-font">{"Good personal hygiene which is directly linked to environmental health is one of the best ways to protect yourself from getting gastro or infectious diseases such as COVID-19, colds and flu. Maintaining good personal hygiene will also prevent you from spreading diseases to other people, how you care for your body, this practise includes washing your hands, brushing your teeth, and more."}</p>
                                <a className="btn btn-lg btn-primary rounded-pill" href="#">
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                            </a>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container className="container-fluid py-5" spacing={{ xs: 2, md: 3}} columns={{ xs: 4, sm: 8, md: 12}}>
                    <Grid container item xs={2} sm={4} md={4}>
                    </Grid>
                    <Grid container item xs={2} sm={4} md={4} className="container">
                        <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px'}}>
                            <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">
                                {"Posts"}
                            </h5>
                            <h3 className="display-4">{"Our Latest Medical Posts"}</h3>
                            <Card sx={{ minWidth: 275 }} variant="outlined">
                                    <select className="form-select bg-light border-0" style={{ height: '55px'}} onChange={blogPostFilter}>
                                            <option value="no_department">{"View all posts"}</option>
                                            {keywords.map((item)=>{
                                                return(
                                                    <option className="h5 bg-light rounded py-2 px-3 mb-2" value={item.keyword}>{item.keyword}</option>
                                                )
                                            })}
                                    </select> 
                            </Card>                                            
                        </div>
                            
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                    </Grid>
            </Grid>
            <Grid container spacing={{ xs:2, md: 3}} columns={{ xs: 4, sm: 8, md:12}}>
                    {

                        allPost.map((post)=>{
                            return(
                                 <Grid item xs={2} sm={4} md={4} container key={post.adSlot}>
                                    <Card 
                                        sx={{ minWidth: 275 }}
                                        variant="outlined"
                                        className={"bg-light rounded overflow-hidden"}
                                        style={splitterStyle}
                                        > 
                                        <CardHeader
                                            avatar={
                                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                    {post.category.substring(0,1)}
                                                </Avatar>
                                            }
                                            action={
                                                <IconButton aria-label="settings">
                                                    <MoreVertIcon/>
                                                </IconButton>
                                            }
                                            title={post.category}
                                            subheader={post.date}
                                        />
                                        <CardContent>
                                            {/*<img
                                                className="img-fluid w-100"
                                                src={post.blogImage}
                                                style={{
                                                    height: 255,
                                                    width: "100%",
                                                    maxWidth: 400,
                                                    display: "block",
                                                    overflow: "hidden",
                                                }}
                                            />*/}
                                            <AdsPost
                                                image={post.blogImage}
                                                adSlot={post.adSlot}
                                                style={{
                                                    height:255,
                                                    width:"100%",
                                                    maxWidth: 400,
                                                    display: "block",
                                                    overflow: "hidden",
                                                }}
                                                className="img-fluid w-100"
                                            />
                                            <div className="p-4">
                                                <a href={"/learning/" + post.adSlot + "/" + post.category.replace(/\s+/g, '-').toLowerCase() + "/"+ post.slug} className="h3 d-block mb-3" style={{textDecoration:'none'}}>{post.blogTitle}</a>
                                                <p className="m-0 small-font" dangerouslySetInnerHTML={{__html: post.blogBody1}}></p>
                                            </div>
                                            {/*<div className="d-flex justify-content-between border-top p-4">
                                                <div className="d-flex align-items-center">
                                                    <img className="rounded-circle me-2" src={user} width="25" height="25"/>
                                                    <small><a href={"/learning/" + post.category.replace(/\s+/g, '-').toLowerCase() + "/"+ post.slug} style={{textDecoration: 'none'}} className="category-font">{post.category}</a></small>
                                                </div>
                                            </div>*/}
                                        </CardContent>
                                    </Card>                          
                                </Grid>
                          );
                        })
                    }
            </Grid>
            {/*<Grid container spacing={{ xs:2, md: 3}} columns={{ xs: 8, sm: 16, md:12}}>  
                {
                    (()=> {
                        if(allPost.length > 0){
                            return(
                                <div className="col-12 text-center" style={{marginTop:'53px'}}>
                                    <button className="btn btn-dark rounded-pill py-md-3 px-md-5 mx-2">Load more</button>
                                </div>
                            );
                        }
                    })()
                }   
            </Grid>*/}
        </Box>
        <Footer/>
        </>       
    );

}

export default Body;