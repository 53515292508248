import React, { useEffect, useState } from 'react';
import Topbar from '../main/Topbar';
import {Helmet} from 'react-helmet';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import DB from '../../db.js';

const MedicalServices = () => {

    const location = useLocation();
    const pathname = location.pathname;
    const [medicalServices, setMedicalServices] = useState([]);
    const indexie = pathname.lastIndexOf("/");

    var slug = pathname.substring(indexie + 1);
    var service = pathname.substring(indexie + 1).replace("-"," ");

    if(indexie >= 1){
        service = service.replace("-"," ");
    }

    useEffect(()=>{
        const db = new DB('family_hero');
        db.getMedicalServices(slug).then((data)=>{
            setMedicalServices(data);
        });
    },[]);

    return(
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{"Medical services"}</title>
                <meta name="author" content={"Sanjay Kumar"}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://www.worldofhealthcare.co.za/medical-services"/>
            </Helmet>
            <Topbar/>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        {/*<h6 className="section-title bg-white text-center text-primary px-3">{""}</h6>*/}
                        <h2 className="mb-5" style={{marginTop: '100px'}}>
                            {service && service.charAt(0).toUpperCase() + service.slice(1)}
                        </h2>
                        {
                            medicalServices.map((item)=>{
                                return(
                                    <p>{item.text}</p>
                                )
                            })
                         }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )

}

export default MedicalServices;