import React, { useEffect, useState } from 'react';
import Topbar from '../main/Topbar';
import '../main/css/chat.css';
import Footer from '../main/Footer';
import GroupIcon from '@mui/icons-material/Group';
import { Helmet } from 'react-helmet';
import Modal from '../main/Modal';
import Users from '../main/Users';
import DB from '../../db.js';
import { Observable } from 'rxjs';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Swal from 'sweetalert2';
import ChatIcon from '@mui/icons-material/Chat';
import DoctorFinderMap from './maps/DoctorFinderMap';
import AdsChat from './ads/AdsChat';
import useGeolocation from './useGeolocation';
import useGeolocationIPData from './useGeolocationIPData';

const brain = require('brain.js');

const Alert = React.forwardRef(function Alert(props, ref){
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props}/>;
});

const adHTML = `
    <div
        style="
            background-image: url('/main_chat.jpg');
            background-size: cover;
            background-position: center; 
            padding: 20px; 
            border-radius: 8px;
            margin-top: 40px;
            height: 216px;
        "
    >
        <ins class="adsbygoogle"
            style="display: block;"
            data-ad-client="ca-pub-2340613167692358"
            data-ad-slot="5806458153"
            data-ad-format="auto"
            data-full-width-responsive="true"
        >
        </ins> 
    </div>
`;

const Chat = () => {

    const db = new DB('family_hero');

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenUser, setIsOpenUser] = useState(false);
    const [username, setUsername] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [id, setId] = useState("");
    const [user, setUser] = useState([]);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [botName, setBotName] = useState("");
    const [email, setEmail] = useState("");
    //const [messageId, setMessageId] = useState("");
    const [html, setHTML] = useState();

    const { city, latitude, longitude, timezone, country_name } = useGeolocation();
    const { city_ipdata, latitude_ipdata, time_zone_ipdata, country_name_ipdata } = useGeolocationIPData();

    const [continent, setContinent] = useState('');

    var msg = [];

    function useJSX(){
        setHTML(<div dangerouslySetInnerHTML={{__html: adHTML}}></div>)
    }

    //const url = "ws://localhost:8080/chat";
    //const mywsServer = new WebSocket(url);
    let mywsServer;
    
    const handleClose = () => {
        setOpenSnackbar(false);
    }

    function capitalizeFirstLetter(str) {
        console.log(str);
        return str[0].toUpperCase() + str.slice(1);
    }

    async function refreshToken(objUser){
        (async () => {
            const rawResponse = await fetch('http://localhost:8080/refresh-token',{
                method: 'POST',
                headers: {
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(objUser)
            });
            const content = await rawResponse.json();
            console.log(content);
        })();
    }

    async function getSignedInUser(token,email){
        (async () => {
            const rawResponse = await fetch(`http://localhost:8080/user/me?email=${email}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': token
                    }
            });

            const content = await rawResponse.json();
            const objUser = {
                email:email
            };

            if((content.message === 'Invalid Token')){
                setMessage('Sign in or sign up to chat on hero.');
                refreshToken(objUser);
            }
        })();
    }

    useEffect(()=>{
        const token = sessionStorage.getItem('token');
        const email = sessionStorage.getItem('email');
        if((token !== null) && (email !== null)){
            getSignedInUser(token,email);
        }
    },[]);

    useEffect(() => {
        if (typeof timezone !== 'undefined') {
          const extractedContinent = timezone.split('/')[0];
          setContinent(extractedContinent);
        } else if (typeof time_zone_ipdata !== 'undefined'){
          if (typeof time_zone_ipdata !== 'undefined') {
                const extractedContinent = time_zone_ipdata.name.split('/')[0];
                setContinent(extractedContinent);
          }
        }
    }, [timezone, time_zone_ipdata]); // This ensures the effect runs only when 'timezone' changes

    useEffect(() => {
        // Update the document's title when the continent changes
        document.title = `${continent} family hero chat service.`;

    },[continent]);

    useEffect(()=>{
        const metaDescription = document.querySelector('meta[name="description"]');

        const descriptionContent = `Ask healthcare questions from ${continent} community, compare service ratings and learn more about hero chat in your ${city}`;

        if (metaDescription) {
            // Update existing meta description content
            metaDescription.setAttribute('content', descriptionContent);
        } else {
            // Create new meta description if it doesn't exist
            const meta = document.createElement('meta');
            meta.name = 'description';
            meta.description = metaDescription;
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

    },[continent, city]); // Runs whenever continent or city changes



    const messageObservable = new Observable((subscriber)=>{
        var selectedUser = sessionStorage.getItem('selected_chat_user');
        if((selectedUser !== null)){
            (async () => {  
                const rawResponse = await fetch(`http://localhost:8080/handles?name=${selectedUser}`,{
                    headers:{
                        'Content-Type':'application/json'
                    }
                });
                
                const content = await rawResponse.json();
                const chat = [];
                const message = {};

                if((content.name === 'bot')){
                    message.username = 'HeroChat';
                    message.handle = '@'+content.handle;
                    message.image = 'https://image.flaticon.com/icons/svg/327/327779.svg';
                    message.message = 'Support chat, it is still the best health solution in your city. Looking for help? Read about your latest global health placards or reach out specialists or patients with question and get answers. User guide, application manual, frequently asked questions and more details on practice location.';
                    message.type = 'bot';

                    chat.push(message);
                }

                subscriber.next(chat);
            })();
        }
    });

    const userObservable = new Observable((subscriber)=>{
        var email = null;
        if((sessionStorage.getItem('email') !== null)){
            email = sessionStorage.getItem('email');
            setInterval(()=>{
                subscriber.next(email);
            },1000);
        }
    });

    const botMessagesObservable = new Observable((subscriber)=>{
        db.retrieveBotMessages().then((message)=>{
            subscriber.next(message);
        });
    })

    function formatDate(date){
        const h = "0" + date.getHours();
        const m = "0" + date.getMinutes();

        return `${h.slice(-2)}:${m.slice(-2)}`;
    }

    function appendMessageLocation(username, img, side, message, latitude, longitude){
        const msgerChat = get(".msger-chat");

        const msgHTML = `
            <div class="msg ${side}-msg" id="chatMessage-${username.toLowerCase()}">
                <div class="msg-img" style="background-image: url(${img})"></div>

                <div class="msg-bubble">
                    <div class="msg-info">
                    <div class="msg-info-name">${username}</div>
                    <div class="msg-info-time">${formatDate(new Date())}</div>
                    </div>

                    <DoctorFinderMap lat={latitude} lng={longitude}/>
                    <div class="msg-text">${message}</div>
                </div>
            </div>
        `;

        msgerChat.insertAdjacentHTML("beforeend", msgHTML);
        msgerChat.scrollTop += 500;
    }

    function appendMessage(username, img, side, message){
        const msgerChat = get(".msger-chat");

        const msgHTML = `
                <div class="msg ${side}-msg" id="chatMessage-${username.toLowerCase()}">
                <div class="msg-img" style="background-image: url(${img})"></div>

                <div class="msg-bubble">
                    <div class="msg-info">
                    <div class="msg-info-name">${username}</div>
                    <div class="msg-info-time">${formatDate(new Date())}</div>
                    </div>

                    <div class="msg-text">${message}</div>
                </div>
                </div>
        `;


        msgerChat.insertAdjacentHTML("beforeend", msgHTML);
        msgerChat.scrollTop += 500;
    }

    function get(selector, root = document){
        return root.querySelector(selector);
    }

    useEffect(()=>{
        botMessagesObservable.subscribe({
            next(x){
                msg.push(x);
            }
        })
    },[]);

    const FEED_MSGS = [
        `<p>Go online. <a href='#'>Sign in</a> or <a href='#'>sign up</a> and start chats with your health society, give advice on fees, conditions, location and more.</p>`,
        `<p>Welcome to <i><b>${continent ? continent + ' family hero chat' : 'Global family hero chat'}</b></i>, a global place that you can use to chat with the health society, share news, best medical practices, consultation fees including patients, doctor and cash loans.😄 ${adHTML}</p>`
    ];

    function random(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    function placardFeedSignedIn(){
        const msgText = FEED_MSGS[1];
        const delay = msgText.split(" ").length * 100;
        const BOT_IMG = "https://image.flaticon.com/icons/svg/327/327779.svg";

        var selectedUser = sessionStorage.getItem('selected_chat_user');
        var email = sessionStorage.getItem('email');

        var objHeroFeed = new Object();

        if((selectedUser !== null)){
            (async () => {
                const rawResponse = await fetch(`http://localhost:8080/handles?name=${selectedUser}`,{
                    headers:{
                        'Content-Type':'application/json'
                    }
                });
                const content = await rawResponse.json();
                if((content.name === 'bot')){
                    setBotName("HeroChat");

                    objHeroFeed.handle = '@'+content.bot;
                    objHeroFeed.username = botName;
                    objHeroFeed.image = BOT_IMG;
                    objHeroFeed.side = "left";
                    objHeroFeed.type = "bot";
                    objHeroFeed.from = email;
                    objHeroFeed.message = msgText;
                    objHeroFeed.date = formatDate(new Date());

                    appendMessage(botName, BOT_IMG, "left", msgText);
                    //setTimeout(()=>{
                    //    appendMessage(botName, BOT_IMG, "left", msgText);
                    //}, delay);
                }
            })();
        }
    }


    function placardFeedSignedOut(){
        const r = random(0, FEED_MSGS.length - 1);
        //const msgText = FEED_MSGS[r];
        const msgText = FEED_MSGS[0];
        const delay = msgText.split(" ").length * 100;
        const BOT_IMG = "https://image.flaticon.com/icons/svg/327/327779.svg";
        
        var selectedUser = sessionStorage.getItem('selected_chat_user');
        var user = sessionStorage.getItem('email');
        var objUser = JSON.stringify(user);

        if((selectedUser !== null)){
            (async () => {
                const rawResponse = await fetch(`http://localhost:8080/handles?name=${selectedUser}`,{
                    headers:{
                        'Content-Type':'application/json'
                    }
                });
                const content = await rawResponse.json();
                if((content.name === 'bot')){
                    //setBotName("HeroChat");
                    appendMessage('HeroChat',BOT_IMG,'left',msgText);
                }
            })();
        }
    }

    async function loadChats(){
        var username = sessionStorage.getItem('selected_chat_user');
        var email = sessionStorage.getItem('email');

        if((username !== null)){

            if((username === 'bot')){

                var defaultUsername = 'HeroChat';
                const rawResponse = await fetch(`http://localhost:8080/view-message?username=${defaultUsername}`,{
                    method:'GET',
                    'Content-Type':'application/json'
                });
                const content = await rawResponse.json();
                if((content.type === 'bot')){
                    messageObservable.subscribe({
                        next(x){
                            x.map((message)=>{
                                appendMessage(defaultUsername,message.image,message.side,message.message);
                            });
                        }
                    });
                }

                const message = {};
                const signoutMessage = {};

                message.username = 'HeroChat';
                message.handle = '@'+'bot';
                message.image = 'https://image.flaticon.com/icons/327/32777.svg';
                message.message = 'Support chat, it is still the best health solution in your city. Looking for help? Read about your latest global health placard or reach out specialists or patients with question and get answers. User guide, application manual, frequently asked questions and more details on practice location.';
                message.type = 'bot';
                message.side = 'left';

                signoutMessage.username = 'HeroChat';
                signoutMessage.handle = '@'+'bot';
                signoutMessage.image = 'https://image.flaticon.com/icons/327/327779.svg';
                signoutMessage.message = FEED_MSGS[1];
                signoutMessage.type = 'bot';
                signoutMessage.side = 'left';

                appendMessage(message.username,message.image,message.side,message.message);
                appendMessage(signoutMessage.username,signoutMessage.image,signoutMessage.side,signoutMessage.message);

                const messageResponse = await fetch('http://localhost:8080/finder-messages',{
                    method:'GET',
                    'Content-Type':'application/json'
                });
                const res = await messageResponse.json();
                for(var i=0;i<res.length;i++){
                    appendMessage(res[i].username,res[i].image,
                        res[i].side,res[i].message,
                        res[i].latitude,res[i].longitude);
                }
            }
            
        }else{

            const message = {};
            const signoutMessage = {};

            message.username = 'HeroChat';
            message.handle = '@'+'bot';
            message.image = 'https://image.flaticon.com/icons/327/32777.svg';
            message.message = 'Support chat, it is still the best health solution in your city. Looking for help? Read about your latest global health placard or reach out specialists or patients with question and get answers. User guide, application manual, frequently asked questions and more details on practice location.';
            message.type = 'bot';
            message.side = 'left';

            signoutMessage.username = 'HeroChat';
            signoutMessage.handle = '@'+'bot';
            signoutMessage.image = 'https://image.flaticon.com/icons/327/327779.svg';
            signoutMessage.message = FEED_MSGS[1];
            signoutMessage.type = 'bot';
            signoutMessage.side = 'left';

            appendMessage(message.username,message.image,message.side,message.message);
            appendMessage(signoutMessage.username,signoutMessage.image,signoutMessage.side,signoutMessage.message);

            const rawResponse = await fetch('http://localhost:8080/finder-messages',{
                method:'GET',
                'Content-Type':'application/json'
            });
            const content = await rawResponse.json();
            for(var i=0;i<content.length;i++){
                appendMessageLocation(content[i].username,content[i].image,
                    content[i].side,content[i].message,
                    content[i].latitude,content[i].longitude);
            }
        }
    }

    useEffect(()=>{
        loadChats();
    },[]);

    useEffect(()=>{
      
        const msgerForm = get(".msger-inputarea");
        const msgerInput = get(".msger-input");

        const BOT_MSGS = [
            "Hi, how are you?",
            "Ohh... I can't understand what you trying to say. Sorry!",
            "I like to play games... But I don't know how to play!",
            "Sorry if my answers are not relevant. :))",
            "I feel sleepy! :("
        ];

        if((sessionStorage.getItem('email') !== null)){
            userObservable.subscribe({
                next(x){
                    var email = sessionStorage.getItem('email');
                    //console.log(email);
                }
            })
        }else{
            Swal.fire({
                title:'Info',
                text:'Sign up or sign in to send text messages.',
                icon:'warning',
                confirmButtonText:'Cool'
            });
        }

        const BOT_IMG = "https://image.flaticon.com/icons/svg/327/327779.svg";
        const PERSON_IMG = "https://image.flaticon.com/icons/svg/145/145867.svg";


        function botResponse(objMessage){
            const r = random(0, BOT_MSGS.length - 1);
            const msgText = BOT_MSGS[r];
            const delay = msgText.split(" ").length * 100;

            var objBotMessage = new Object();            
            var selectedUser = sessionStorage.getItem('selected_chat_user');
            
            if((selectedUser !== null)){
                (async () => {
                    const rawResponse = await fetch(`http://localhost:8080/handles?name=${selectedUser}`,{
                        headers:{
                            'Content-Type':'application/json'
                        }
                    });
                    const content = await rawResponse.json();
                    if((content.name === 'bot')){

                        objBotMessage.handle = '@'+content.name;
                        objBotMessage.username = content.name;
                        objBotMessage.image = BOT_IMG;
                        objBotMessage.side = 'left';
                        objBotMessage.type = 'bot';
                        objBotMessage.from = selectedUser;
                        objBotMessage.message = msgText;
                        objBotMessage.date = formatDate(new Date());

                        setTimeout(()=>{
                            appendMessage(objBotMessage.username,BOT_IMG,objBotMessage.side,msgText);
                        },delay);
                    }else{

                        objBotMessage.handle = '@'+content.name;
                        objBotMessage.username = content.name;
                        objBotMessage.image = BOT_IMG;
                        objBotMessage.side = 'left';
                        objBotMessage.type = 'bot';
                        objBotMessage.from = selectedUser;
                        objBotMessage.message = msgText;
                        objBotMessage.date = formatDate(new Date());

                        setTimeout(()=>{
                            appendMessage(objBotMessage.username,BOT_IMG,objBotMessage.side,msgText);
                        },delay);
                    }
                })();
            }
        }
    
        function get(selector, root = document){
            return root.querySelector(selector);
        }
    
        function formatDate(date){
            const h = "0" + date.getHours();
            const m = "0" + date.getMinutes();
    
            return `${h.slice(-2)}:${m.slice(-2)}`;
        }

        function isOpen(ws){
            return ws.readyState === ws.OPEN 
        }

        async function isJson(str){
            try{
                JSON.parse(str);
            }catch(e){
                return false;
            }
            return true;
        }

        async function addMessage(obj){
            for(var i=0;i < obj.length;i++){
                appendMessage(
                    obj[i].username,
                    obj[i].image,
                    obj[i].side,
                    obj[i].message
                );
            }
        }

        async function getUser(email,from,msgText){

            const rawResponse = await fetch(`http://localhost:8080/get-user?email=${email}`,{
                method:'GET',
                'Content-Type':'application/json'
            });
            const content = await rawResponse.json();

            if((email !== null) && (content !== null)){
                 if((from === 'bot')){
                    var username = content.username;
                    var objMessage = new Object();

                    objMessage.handle = '@'+from.toLowerCase();
                    objMessage.username = username;
                    objMessage.image = PERSON_IMG;
                    objMessage.side = 'left';
                    objMessage.type = 'bot';
                    objMessage.from = username;
                    objMessage.message = msgText;
                    objMessage.date = formatDate(new Date());

                    if(isOpen(mywsServer)){
                        const messageData = await fetch('http://localhost:8080/message',{
                            method:'POST',
                            headers:{
                                'Content-Type':'application/json'
                            },
                            body:JSON.stringify(objMessage)
                        });
                        const messageContent = await messageData.json();
                        mywsServer.send(JSON.stringify(messageContent.content));
                        msgerInput.value = "";
                    }
                }else if((content.auth === 'sign_up')){
                    var username = content.username;
                    var objMessage = new Object();

                    objMessage.handle = '@'+content.username;
                    objMessage.username = content.username;
                    objMessage.image = PERSON_IMG;
                    objMessage.side = 'left';
                    objMessage.type = username;
                    objMessage.message = msgText;
                    objMessage.date = formatDate(new Date());
                    objMessage.connection = from;

                    if(isOpen(mywsServer)){
                        const messageData = await fetch('http://localhost:8080/message',{
                            method: 'POST',
                            headers:{
                                'Content-Type':'application/json'
                            },
                            body:JSON.stringify(objMessage)
                        });
                        const messageContent = await messageData.json();
                        mywsServer.send(JSON.stringify(messageContent.content));
                        msgerInput.value = "";
                    }        
                   

                }else{
                    var username = content.username;
                    var objMessage = new Object();

                    objMessage.handle = '@'+username;
                    objMessage.username = username;
                    objMessage.image = PERSON_IMG;
                    objMessage.side = 'right';
                    objMessage.type = 'user';
                    objMessage.from = from;
                    objMessage.message = msgText;
                    objMessage.date = formatDate(new Date());

                    appendMessage(objMessage.username, objMessage.image, objMessage.side, objMessage.message);
                    msgerInput.value = "";
                    botResponse(objMessage);
                }
            }else{
                Swal.fire({
                    title:'info',
                    text:'Sign in or sign up to send and receive messages.',
                    icon:'warning',
                    confirmButtonText:'Cool'
                });
            }  
        }
        
        msgerForm.addEventListener("submit", event => {
            event.preventDefault();

            const msgText = msgerInput.value;
            if((!msgText)){
                setSnackbarMessage("Please enter your message!");
                setOpenSnackbar(true);
                return;
            }else if((msgText)){
                
                var email = sessionStorage.getItem('email');
                var from = sessionStorage.getItem('selected_chat_user');

                if((email !== null) && (from !== null)){
                    getUser(email,from,msgText);
                }else{
                    setSnackbarMessage("Sign up or sign in to send and receive messages.");
                    setOpenSnackbar(true);
                }

            }
        });

        function connect(){
            mywsServer = new WebSocket("ws://localhost:8080/chat");
            mywsServer.onopen = function(){
                console.log('Connection opened!');
            }
            mywsServer.onmessage = function(event){
                const { data } = event;
                var obj = JSON.parse(data);
                appendMessage(
                    obj.username,
                    obj.image,
                    obj.side,
                    obj.message
                );
            }
            mywsServer.onclose = function(event){
                //console.log('Socket is closed. Reconnect will be attempted 1 second.', event.reason);
                setTimeout(function(){
                    connect();
                },100);
            }
            mywsServer.onerror = function(err){
                console.log('Socket encountered error: ', err.message, 'Closing socket.');
                mywsServer.close();
            }
        }
        connect();
    },[]);

    useEffect(()=>{
        const metaDescription = document.querySelector('meta[name="description"]');

        const descriptionContent = `Ask healthcare questions, compare service ratings, and learn more about hero chat.`;

        if (metaDescription) {
            // Update existing meta description content
            metaDescription.setAttribute('content', descriptionContent);
        } else {
            // Create new meta description if it doesn't exist
            const meta = document.createElement('meta');
            meta.name = 'description';
            meta.description = metaDescription;
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

    },[]); // Runs whenever continent or city changes


    return(
            <>
             <Helmet>
                 <meta charSet="uft-8"/>
                 <title>{"Hero chat"}</title>
                 <meta name="author" content={"Sanjay Kumar"}/>
                 <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                 <meta rel="canonical" href="https://www.worldofhealthcare.co.za/chat"/>
             </Helmet>
             <Topbar/>
             <br/><br/><br/><br/><br/>
             <div className="container-fluid pt-5">
               <div className="container">
                     <div className="text-center mx-auto mb-5" style={{maxWidth:'500px'}}>
                         <h2 className="d-inline-block text-primary text-uppercase border-bottom border-5">{"Hero Chat"}</h2>
                         <h2 className="mb-5" style={{marginTop: '15px'}}>
                            {"Ask healthcare questions, compare service ratings, and learn more about hero chat."}
                        </h2>
                     </div>
                     <div className="container-xxl py-5 chat">
                         <section className="msger" style={{marginTop:'-10%'}}>
                             <header className="msger-header">
                                 <div className="msger-header-title">
                                     <GroupIcon onClick={() => setIsOpenUser(true)}/> HeroChat {" - " + (city ? city : "City not available")}
                                     {isOpenUser && <Users setIsOpenUser={setIsOpenUser} />}
                                 </div>
                                 <div className="msger-header-options">
                                     <span><ChatIcon onClick={() => setIsOpen(true)}/></span>
                                     {isOpen && <Modal setIsOpen={setIsOpen} />}
                                 </div>
                                 
                             </header>
                             <main className="msger-chat"></main>
                             <form className="msger-inputarea">
                                 <input type="text" className="msger-input" placeholder="Enter your message..."/>
                                 <button type="submit" className="msger-send-btn" id="messenger-send-btn">Send</button>
                             </form>
                         </section>
                     </div>
               </div>
             </div>
             <Footer/>
             <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
                 <Alert onClose={handleClose} severity="error" sx={{ width: '100%'}}>
                     {snackbarMessage}
                 </Alert>
             </Snackbar>
            </>
    )
    
}

export default Chat;